import { TableCell, TableRow as MuiTableRow, Typography } from '@mui/material';

export function TableRow({ title, value, testId = 'unknown' }) {
    return (
        <MuiTableRow>
            <TableCell component="th" scope="row">
                <Typography variant="subtitle2">{title}</Typography>
            </TableCell>
            <TableCell style={{ whiteSpace: 'break-spaces' }} data-testid={`LicenseFileDetailsRow_${testId}`}>
                {value}
            </TableCell>
        </MuiTableRow>
    );
}
