import { Box, Card } from '@mui/material';
import PropTypes from 'prop-types';

import { ObjectTableSearch } from './ObjectTableSearch';
import { useTableInteractionStyles } from './styles';

/**
 * @param {Object} props
 * @param {import('react-table').TableInstance} props.table
 */
export function ObjectTableInteraction({ Buttons, table }) {
    const { classes } = useTableInteractionStyles();

    return (
        <Card className={classes.card}>
            <Box display="flex" alignItems="center">
                <Box flexGrow={1} display="flex">
                    <ObjectTableSearch table={table} />
                </Box>
                <Buttons />
            </Box>
        </Card>
    );
}

ObjectTableInteraction.propTypes = {
    Buttons: PropTypes.func.isRequired,
    table: PropTypes.object.isRequired,
};
