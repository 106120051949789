import { useCallback, useMemo, useState } from 'react';
import { FormContainer } from 'react-hook-form-mui';
import { generatePath } from 'react-router';
import { useParams } from 'react-router-dom';

import { Box, Card, CardHeader, Collapse, Divider, IconButton, useTheme } from '@mui/material';
import _ from 'lodash';
import { makeStyles } from 'makeStyles';
import { getConfigurationFormFields } from 'productConfigurations/utils';

import { ActionButton } from 'commonComponents/ActionButton';
import { CommonFormComponents } from 'commonComponents/CommonFormComponents';
import { LoadingIndicator } from 'commonComponents/LoadingIndicator';
import { PageContainer } from 'commonComponents/PageContainer';

import { useLicense } from 'api/licenses';
import { useCurrentProduct } from 'api/products';
import { ExpandLessIcon, ExpandMoreIcon } from 'icons';
import { CreateIcon } from 'icons/CreateIcon';
import { ROUTES } from 'routes/routes';

import { ROLE_DEFINITIONS } from '../roles/definitions';
import { useCurrentUserHasRole } from '../roles/helpers';
import {
    ConfigurationFormCardContent,
    LicenseCardActions,
    LicenseCardContent,
    useLicenseAndConfigurationForm,
} from './LicenseAndConfigurationForm/LicenseAndConfigurationForm';
import { propertyNames } from './propertyNames';

const useStyles = makeStyles()(theme => ({
    expired: {
        color: theme.palette.error.main,
    },
}));

export function EditLicense() {
    const theme = useTheme();
    const { classes } = useStyles();
    const [open, setOpen] = useState(true);
    const { id: licenseId } = useParams<{ id: string }>();
    const currentUserHasRole = useCurrentUserHasRole();
    const userIsLicenseManager = currentUserHasRole(ROLE_DEFINITIONS.LicenseManager);
    const disabledFields = !userIsLicenseManager ? ['ownerId'] : [];
    const { data: currentProduct } = useCurrentProduct();
    const availableFeatures = currentProduct?.features?.filter(
        f => f.featureType === 'Feature' || f.featureType === 'ADK Feature'
    );
    const { data: license, error } = useLicense(Number(licenseId));

    const SubmitComponent = useCallback(({ inProgress }) => {
        return (
            <ActionButton
                color="secondary"
                data-testid="SubmitButton"
                iconClass={CreateIcon}
                inProgress={inProgress}
                title="Submit"
                type="submit"
                variant="contained"
            />
        );
    }, []);

    const initialValues = useMemo(() => {
        if (license) {
            return {
                ...license,
                site_id:
                    license.properties.find(({ name }) => name === propertyNames.PROPERTY_SITE_ID) != null
                        ? license.properties.find(({ name }) => name === propertyNames.PROPERTY_SITE_ID).value
                        : '',
                serial_number:
                    license.properties.find(({ name }) => name === propertyNames.PROPERTY_SERIAL_NUMBER) != null
                        ? license.properties.find(({ name }) => name === propertyNames.PROPERTY_SERIAL_NUMBER).value
                        : '',
                features: license.features.map(f => f.id),
                configuration: getConfigurationFormFields(license.configuration),
            };
        }
    }, [license]);

    const licenseAndConfigurationForm = useLicenseAndConfigurationForm({
        initialValues,
    });

    if (error) {
        return null;
    }

    if (!license || !licenseAndConfigurationForm || !currentProduct) {
        return <LoadingIndicator />;
    }

    const { formContext, trigger, isMutating } = licenseAndConfigurationForm;
    const { watch } = formContext;
    const internal = watch('internal');

    return (
        <PageContainer maxWidth="lg">
            <FormContainer formContext={formContext} onSuccess={data => trigger(data)}>
                <CommonFormComponents />
                <Box display="flex" flexDirection="column" gap={theme.spacing(1)}>
                    <Card>
                        <CardHeader
                            className={license && license.isExpired ? classes.expired : null}
                            title={`Edit ${license.configuration.product?.name || 'N/A'} license${
                                license.isExpired ? ' [EXPIRED]' : ''
                            }`}
                            subheader={internal ? 'Only for internal use' : ''}
                            subheaderTypographyProps={{ variant: 'subtitle2', color: 'error' }}
                        />
                        <LicenseCardContent license={license} disabledFields={disabledFields} />
                        <LicenseCardActions
                            cancelButtonRoute={generatePath(ROUTES.LICENSE_DETAIL, { id: licenseId })}
                            SubmitComponent={SubmitComponent}
                            isMutating={isMutating}
                        />
                    </Card>
                    <Card>
                        <CardHeader
                            onClick={() => setOpen(!open)}
                            subheader="Configuration Details"
                            action={
                                <IconButton size="large">{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
                            }
                        />
                        <Collapse in={open} timeout="auto">
                            <Divider />
                            <ConfigurationFormCardContent
                                availableFeatures={
                                    license.configuration.isAdhocConfiguration
                                        ? _.unionBy(availableFeatures, license.configuration.features, 'id')
                                        : license.configuration.features
                                }
                                currentProduct={currentProduct}
                                selectedProductConfiguration={license.configuration}
                            />
                        </Collapse>
                    </Card>
                </Box>
            </FormContainer>
        </PageContainer>
    );
}
