import axios from 'axios';

import { BASE_URL } from 'common/baseUrl';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

function getApiEndpoint(endpoint) {
    return BASE_URL ? `/${BASE_URL}${endpoint}` : endpoint;
}

export function get<T>(url: string, config: import('axios').AxiosRequestConfig = {}) {
    return axios.get<T>(getApiEndpoint(url), config);
}

export function post<T>(url: string, data: any = null, config: import('axios').AxiosRequestConfig = {}) {
    return axios.post<T>(getApiEndpoint(url), data, config);
}

export function put<T>(url, id, data = null) {
    return axios.put<T>(`${getApiEndpoint(url)}${id}`, data);
}

export function patch(url, id, data = null) {
    return axios.patch(`${getApiEndpoint(url)}${id}`, data);
}

export function destroy(url, id) {
    return axios.delete(`${getApiEndpoint(url)}${id}`);
}
