import { useState } from 'react';

import { ActionButton } from 'commonComponents/ActionButton';

import { DeleteIcon } from 'icons';
import { ROLE_DEFINITIONS } from 'roles/definitions';
import { RoleDependentComponent } from 'roles/RoleDependentComponent';

import { ConfigurationDeleteDialog } from './ConfigurationDeleteDialog';

export function ConfigurationDeleteButton({ configuration }) {
    const [open, setOpen] = useState(false);

    return (
        <>
            <RoleDependentComponent
                userRole={ROLE_DEFINITIONS.LicenseManager}
                component={ActionButton}
                onClick={() => setOpen(true)}
                color="secondary"
                iconClass={DeleteIcon}
                title="Delete"
                variant="contained"
                data-testid="DeleteConfig_Button"
            />
            <ConfigurationDeleteDialog
                configuration={configuration}
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
            />
        </>
    );
}
