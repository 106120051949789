import { Button, Tooltip } from '@mui/material';
import { makeStyles } from 'makeStyles';

import { CircularProgressIcon } from 'icons/CircularProgressIcon';

const useStyles = makeStyles()(theme => ({
    icon: {
        marginRight: theme.spacing(1),
    },
    tooltip: { display: 'block' },
}));

type ActionButtonProps = {
    disabled?: boolean;
    iconClass: (...props: any) => JSX.Element;
    inProgress?: boolean;
    title?: string;
    tooltip?: string;
};

export function ActionButton({
    disabled,
    iconClass,
    inProgress,
    title,
    tooltip = '',
    ...other
}: React.ComponentProps<typeof Button> & ActionButtonProps) {
    const { classes } = useStyles();
    const Icon = inProgress ? CircularProgressIcon : iconClass;
    return (
        <Tooltip title={tooltip}>
            <span className={classes.tooltip}>
                <Button disabled={disabled || inProgress} variant="outlined" size="large" {...other}>
                    <Icon className={classes.icon} />
                    {title}
                </Button>
            </span>
        </Tooltip>
    );
}
