import { Box, CircularProgress, Typography } from '@mui/material';

/** @param {{ text?: string }} param0 */
export function LoadingIndicator({ text }) {
    return (
        <Box
            alignItems="center"
            display="flex"
            height="100vh"
            justifyContent="center"
            width="100%"
            data-testid="LoadingIndicator"
        >
            <Box alignItems="center" display="flex" flexDirection="column">
                <CircularProgress size={80} />
                <Typography align="center">{text || 'Loading...'}</Typography>
            </Box>
        </Box>
    );
}
