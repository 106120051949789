import { Container } from '@mui/material';

import { ResetPasswordForm } from './ResetPasswordForm';

export function ResetPasswordPage() {
    return (
        <Container fixed maxWidth="sm">
            <ResetPasswordForm />
        </Container>
    );
}
