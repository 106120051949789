import { TextFieldElement } from 'react-hook-form-mui';

export function AdditionalLicenseData({ defaultProps, name = 'additionalLicenseData', disabled = false }) {
    const validateAdditionalLicenseData = value => {
        if (value) {
            try {
                const result = JSON.parse(value);
                if (typeof result !== 'object') {
                    // JSON.parse also accepts input that is valid JSON, but
                    // not an object.
                    throw new SyntaxError();
                }
            } catch (SyntaxError) {
                return 'Must be a valid JSON object';
            }
        }
    };

    return (
        <TextFieldElement
            name={name}
            label="Additional License Data"
            multiline
            disabled={disabled}
            {...defaultProps}
            validation={{
                validate: validateAdditionalLicenseData,
            }}
        />
    );
}
