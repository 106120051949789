import { createContext, useContext, useMemo } from 'react';

import PropTypes from 'prop-types';

const Context = createContext('null');

export function ObjectTableContext({ children, historyPath }) {
    const value = useMemo(() => {
        return {
            historyPath,
        };
    }, [historyPath]);
    return <Context.Provider value={value}>{children}</Context.Provider>;
}

ObjectTableContext.propTypes = {
    historyPath: PropTypes.string.isRequired,
};
const useThisContext = () => useContext(Context);

export const useHistoryPath = () => useThisContext().historyPath;

export function cellValue(row, entry) {
    if (typeof entry.accessor === 'function') {
        return entry.accessor(row);
    }
    if (typeof row[entry.accessor] === 'boolean') {
        return mapBoolean(row[entry.accessor]);
    }
    return row[entry.accessor];
}

export function mapBoolean(value) {
    return value ? 'Yes' : 'No';
}
