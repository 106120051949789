import { useState } from 'react';

import { ActionButton } from 'commonComponents/ActionButton';

import { DeleteIcon } from 'icons';
import { ROLE_DEFINITIONS } from 'roles/definitions';
import { RoleDependentComponent } from 'roles/RoleDependentComponent';

import { LicenseInvalidateDialog } from './LicenseInvalidateDialog';

export function LicenseInvalidateButton({ licenseDetails }) {
    const { invalidated, id: licenseId } = licenseDetails;

    const [invalidateDialogOpen, setInvalidateDialogOpen] = useState(false);

    if (invalidated) return null;

    return (
        <>
            <RoleDependentComponent
                userRole={ROLE_DEFINITIONS.PrivilegedCustomer}
                component={ActionButton}
                onClick={() => setInvalidateDialogOpen(true)}
                color="secondary"
                iconClass={DeleteIcon}
                title="Invalidate"
                variant="contained"
                data-testid="LicenseDetails_Invalidate"
            />
            <LicenseInvalidateDialog
                licenseId={licenseId}
                open={invalidateDialogOpen}
                onClose={() => {
                    setInvalidateDialogOpen(false);
                }}
            />
        </>
    );
}
