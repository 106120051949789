import { useParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { LoadingIndicator } from 'commonComponents/LoadingIndicator';

import { loadCustomerDetails } from '../api/customers';
import { EditCustomer } from './EditCustomer';

export function EditCustomerWrapper() {
    const { id: customerId } = useParams<{ id: string }>();
    /** @type {import('swr').SWRResponse<import('../api/customers').CustomerDetails>} */
    const { data: customer, error } = useQuery({
        queryKey: ['Customer', 'detail', Number(customerId)],
        queryFn: () => loadCustomerDetails(Number(customerId)),
        meta: { errorMessage: 'Loading customer failed' },
    });

    if (error) {
        return null;
    }

    if (!customer) {
        return <LoadingIndicator />;
    }

    return <EditCustomer customer={customer} />;
}
