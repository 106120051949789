import { Redirect, useLocation } from 'react-router-dom';

import { useUser } from 'api/authorization';
import { userHasRole } from 'roles/helpers';
import { ROUTES } from 'routes/routes';

function getSearchParam(location) {
    if (location.pathname === '/') {
        return undefined;
    }
    return `?next=${encodeURIComponent(location.pathname + location.search)}`;
}

export function RequireAuth({ children }) {
    const { data: user } = useUser();
    const location = useLocation();

    if (!user) {
        return (
            <Redirect
                to={{
                    pathname: ROUTES.LOGIN,
                    search: getSearchParam(location),
                }}
            />
        );
    }
    return children;
}

export function RequireRole({ role, children }) {
    return (
        <RequireAuth>
            <RequireRoleRolePart role={role}>{children}</RequireRoleRolePart>
        </RequireAuth>
    );
}

export function RequireRoleRolePart({ role, children }) {
    const { data: user } = useUser();

    if (!userHasRole(user, role)) {
        return (
            <Redirect
                to={{
                    pathname: ROUTES.NOT_ALLOWED,
                }}
            />
        );
    }

    return children;
}

export function RequireValidUser({ children }) {
    const { data: user } = useUser();

    if (user?.invalidated) {
        return (
            <Redirect
                to={{
                    pathname: ROUTES.NOT_ALLOWED,
                }}
            />
        );
    }

    return children;
}
