import { get } from './axiosWrapper';
import { API_ENDPOINTS } from './endpoints';

type MatchedSubstring = {
    length: number;
    offset: number;
};

type SuggestionsResponse = {
    predictions: {
        description: string;
        matched_substrings: MatchedSubstring[];
        place_id: string;
        reference: string;
        structured_formatting: {
            main_text: string;
            main_text_matched_substrings: MatchedSubstring[];
            secondary_text: string;
        };
        terms: { offset: number; value: string }[];
        types: string[];
    }[];
    status: string;
};

export function loadSuggestions(input, sessiontoken) {
    return get<SuggestionsResponse>(API_ENDPOINTS.PLACES, { params: { input, sessiontoken } }).then(res => res.data);
}

type PlaceDetailsResponse = {
    result: {
        address_components: {
            long_name: string;
            short_name: string;
            types: string[];
        }[];
    };
};
export function loadPlaceDetails(placeId, sessiontoken) {
    return get<PlaceDetailsResponse>(`${API_ENDPOINTS.PLACE_DETAIL}${placeId}`, { params: { sessiontoken } }).then(
        res => res.data
    );
}
