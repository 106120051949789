import { useState } from 'react';

import { ActionButton } from 'commonComponents/ActionButton';

import { DeleteIcon } from 'icons';
import { ROLE_DEFINITIONS } from 'roles/definitions';
import { RoleDependentComponent } from 'roles/RoleDependentComponent';

import { LicenseFileDeactivateDialog } from './LicenseFileDeactivateDialog';

export function LicenseFileDeactivateButton({ licenseFile, license }) {
    const [open, setOpen] = useState(false);

    if (license.invalidated || licenseFile.deactivated) return null;

    return (
        <>
            <RoleDependentComponent
                userRole={ROLE_DEFINITIONS.LicenseManager}
                component={ActionButton}
                iconClass={DeleteIcon}
                variant="contained"
                color="primary"
                onClick={() => setOpen(true)}
                title="Deactivate"
                data-testid="LicenseFileDetails_DeactivateButton"
                disabled={license.isExpired}
            />
            <LicenseFileDeactivateDialog
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                licenseId={license.id}
                licenseFileId={licenseFile.id}
            />
        </>
    );
}
