import { FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui';
import { generatePath, useHistory } from 'react-router';

import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import { ActionButton } from 'commonComponents/ActionButton';

import { deactivateLicenseFile } from 'api/licenses';
import { DeleteIcon, UndoIcon } from 'icons';
import { ROUTES } from 'routes/routes';

export function LicenseFileDeactivateDialog({ licenseId, licenseFileId, open, onClose }) {
    const history = useHistory();

    const { mutate: trigger, isLoading: isMutating } = useMutation({
        mutationFn: () => deactivateLicenseFile(licenseFileId, getValues('reason')),
        onSuccess: () => {
            const licenseDetailsRoute = generatePath(ROUTES.LICENSE_DETAIL, { id: licenseId });
            history.push(licenseDetailsRoute, { showLicenseFiles: true });
        },
        meta: { errorMessage: 'Deactivating license file failed' },
    });

    const formContext = useForm();
    const { getValues } = formContext;

    return (
        <Dialog {...{ open, onClose }}>
            <DialogTitle>Deactivate License File</DialogTitle>
            <DialogContent>
                <Typography variant="body1">Are you sure you want to deactivate this license file?</Typography>
                <FormContainer formContext={formContext} onSuccess={() => trigger()}>
                    <TextFieldElement label="Reason" name="reason" sx={{ marginTop: 2 }} fullWidth multiline />
                </FormContainer>
            </DialogContent>
            <DialogActions>
                <ActionButton
                    color="secondary"
                    iconClass={DeleteIcon}
                    disabled={isMutating}
                    inProgress={isMutating}
                    title="Deactivate"
                    variant="contained"
                    type="submit"
                    onClick={() => trigger()}
                />
                <ActionButton
                    color="primary"
                    iconClass={UndoIcon}
                    inProgress={false}
                    onClick={onClose}
                    title="Cancel"
                    variant="contained"
                />
            </DialogActions>
        </Dialog>
    );
}
