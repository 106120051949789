import { useState } from 'react';
import { generatePath } from 'react-router';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

import { LinkButton } from 'commonComponents/LinkButton';

import { CreateIcon } from 'icons/CreateIcon';
import { ROLE_DEFINITIONS } from 'roles/definitions';
import { RoleDependentComponent } from 'roles/RoleDependentComponent';
import { ROUTES } from 'routes/routes';

export function CreateLicenseFileButton({ licenseDetails }) {
    const { id: licenseId, invalidated } = licenseDetails;
    const [open, setOpen] = useState(false);

    if (invalidated) {
        return null;
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return licenseDetails.configuration.maximumActivations &&
        licenseDetails.activeLicenseFilesCount >= licenseDetails.configuration.maximumActivations ? (
        <>
            <Button color="primary" size="large" variant="contained" onClick={handleClickOpen}>
                Activate License
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Failed to Activate License</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You have already activated this license on the maximum number of devices.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    ) : (
        <RoleDependentComponent
            userRole={ROLE_DEFINITIONS.Customer}
            component={LinkButton}
            iconClass={CreateIcon}
            route={generatePath(ROUTES.LICENSEFILE_CREATE, { licenseId })}
            title="Activate License"
            data-testid="LicenseDetails_CreateLicenseFile"
        />
    );
}
