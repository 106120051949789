import { createTheme } from '@mui/material/styles';

const MEVIS_COLOR_PRIMARY = '#14727a';
const MEVIS_COLOR_SECONDARY = '#5CAEBC';

export function configureTheme() {
    return createTheme({
        palette: {
            primary: { main: MEVIS_COLOR_PRIMARY },
            secondary: { main: MEVIS_COLOR_SECONDARY },
        },
        components: {
            MuiTooltip: {
                defaultProps: {
                    enterDelay: 500,
                },
            },
            MuiCardActions: {
                styleOverrides: {
                    root: ({ theme }) => ({
                        '& > :not(:first-of-type)': {
                            marginLeft: 'unset',
                        },
                        padding: theme.spacing(2),
                        paddingTop: 0,
                        gap: theme.spacing(1),
                    }),
                },
            },
        },
        // reset breakpoints to the v4 values
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 960,
                lg: 1280,
                xl: 1920,
            },
        },
    });
}
