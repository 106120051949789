import { useMemo, useState } from 'react';
import { generatePath, Link as RouterLink, Redirect, useLocation } from 'react-router-dom';

import {
    Box,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    Link,
    TextField,
    Tooltip,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { makeStyles } from 'makeStyles';

import { ActionButton } from 'commonComponents/ActionButton';
import { PageContainer } from 'commonComponents/PageContainer';

import { login, useUser } from 'api/authorization';
import { isAxiosError } from 'api/hooks/errorHandling';
import { useSettings } from 'api/settings';
import { LockOpenIcon, VisibilityIcon, VisibilityOffIcon } from 'icons';
import { ROUTES } from 'routes/routes';

const useStyles = makeStyles()(theme => ({
    linkStyles: {
        marginLeft: theme.spacing(2),
    },
    content: {
        display: 'grid',
        gap: theme.spacing(2),
    },
}));

export function LoginPage() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const { classes } = useStyles();
    const { data } = useUser();
    const { data: appSettings } = useSettings();
    const userAlreadyLoggedIn = Boolean(data);
    const queryClient = useQueryClient();
    const {
        data: user,
        isLoading: loginInProgress,
        error: loginError,
        mutate: trigger,
    } = useMutation({
        mutationFn: login,
        onSuccess: response => {
            queryClient.setQueryData(['LoggedInUser'], response);
        },
        meta: { errorMessage: 'Login failed' },
    });
    const location = useLocation();
    const userIsLocked = loginError && isAxiosError(loginError) && loginError.response.status === 403;

    function requestLogin(event) {
        event.preventDefault();
        event.stopPropagation();

        if (username === '' || password === '') {
            return;
        }
        trigger({ username, password });
    }

    function loginErrorMessage() {
        if (loginError) {
            return 'Sign-in failed: ' + (isAxiosError(loginError) ? loginError.response?.data.message || '' : '');
        }
        if (new URLSearchParams(location.search).has('session_expired')) {
            return 'Your session has expired. Please login again.';
        }
        return '';
    }

    const isRegistrationIncomplete = useMemo(
        () => isAxiosError(loginError) && loginError.response?.data?.detail === 'registration_incomplete',
        [loginError]
    );

    if (userAlreadyLoggedIn || user) {
        window.sessionStorage.clear();
        const next = new URLSearchParams(location.search).get('next') || '/';
        return <Redirect to={next} />;
    }

    if (isRegistrationIncomplete) {
        var path = generatePath(ROUTES.USER_REGISTER_INCOMPLETE, { email: username });
        return <Redirect to={path} />;
    }

    return (
        <PageContainer maxWidth="sm">
            <Card>
                <CardHeader title="Welcome to MeVis Varex Licensing" />
                <form onSubmit={requestLogin}>
                    <CardContent className={classes.content}>
                        <TextField
                            variant="outlined"
                            label="Username"
                            name="username"
                            value={username}
                            onChange={event => {
                                setUsername(event.target.value);
                            }}
                            autoFocus
                            autoComplete="username"
                            data-testid="username"
                        />
                        <TextField
                            variant="outlined"
                            label="Password"
                            name="password"
                            autoComplete="current-password"
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            onChange={event => {
                                setPassword(event.target.value);
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Tooltip title={showPassword ? 'Hide password' : 'Show password'}>
                                            <IconButton
                                                edge="end"
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword(!showPassword)}
                                                data-testid="togglePasswordVisibility"
                                                size="large"
                                            >
                                                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                ),
                            }}
                            data-testid="password"
                        />
                        <FormHelperText error>
                            {loginErrorMessage()}
                            {userIsLocked && (
                                <>
                                    <Box display="flex" gap={0.5}>
                                        <div>For help unlocking your account, you can send an email to:</div>
                                        <Link color="primary" href="mailto:lms@mevis.de" underline="hover">
                                            lms@mevis.de
                                        </Link>
                                    </Box>
                                </>
                            )}
                        </FormHelperText>
                    </CardContent>
                    <CardActions>
                        <Grid container direction="row">
                            <Grid item>
                                <ActionButton
                                    disabled={username === '' || password === ''}
                                    color="primary"
                                    iconClass={LockOpenIcon}
                                    inProgress={loginInProgress}
                                    onClick={requestLogin}
                                    variant="contained"
                                    title="Sign In"
                                    data-testid="signIn"
                                    type="submit"
                                />
                            </Grid>
                            <Grid item>
                                <Grid container item direction="column">
                                    <Link
                                        className={classes.linkStyles}
                                        component={RouterLink}
                                        to={ROUTES.RESET_PASSWORD_REQUEST}
                                        underline="hover"
                                    >
                                        Reset Password
                                    </Link>
                                    {appSettings?.isSelfRegistrationEnabled && (
                                        <Link
                                            className={classes.linkStyles}
                                            component={RouterLink}
                                            to={ROUTES.USER_REGISTER}
                                            underline="hover"
                                        >
                                            New User Registration
                                        </Link>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardActions>
                </form>
            </Card>
        </PageContainer>
    );
}
