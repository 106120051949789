import React from 'react';

import { Typography } from '@mui/material';

export function DetailsCardTitle({ title = '', subtitle = '', showError = false, dataTestId = 'DetailsCard' }) {
    return (
        <React.Fragment>
            <Typography
                variant="subtitle2"
                color={showError ? 'error' : 'textSecondary'}
                data-testid={`${dataTestId}_subtitle`}
            >
                {subtitle}
            </Typography>
            <Typography variant="h5" component="h2" gutterBottom data-testid={`${dataTestId}_title`}>
                {title}
            </Typography>
        </React.Fragment>
    );
}
