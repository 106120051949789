import { useMemo, useState } from 'react';
import { generatePath } from 'react-router';

import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    Collapse,
    Divider,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { makeStyles } from 'makeStyles';
import { arrayOf, bool, object } from 'prop-types';

import { LinkButton } from 'commonComponents/LinkButton';
import { LoadingIndicator } from 'commonComponents/LoadingIndicator';

import { loadLicenseFileList } from 'api/licenses';
import { ExpandLessIcon, ExpandMoreIcon } from 'icons';
import { CircularProgressIcon } from 'icons/CircularProgressIcon';
import { creationDate, hasNoneHardwareBinding, licenseFileExpirationDate } from 'licenses/utils';
import { ROUTES } from 'routes/routes';

import { ROLE_DEFINITIONS } from '../../roles/definitions';
import { useCurrentUserHasRole } from '../../roles/helpers';
import { LicenseFileMoveButton } from './LicenseFileActions/LicenseFileMoveButton';

const useStyles = makeStyles()(theme => ({
    card: {
        marginTop: theme.spacing(1),
    },
    cardContent: {
        paddingTop: 0,
        '&:last-child': {
            paddingBottom: 0,
        },
    },
    cardHeader: {
        cursor: 'pointer',
    },
    rowExpired: {
        '& td': {
            color: theme.palette.error.main,
        },
    },
    row: {
        '& td': {
            color: theme.palette.text.primary,
        },
    },
    actionButtonCell: { textAlign: 'center', width: 0, paddingInline: 'inherit' },
    checkboxCell: { textAlign: 'center' },
    tableCellNoWrap: {
        whiteSpace: 'nowrap',
    },
    commentCell: {
        maxWidth: '200px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));

function LicenseFilesCard({ open, licenseFiles, license }) {
    const { classes } = useStyles();
    const [selectedLicenseFiles, setSelectedLicenseFiles] = useState([]);
    const currentUserHasRole = useCurrentUserHasRole();
    const userIsAtLeastLicenseManager = currentUserHasRole(ROLE_DEFINITIONS.LicenseManager);

    const canMoveLicense = useMemo(
        () => !license.invalidated && userIsAtLeastLicenseManager && license.configuration.product.allow_move_license,
        [license, userIsAtLeastLicenseManager]
    );

    function handleLicenseFileSelection(licenseFileId) {
        if (selectedLicenseFiles.includes(licenseFileId)) {
            setSelectedLicenseFiles(selectedLicenseFiles.filter(id => id !== licenseFileId));
        } else {
            setSelectedLicenseFiles([...selectedLicenseFiles, licenseFileId]);
        }
    }

    function clearLicenseFileSelection() {
        setSelectedLicenseFiles([]);
    }

    const licenseFileSelected = licenseFileId => {
        return selectedLicenseFiles.includes(licenseFileId);
    };

    const licenseFileRowClass = licenseFile => {
        // Note: Styling for deactivated is only applied to some cells or even parts of a cell,
        // so this is done via the sx property where applicable.
        if (licenseFile.isExpired) {
            return classes.rowExpired;
        }
        return classes.row;
    };

    const licenseFileCellSx = licenseFile => (licenseFile.deactivated ? { textDecoration: 'line-through' } : {});

    const buildCommentCell = licenseFile => {
        return (
            <>
                <Box sx={{ ...licenseFileCellSx(licenseFile), whiteSpace: 'pre-wrap' }}>{licenseFile.comment}</Box>
                {licenseFile.deactivated && (
                    <Box sx={{ fontStyle: 'italic', whiteSpace: 'pre-wrap' }}>{licenseFile.deactivation_reason}</Box>
                )}
            </>
        );
    };

    return (
        <Collapse in={open} timeout="auto" unmountOnExit>
            <Divider />
            <CardContent className={classes.cardContent}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {canMoveLicense && (
                                <TableCell className={classes.actionButtonCell}>
                                    <LicenseFileMoveButton
                                        license={license}
                                        licenseFileIds={selectedLicenseFiles}
                                        disabled={isEmpty(selectedLicenseFiles)}
                                        onSubmitCallback={clearLicenseFileSelection}
                                        title="Move"
                                        tooltip="Move selected license files to another license"
                                    />
                                </TableCell>
                            )}
                            {!hasNoneHardwareBinding(license.configuration) && <TableCell>System ID</TableCell>}
                            <TableCell className={classes.tableCellNoWrap}>Activation Date</TableCell>
                            <TableCell className={classes.tableCellNoWrap}>Expiration Date</TableCell>
                            <TableCell className={classes.tableCellNoWrap}>Support Expiration Date</TableCell>
                            <TableCell className={classes.commentCell}>Comment</TableCell>
                            <TableCell>Details</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {licenseFiles.map(licenseFile => (
                            <TableRow
                                key={licenseFile.id}
                                className={licenseFileRowClass(licenseFile)}
                                data-testid={`licenseFileComment_${licenseFile.comment}`}
                            >
                                {canMoveLicense && (
                                    <TableCell className={classes.checkboxCell}>
                                        <Checkbox
                                            style={{ padding: 0 }}
                                            size="small"
                                            name={`select${licenseFile.id}`}
                                            checked={licenseFileSelected(licenseFile.id)}
                                            onClick={() => handleLicenseFileSelection(licenseFile.id)}
                                        />
                                    </TableCell>
                                )}
                                {!hasNoneHardwareBinding(license.configuration) && (
                                    <TableCell sx={licenseFileCellSx(licenseFile)}>
                                        {licenseFile.hardwareIdentifiers.join(', ')}
                                    </TableCell>
                                )}
                                <TableCell className={classes.tableCellNoWrap} sx={licenseFileCellSx(licenseFile)}>
                                    {creationDate(licenseFile)}
                                </TableCell>
                                <TableCell className={classes.tableCellNoWrap} sx={licenseFileCellSx(licenseFile)}>
                                    {licenseFileExpirationDate(licenseFile.expiration_date)}
                                </TableCell>
                                <TableCell className={classes.tableCellNoWrap} sx={licenseFileCellSx(licenseFile)}>
                                    {licenseFileExpirationDate(licenseFile.support_expiration_date)}
                                </TableCell>
                                <TableCell className={classes.commentCell}>{buildCommentCell(licenseFile)}</TableCell>
                                <TableCell>
                                    {!licenseFile.deactivated ? (
                                        <LinkButton
                                            variant="outlined"
                                            color="primary"
                                            route={generatePath(ROUTES.LICENSEFILE_DETAIL, { id: licenseFile.id })}
                                            title="Details"
                                            size="small"
                                        />
                                    ) : (
                                        <Box sx={{ fontStyle: 'italic' }}>deactivated</Box>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </CardContent>
        </Collapse>
    );
}

LicenseFilesCard.propTypes = {
    open: bool.isRequired,
    licenseFiles: arrayOf(object).isRequired,
    license: object.isRequired,
};

export function LicenseFiles({ license, initiallyOpen }) {
    const { classes } = useStyles();
    const [open, setOpen] = useState(initiallyOpen ?? false);

    const { data: licenseFiles, error } = useQuery({
        queryKey: ['LicenseFile', 'list', { licenseId: license.id }],
        queryFn: () => loadLicenseFileList({ licenseId: license.id }),
        meta: { errorMessage: 'Loading license files failed' },
    });

    if (error) {
        return null;
    }

    return (
        <Card className={classes.card}>
            <CardHeader
                onClick={() => setOpen(licenseFiles && !open)}
                subheader="License Files"
                action={
                    <IconButton size="large">
                        {!licenseFiles ? <CircularProgressIcon /> : open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                }
                className={classes.cardHeader}
                data-testid="LicenseFilesCardHeader"
            >
                <LoadingIndicator />
            </CardHeader>
            {licenseFiles && <LicenseFilesCard open={open} licenseFiles={licenseFiles} license={license} />}
        </Card>
    );
}

LicenseFiles.propTypes = {
    license: object.isRequired,
};
