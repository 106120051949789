import { generatePath } from 'react-router';

import { LinkButton } from 'commonComponents/LinkButton';

import { CreateIcon } from 'icons/CreateIcon';
import { propertyNames } from 'licenses/propertyNames';
import { ROLE_DEFINITIONS } from 'roles/definitions';
import { RoleDependentComponent } from 'roles/RoleDependentComponent';
import { ROUTES } from 'routes/routes';

export function LicenseUpgradeButton({ licenseDetails }) {
    const isActivated = !!licenseDetails.activeLicenseFilesCount;
    const hasSerialNumber =
        licenseDetails?.properties?.find(({ name }) => name === propertyNames.PROPERTY_SERIAL_NUMBER) != null;
    if (isActivated && hasSerialNumber) {
        return (
            <RoleDependentComponent
                userRole={ROLE_DEFINITIONS.PrivilegedCustomer}
                component={LinkButton}
                color="secondary"
                iconClass={CreateIcon}
                route={generatePath(ROUTES.LICENSE_UPGRADE, { id: licenseDetails.id })}
                title="Upgrade"
            />
        );
    }

    return null;
}
