import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

export function ClaimConfirmationDialog({ isOpen, setOpen, username, sn, prod, pc, onAssign, onCancel }) {
    return (
        <Dialog open={isOpen} onClose={() => setOpen(false)} maxWidth="md">
            <DialogTitle>
                <Typography variant="h6">Claim License Confirmation</Typography>
            </DialogTitle>
            <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography display="inline">
                    Are you sure you would like to assign the below license to user {username}?
                </Typography>
                <Box>
                    <Typography variant="subtitle1" style={{ fontWeight: '500' }}>
                        License
                    </Typography>
                    <Typography>Product: {prod}</Typography>
                    <Typography>Product Configuration: {pc}</Typography>
                    <Typography>Serial Number: {sn}</Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    autofocus
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setOpen(false);
                        onAssign();
                    }}
                >
                    Assign
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setOpen(false);
                        onCancel();
                    }}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}
