import { Box } from '@mui/material';
import { makeStyles } from 'makeStyles';

import { useSettings } from 'api/settings';
import DemoSystemLogo from 'assets/demo_system_logo.png';
import DevSystemLogo from 'assets/dev_system_logo.png';
import TestSystemLogo from 'assets/test_system_logo.png';

const useStyles = makeStyles()({
    ignoreMouse: {
        width: '128px',
        height: '128px',
        pointerEvents: 'none',
    },
});

export function SystemLogo() {
    const { classes } = useStyles();
    const { data: appSettings } = useSettings();

    if (!appSettings) {
        return null;
    }

    return (
        <Box className={classes.ignoreMouse} position="absolute" top="100%" right="0">
            {appSettings.isTestSystem && (
                <img className={classes.ignoreMouse} src={TestSystemLogo} alt="Test System Logo" />
            )}
            {appSettings.isDevSystem && (
                <img className={classes.ignoreMouse} src={DevSystemLogo} alt="Dev System Logo" />
            )}
            {appSettings.isDemoSystem && (
                <img className={classes.ignoreMouse} src={DemoSystemLogo} alt="Demo System Logo" />
            )}
        </Box>
    );
}
