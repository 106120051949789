import { useCallback } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { FormContainer } from 'react-hook-form-mui';

import { Dialog, DialogActions, DialogTitle } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { CustomerFormContent } from 'customers/CustomerFormContent';
import { makeStyles } from 'makeStyles';

import { ActionButton } from 'commonComponents/ActionButton';

import { useUser } from 'api/authorization';
import { type CustomerDetails, createCustomer } from 'api/customers';
import { useHandleFormError } from 'api/hooks/errorHandling';
import { useCurrentProduct } from 'api/products';
import { UndoIcon } from 'icons';
import { CreateIcon } from 'icons/CreateIcon';
import { isApplication } from 'licenses/utils';
import { ROLE_DEFINITIONS } from 'roles/definitions';
import { useCurrentUserHasRole } from 'roles/helpers';

const INITIAL_VALUES = {
    full_name: '',
    company: '',
    email: '',
    address: '',
    phone_number: '',
    is_active: true,
    comment: '',
    country: '',
    city: '',
    owner_id: '',
    products: [],
};

const useStyles = makeStyles()(theme => ({
    inputField: {
        marginTop: theme.spacing(1),
        width: '100%',
    },
}));

export function LicenseCreateCustomerDialog({ open, onClose }) {
    const { classes } = useStyles();
    const { data: currentProduct } = useCurrentProduct();
    const { data: currentUser } = useUser();
    const currentUserHasRole = useCurrentUserHasRole();
    const userIsLicenseManager = currentUserHasRole(ROLE_DEFINITIONS.LicenseManager);
    const defaultProps = {
        autoComplete: 'off',
        className: classes.inputField,
    };
    const formContext = useForm({
        defaultValues: {
            ...INITIAL_VALUES,
            products: [currentProduct],
            owner_id: userIsLicenseManager ? '' : currentUser.owner_id || '',
        },
    });
    const handleFormError = useHandleFormError(formContext);
    const { setValue, watch } = useFormContext();
    const product = watch('configuration.product');

    const queryClient = useQueryClient();
    const trigger = useCallback(
        async values => {
            try {
                const response = await createCustomer({
                    ...values,
                    username: values.email,
                    is_active: false,
                    products: values.products?.map(p => p.id),
                    groups: ['Customer'],
                });
                queryClient.setQueriesData<CustomerDetails[]>(['Customer', 'list'], oldList => [response, ...oldList]);
                setValue('user', response.id, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
                if (!isApplication({ product }, currentProduct)) {
                    setValue('ownerId', values.owner_id, {
                        shouldDirty: true,
                        shouldTouch: true,
                        shouldValidate: true,
                    });
                }
                onClose();
            } catch (error) {
                handleFormError(error);
            }
        },
        [queryClient, setValue, product, currentProduct, onClose, handleFormError]
    );

    const handleSubmit = useCallback(event => {
        event.preventDefault();
        event.stopPropagation();
    }, []);

    return (
        <Dialog
            {...{ open, onClose }}
            data-testid="LicenseCreateCustomerDialog"
            onSubmit={handleSubmit}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>Create Customer</DialogTitle>
            <FormContainer formContext={formContext} onSuccess={trigger}>
                <CustomerFormContent defaultProps={defaultProps} form={formContext} disableProducts />
                <DialogActions>
                    <ActionButton
                        color="secondary"
                        iconClass={CreateIcon}
                        title=" Create"
                        variant="contained"
                        type="submit"
                        data-testid="LicenseCreateCustomer_CreateButton"
                    />
                    <ActionButton
                        color="primary"
                        iconClass={UndoIcon}
                        inProgress={false}
                        onClick={onClose}
                        title="Cancel"
                        variant="contained"
                        data-testid="LicenseCreateCustomer_CancelButton"
                    />
                </DialogActions>
            </FormContainer>
        </Dialog>
    );
}
