import { useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { Card, CardHeader } from '@mui/material';

import { useDefaultHardwareBinding } from 'common/useDefaultHardwareBinding';

import { PageContainer } from 'commonComponents/PageContainer';

import { createProductConfiguration } from 'api/productConfigurations';
import { useCurrentProduct } from 'api/products';
import { CreateIcon } from 'icons/CreateIcon';
import { ROUTES } from 'routes/routes';

import { ConfigurationForm } from './ConfigurationForm/ConfigurationForm';

export function CreateConfiguration() {
    const history = useHistory();
    const configurationRef = useRef();
    const { data: currentProduct } = useCurrentProduct();

    const defaultHardwareBinding = useDefaultHardwareBinding(currentProduct);

    if (!currentProduct) {
        return null;
    }

    const initialValues = {
        name: '',
        runtimeFeatures: [],
        validity: 366,
        validityUnit: 'd',
        supportValidity: 366,
        supportValidityUnit: 'd',
        features: currentProduct.applicationFeatures,
        hasSerialNumber: false,
        partNo: '',
        internal: false,
        invalidated: false,
        hardwareBinding: defaultHardwareBinding,
        maximumActivations: 1,
        product: currentProduct,
    };

    return (
        <PageContainer>
            <Card>
                <CardHeader title={`Create new Configuration for ${currentProduct.name}`} />
                <ConfigurationForm
                    cancelButtonRoute={ROUTES.CONFIGURATION_LIST}
                    history={history}
                    initialValues={initialValues}
                    submitApiCall={createProductConfiguration}
                    submitButtonIconClass={CreateIcon}
                    submitButtonText="Create"
                    submitErrorMessage="Configuration creation failed."
                    configurationRef={configurationRef}
                />
            </Card>
        </PageContainer>
    );
}
