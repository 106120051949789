import { CheckboxButtonGroup, CheckboxElement, TextFieldElement } from 'react-hook-form-mui';

import { Box, CardContent, TextField, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { GooglePlaceAutocomplete } from 'customers/GooglePlaceAutocomplete';

import { AutocompleteElement } from 'commonComponents/AutocompleteElement';
import { NonFieldErrors } from 'commonComponents/CommonFormComponents';
import { LoadingIndicator } from 'commonComponents/LoadingIndicator';

import { loadCompanyList, loadOwnerIdList } from 'api/customers';
import { useProducts } from 'api/products';

import { Product } from './Product';
import { USERNAME_REGEX } from './utils';

export type UserFormFields = Pick<
    import('api/users').UserDetails,
    | 'address'
    | 'city'
    | 'comment'
    | 'company'
    | 'country'
    | 'email'
    | 'full_name'
    | 'groups'
    | 'is_internal'
    | 'is_active'
    | 'owner_id'
    | 'phone_number'
    | 'products'
    | 'username'
>;

export type UserFormContentProps = {
    defaultProps: Partial<
        import('react-hook-form-mui').TextFieldElementProps & import('react-hook-form-mui').CheckboxElementProps
    >;
    form: import('react-hook-form').UseFormReturn<UserFormFields>;
};

export function UserFormContent({ defaultProps, form }: UserFormContentProps) {
    const theme = useTheme();
    const { data: allProducts } = useProducts();
    const { data: allCompanies } = useQuery({
        queryKey: ['Company', 'list'],
        queryFn: () => loadCompanyList({ onlyUserProducts: true }),
        meta: { errorMessage: 'Loading companies failed' },
    });
    const { data: allOwnerIds } = useQuery({
        queryKey: ['OwnerId', 'list'],
        queryFn: () => loadOwnerIdList({ onlyUserProducts: true }),
        meta: { errorMessage: 'Loading owner-ids failed.' },
    });
    const { watch } = form;
    const [city, country] = watch(['city', 'country']);

    if (!allProducts || !allCompanies || !allOwnerIds) {
        return <LoadingIndicator />;
    }

    return (
        <CardContent>
            <Box display="flex" flexDirection="column" gap={theme.spacing(2)}>
                <CheckboxButtonGroup
                    name="groups"
                    options={[
                        { id: 'LicenseManager', label: 'License Manager' },
                        { id: 'PrivilegedCustomer', label: 'Privileged Customer' },
                        { id: 'Customer', label: 'Customer' },
                    ]}
                    row
                />
                <TextFieldElement
                    label="User Name"
                    name="username"
                    required
                    validation={{ pattern: USERNAME_REGEX }}
                    parseError={error => {
                        if (error.message) {
                            return error.message;
                        }
                        if (error.type === 'pattern') {
                            return 'Letters, digits and @/./+/-/_ only';
                        }
                        return 'Unknown error';
                    }}
                    {...defaultProps}
                />
                <TextFieldElement label="Email" name="email" type="email" required {...defaultProps} />
                <TextFieldElement label="Full Name" name="full_name" required {...defaultProps} />
                <Product allProducts={allProducts} />
                <AutocompleteElement
                    label="Company"
                    name="company"
                    options={allCompanies}
                    freeSolo
                    autoSelect
                    required
                />
                <GooglePlaceAutocomplete form={form} />
                <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={theme.spacing(1)}>
                    {/* Regular TextFields with watch to ensure updates */}
                    <TextField label="Country" name="country" disabled variant="outlined" value={country} />
                    <TextField label="City" name="city" disabled variant="outlined" value={city} />
                </Box>
                <TextFieldElement
                    label="Phone Number"
                    name="phone_number"
                    validation={{ maxLength: 20 }}
                    parseError={error => {
                        if (error.type === 'maxLength') {
                            return 'Max 20 characters allowed';
                        }
                        return error.message;
                    }}
                    {...defaultProps}
                />
                <AutocompleteElement
                    testId="Customer_Default_Owner_Id_Input"
                    label="Default Owner ID"
                    name="owner_id"
                    options={allOwnerIds}
                    freeSolo
                    autoSelect
                />
                <TextFieldElement label="Comment" name="comment" {...defaultProps} />
                <CheckboxElement label="Internal" name="is_internal" />
                <CheckboxElement label="Active" name="is_active" />
                <NonFieldErrors />
            </Box>
        </CardContent>
    );
}
