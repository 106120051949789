import { useState } from 'react';
import { generatePath } from 'react-router';

import {
    Card,
    CardContent,
    CardHeader,
    Collapse,
    Divider,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';
import clsx from 'clsx';
import { format, parseJSON } from 'date-fns';
import { makeStyles } from 'makeStyles';

import { DATE_FORMAT } from 'common/constants';

import { useTableBodyStyles } from 'commonComponents/ObjectTable/styles';

import { LicenseListEntry, useCustomerLicenses } from 'api/licenses';
import { ExpandLessIcon, ExpandMoreIcon } from 'icons';
import { expirationDate } from 'licenses/utils';

import { LinkButton } from '../commonComponents/LinkButton';
import { ROUTES } from '../routes/routes';

const useStyles = makeStyles()(() => ({
    cardContent: {
        paddingTop: 0,
        '&:last-child': {
            paddingBottom: 0,
        },
    },
    table: {
        width: '100%',
    },
    tableLast: {
        width: '118px',
    },
}));

export function CustomerLicenses({ customerId }) {
    const { classes } = useStyles();
    const [open, setOpen] = useState(false);

    const { data, error } = useCustomerLicenses({ customerId });

    if (error || !data) {
        return null;
    }
    return (
        <Card>
            <CardHeader
                onClick={() => setOpen(!open)}
                subheader="Licenses"
                action={<IconButton size="large">{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>}
                data-testid="CustomerLicenses_CardHeader"
            />
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Divider />
                <CardContent className={classes.cardContent} data-testid="CustomerLicenses_CardContent">
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Creation Date</TableCell>
                                <TableCell>Product Configuration</TableCell>
                                <TableCell>License Files</TableCell>
                                <TableCell>Expiration Date</TableCell>
                                <TableCell>Details</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.results.map(license => {
                                return <CustomerLicenseEntry key={license.id} license={license} />;
                            })}
                        </TableBody>
                    </Table>
                </CardContent>
            </Collapse>
        </Card>
    );
}

function CustomerLicenseEntry({ license }: { license: LicenseListEntry }) {
    const { classes } = useTableBodyStyles();
    const cellClasses = clsx(license.invalidated && classes.rowInvalidated, license.isExpired && classes.rowError);

    return (
        <TableRow>
            <TableCell className={cellClasses}>
                {license.creation_date && format(parseJSON(license.creation_date), DATE_FORMAT)}
            </TableCell>
            <TableCell className={cellClasses}>{license.configuration}</TableCell>
            <TableCell className={cellClasses}>{license.licenseFilesCount}</TableCell>
            <TableCell className={cellClasses}>{expirationDate(license, license.expiration_date)}</TableCell>
            <TableCell>
                <LinkButton
                    variant="outlined"
                    color="primary"
                    route={generatePath(ROUTES.LICENSE_DETAIL, { id: license.id })}
                    title="Details"
                    size="small"
                />
            </TableCell>
        </TableRow>
    );
}
