import { forwardRef } from 'react';

import { Fab, Tooltip } from '@mui/material';
import { makeStyles } from 'makeStyles';

import { MenuIcon, SecurityIcon, UserIcon } from 'icons';

const useStyles = makeStyles()({
    root: {
        borderStyle: 'solid',
        borderWidth: 2,
    },
});

export const AppBarMenuButton = forwardRef(({ onClick, user }, ref) => {
    const { classes } = useStyles();

    function userTooltip() {
        if (!user) {
            return '';
        }
        return user.full_name ? `${user.username} (${user.full_name})` : user.username;
    }

    function buttonContent() {
        if (!user) {
            return <MenuIcon data-testid="AppBarMenuButton_NoUserIcon" />;
        }
        if (user.is_superuser) {
            return <SecurityIcon data-testid="AppBarMenuButton_SuperUserIcon" />;
        }
        return <UserIcon data-testid="AppBarMenuButton_UserIcon" />;
    }

    return (
        <Tooltip data-testid="AppBarMenuButton_Tooltip" title={userTooltip()}>
            <Fab ref={ref} className={classes.root} color="primary" onClick={onClick}>
                {buttonContent()}
            </Fab>
        </Tooltip>
    );
});
