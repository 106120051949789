import { Paper, Table, TableContainer } from '@mui/material';
import { makeStyles } from 'makeStyles';
import PropTypes from 'prop-types';

import { LoadingIndicator } from 'commonComponents/LoadingIndicator';

import { ObjectTableBody } from './ObjectTableBody';
import { ObjectTableContext } from './ObjectTableContext';
import { ObjectTableHeader } from './ObjectTableHeader';
import { ObjectTableInteraction } from './ObjectTableInteraction';
import { ObjectTablePagination } from './ObjectTablePagination';

const useStyles = makeStyles()({
    paper: {
        position: 'relative',
        flexGrow: 1,
        flexShrink: 1,
        overflow: 'hidden',
        paddingBottom: '50px',
        display: 'flex',
        flexDirection: 'column',
    },
    tableContainer: {
        overflow: 'auto',
        flexGrow: 1,
    },
    pagination: {
        position: 'absolute',
        bottom: 0,
        right: '10%',
    },
});

/**
 * @param {Object} props
 * @param {import('react-table').TableInstance} props.table
 * @param {Number} [props.count] - Total number of rows, only required when using manual pagination
 */
export function ObjectTable({ loading, Buttons, table, count, historyPath }) {
    const { classes } = useStyles();

    return (
        <ObjectTableContext historyPath={historyPath}>
            <ObjectTableInteraction Buttons={Buttons} table={table} />
            {loading ? (
                <LoadingIndicator />
            ) : (
                <Paper className={classes.paper}>
                    <TableContainer className={classes.tableContainer}>
                        <Table stickyHeader>
                            <ObjectTableHeader table={table} />
                            <ObjectTableBody table={table} />
                        </Table>
                    </TableContainer>
                    <ObjectTablePagination className={classes.pagination} table={table} count={count} />
                </Paper>
            )}
        </ObjectTableContext>
    );
}

ObjectTable.propTypes = {
    historyPath: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    Buttons: PropTypes.func.isRequired,
    table: PropTypes.object.isRequired,
    count: PropTypes.number,
};
