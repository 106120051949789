import { useForm } from 'react-hook-form';
import { FormContainer } from 'react-hook-form-mui';
import { TextFieldElement } from 'react-hook-form-mui';
import { useHistory } from 'react-router-dom';

import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    MenuItem,
    Table,
    TableBody,
    Typography,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';

import { ActionButton } from 'commonComponents/ActionButton';
import { CommonFormComponents } from 'commonComponents/CommonFormComponents';
import { PageContainer } from 'commonComponents/PageContainer';
import { TableRow } from 'commonComponents/TableRow';

import { type LoggedInUser, useUser } from 'api/authorization';
import { useHandleFormError } from 'api/hooks/errorHandling';
import { EditIcon } from 'icons';
import { ROLE_DEFINITIONS } from 'roles/definitions';
import { userHasRole } from 'roles/helpers';
import { ROUTES } from 'routes/routes';

import { type UserDetails, mutateUser, updateUser } from '../api/users';

export function ProfilePage() {
    const { data: user } = useUser();
    const history = useHistory();
    const defaultValues = { default_screen: user.default_screen, default_product: user.default_product?.id || null };
    const formContext = useForm({
        defaultValues,
    });

    const queryClient = useQueryClient();
    const handleFormError = useHandleFormError(formContext);
    const { mutate: trigger, isLoading: requestInProgress } = useMutation<UserDetails, unknown, typeof defaultValues>({
        mutationFn: values =>
            updateUser(user.id, {
                ...user,
                products: user.products.map(p => p.id),
                default_product: values.default_product,
                default_screen: values.default_screen,
            }),
        onSuccess: (response, values) => {
            mutateUser(queryClient, response);
            queryClient.setQueryData<{ user: LoggedInUser }>(['LoggedInUser'], old => ({
                user: {
                    ...old.user,
                    default_screen: response.default_screen,
                    default_product: response.default_product,
                },
            }));
            formContext.reset(values);
            history.push(ROUTES.LOGIN);
        },

        onError: handleFormError,
    });

    return (
        <PageContainer maxWidth="sm">
            <FormContainer formContext={formContext} onSuccess={values => trigger(values)}>
                <CommonFormComponents />
                <Card>
                    <CardHeader title="Your User Profile"></CardHeader>
                    <CardContent>
                        <Table size="small">
                            <TableBody>
                                <TableRow title="Username" value={user.username} />
                                <TableRow title="Full Name" value={user.full_name} />
                                <TableRow title="Email Address" value={user.email} />
                                <TableRow
                                    title="User Type"
                                    value={user?.groups.map(g => _.startCase(g)).join(', ') || ''}
                                />
                            </TableBody>
                        </Table>
                        <Divider orientation="vertical" textAlign="right">
                            <Typography gutterBottom variant="h6" component="div">
                                User Preferences:
                            </Typography>
                        </Divider>
                        <TextFieldElement name="default_product" select fullWidth label="Default Product">
                            {user.products.length !== 1 && <MenuItem value="">-- None --</MenuItem>}
                            {user.products.map(option => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextFieldElement>
                        {userHasRole(user, ROLE_DEFINITIONS.LicenseManager) && (
                            <TextFieldElement
                                name="default_screen"
                                sx={{
                                    alignSelf: 'stretch',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'flex-end',
                                    mt: 2,
                                }}
                                select
                                fullWidth
                                label="Default Screen"
                            >
                                <MenuItem key="D" value="D">
                                    DashBoard
                                </MenuItem>
                                <MenuItem key="L" value="L">
                                    Licenses
                                </MenuItem>
                            </TextFieldElement>
                        )}
                    </CardContent>
                    <CardActions
                        sx={{
                            alignSelf: 'stretch',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'flex-end',
                            pt: 0,
                            pr: 2,
                            pb: 2,
                        }}
                    >
                        <ActionButton
                            color="secondary"
                            iconClass={EditIcon}
                            inProgress={requestInProgress}
                            title="Update"
                            type="submit"
                            variant="contained"
                            disabled={!formContext.formState.isDirty}
                        />
                    </CardActions>
                </Card>
            </FormContainer>
        </PageContainer>
    );
}
