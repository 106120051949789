import { CardActions } from '@mui/material';

import { CreateLicenseFileButton } from './CreateLicenseFileButton';
import { DeleteOrInvalidateLicense } from './DeleteOrInvalidateLicense';
import { LicenseDetailsBackButton } from './LicenseDetailsBackButton';
import { LicenseEditButton } from './LicenseEditButton';
import { LicenseRenewButton } from './LicenseRenewButton';
import { LicenseUpgradeButton } from './LicenseUpgradeButton';

export function LicenseCardActions({ licenseDetails }) {
    return (
        <CardActions>
            <CreateLicenseFileButton {...{ licenseDetails }} />
            <LicenseUpgradeButton {...{ licenseDetails }} />
            <LicenseRenewButton {...{ licenseDetails }} />
            <DeleteOrInvalidateLicense {...{ licenseDetails }} />
            <LicenseEditButton {...{ licenseDetails }} />
            <LicenseDetailsBackButton />
        </CardActions>
    );
}
