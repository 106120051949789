import { generatePath } from 'react-router';
import { useParams } from 'react-router-dom';

import { Card, CardActions, CardContent, Table, TableBody } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { format, parseJSON } from 'date-fns';
import ShortId from 'shortid';

import { DATE_FORMAT } from 'common/constants';

import { DetailsCardTitle } from 'commonComponents/DetailsCardTitle';
import { LinkButton } from 'commonComponents/LinkButton';
import { LoadingIndicator } from 'commonComponents/LoadingIndicator';
import { cellValue } from 'commonComponents/ObjectTable/ObjectTableContext';
import { PageContainer } from 'commonComponents/PageContainer';
import { TableRow } from 'commonComponents/TableRow';

import { useUser } from 'api/authorization';
import { loadUserDetails } from 'api/users';
import { EditIcon, UndoIcon } from 'icons';
import { ROLE_DEFINITIONS } from 'roles/definitions';
import { RoleDependentComponent } from 'roles/RoleDependentComponent';
import { ROUTES } from 'routes/routes';

import { UserDeleteButton } from './UserDeleteButton';
import { UserInvalidateButton } from './UserInvalidateButton';
import { otherAdminUserIsLoggedInUser } from './utils';

const ROW_DATA_MAPPER = [
    {
        title: 'Username',
        accessor: 'username',
    },
    {
        title: 'Full Name',
        accessor: 'full_name',
    },
    {
        title: 'Email address',
        accessor: 'email',
    },
    {
        title: 'Products',
        accessor: details => details.products?.map(p => p.name).join(', ') || '',
    },
    {
        title: 'Company',
        accessor: 'company',
    },
    {
        title: 'Country',
        accessor: 'country',
    },
    {
        title: 'City',
        accessor: 'city',
    },
    {
        title: 'Phone Number',
        accessor: 'phone_number',
    },
    {
        title: 'Default Owner ID',
        accessor: 'owner_id',
    },
    {
        title: 'Comment',
        accessor: 'comment',
    },
    {
        title: 'Joined',
        accessor: details => details.date_joined && format(parseJSON(details.date_joined), DATE_FORMAT),
    },
    {
        title: 'User Role(s)',
        accessor: details => details.groups.join(', '),
    },
    { title: 'Internal', accessor: 'is_internal' },
    { title: 'Active', accessor: 'is_active' },
    { title: 'Invalidated', accessor: 'invalidated' },
];

export function UserDetails() {
    const { data: loggedInUser } = useUser();
    const { id: userId } = useParams<{ id: string }>();

    const { data: user, error } = useQuery({
        queryKey: ['User', 'detail', userId],
        queryFn: () => loadUserDetails(Number(userId)),
        meta: { errorMessage: 'Loading user failed.' },
    });

    if (error) {
        return null;
    }

    if (!user) {
        return <LoadingIndicator />;
    }

    const rowData = ROW_DATA_MAPPER.map(rowData => {
        return <TableRow key={ShortId.generate()} title={rowData.title} value={cellValue(user, rowData)} />;
    });

    return (
        <PageContainer maxWidth="md">
            <Card>
                <CardContent data-testid="UserDetails_CardContent">
                    <DetailsCardTitle subtitle="User" title={user.username} />
                    <Table size="small">
                        <TableBody>{rowData}</TableBody>
                    </Table>
                </CardContent>
                <CardActions>
                    {otherAdminUserIsLoggedInUser(user, loggedInUser) && (
                        <RoleDependentComponent
                            userRole={ROLE_DEFINITIONS.Administrator}
                            component={LinkButton}
                            iconClass={EditIcon}
                            route={generatePath(ROUTES.USER_EDIT, { id: user.id })}
                            title="Edit"
                            data-testid="UserDetails_EditButton"
                        />
                    )}
                    {user.hasLicenses ? <UserInvalidateButton user={user} /> : <UserDeleteButton user={user} />}
                    <LinkButton iconClass={UndoIcon} route={ROUTES.USER_LIST} title="Back" />
                </CardActions>
            </Card>
        </PageContainer>
    );
}
