import { useState } from 'react';

import { arrayOf, bool, func, number, object, string } from 'prop-types';

import { ActionButton } from 'commonComponents/ActionButton';

import { MoveIcon } from 'icons';
import { ROLE_DEFINITIONS } from 'roles/definitions';
import { RoleDependentComponent } from 'roles/RoleDependentComponent';

import { MoveLicenseFileDialog } from '../MoveLicenseFileDialog';

export function LicenseFileMoveButton({
    license,
    licenseFileIds,
    disabled = false,
    onSubmitCallback,
    title = 'Move to license',
    tooltip,
}) {
    const [open, setOpen] = useState(false);

    if (license.invalidated || !license.configuration.product.allow_move_license) return null;

    return (
        <>
            <RoleDependentComponent
                userRole={ROLE_DEFINITIONS.LicenseManager}
                component={ActionButton}
                iconClass={MoveIcon}
                variant="contained"
                color="primary"
                onClick={() => setOpen(true)}
                title={title}
                data-testid="LicenseFileDetails_Move"
                disabled={disabled}
                tooltip={tooltip}
            />
            {open && (
                <MoveLicenseFileDialog
                    license={license}
                    licenseFileIds={licenseFileIds}
                    setOpen={setOpen}
                    onSubmitCallback={onSubmitCallback}
                />
            )}
        </>
    );
}

LicenseFileMoveButton.propTypes = {
    license: object.isRequired,
    licenseFileIds: arrayOf(number.isRequired).isRequired,
    disabled: bool,
    onSubmitCallback: func,
    title: string,
    tooltip: string,
};
