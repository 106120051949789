import { get } from './axiosWrapper';
import { API_ENDPOINTS } from './endpoints';

type EmailTemplate = {
    additionalRecipients: string;
    plainText: string;
    sender: string;
    subject: string;
};

export function loadEmailTemplate(payload) {
    return get<EmailTemplate>(API_ENDPOINTS.EMAIL_TEMPLATE_DETAIL, {
        params: {
            type: payload.type,
            full_name: payload.full_name,
            username: payload.username,
            email: payload.email,
        },
    }).then(res => res.data);
}
