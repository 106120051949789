import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { useHistory } from 'react-router-dom';

import { Card, CardActions, CardContent, CardHeader, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { makeStyles } from 'makeStyles';

import { useUser } from 'api/authorization';
import { useHandleFormError } from 'api/hooks/errorHandling';
import { assignLicense } from 'api/licenses';
import { SearchIcon } from 'icons';

import { ActionButton } from './ActionButton';
import { ClaimConfirmationDialog } from './ClaimConfirmationDialog';

const initialValues = { serialNumber: '' };
let targetSN = '';
let targetProd = '';
let targetConfig = '';

const useStyles = makeStyles()(theme => ({
    inputField: {
        marginTop: theme.spacing(1),
        width: '100%',
    },
}));

export function HandleError(error, handler) {
    if (error.response?.status == null) {
        handler(error, 'Error assigning license.');
        return;
    }

    switch (error.response.status) {
        // Not Found Error
        case 404:
            handler(error, 'Error: No matching license found.');
            break;
        // Bad Request Error
        case 400:
            switch (error.response.data) {
                case 'more_than_one_found':
                    handler(error, 'Error: Multiple licenses match this serial number.');
                    break;
                case 'already_assigned':
                    handler(
                        error,
                        'Error: This license is associated with another customer. Please contact customer service.'
                    );
                    break;
                default:
                    break;
            }
            break;
        default:
            break;
    }
}

export function ClaimLicense() {
    const { classes } = useStyles();
    const { data: user } = useUser();
    const [progressText, setProgressText] = useState('');
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const formContext = useForm({ defaultValues: initialValues, mode: 'onTouched' });
    const handleFormError = useHandleFormError(formContext);
    const history = useHistory();

    let headerText;
    if (user?.products[0]) {
        headerText = 'Claim license for: ';
    } else {
        headerText = 'No product is assigned to user: ';
    }

    const queryClient = useQueryClient();
    const onSearch = async values => {
        try {
            setProgressText('Searching for license...');
            targetSN = values.serialNumber;
            // Check if license is found for sn
            // Send false for not yet confirmed
            const license = await assignLicense(targetSN, false);
            // If no errors and valid license, prompt for confirmation
            if (license) {
                targetProd = license.productName;
                targetConfig = license.configuration?.name;
                setProgressText('Confirming license assignment...');
                setConfirmDialogOpen(true);
                queryClient.invalidateQueries(['License', 'detail', license.id]);
            }
            return license;
        } catch (error) {
            setProgressText('Try another Serial Number...');
            HandleError(error, handleFormError);
            return;
        }
    };

    const { mutate: onConfirm, isLoading: isMutating } = useMutation({
        onMutate: () => setProgressText('Assigning license'),
        mutationFn: () => assignLicense(targetSN, true),
        onSuccess: license => {
            history.push(`/license/${license.id}`);
        },
        onError: error => {
            setProgressText('Try another Serial Number...');
            HandleError(error, handleFormError);
        },
    });

    const onCancel = () => {
        setProgressText('Assignment canceled, enter another Serial Number...');
    };

    return (
        <>
            <Card>
                <CardHeader title={headerText + user?.username} />
                <FormContainer formContext={formContext} onSuccess={onSearch}>
                    <CardContent>
                        <Typography>
                            {'Please enter a System Serial Number to assign a License to your account:'}
                        </Typography>
                        <Typography variant="caption">{progressText}</Typography>
                        <TextFieldElement
                            label="Serial Number"
                            name="serialNumber"
                            required
                            autoComplete="off"
                            variant="outlined"
                            className={classes.inputField}
                            data-testid="ClaimLicense_SerialNumberInput"
                            inputProps={{ role: 'textbox' }}
                        />
                    </CardContent>
                    <CardActions>
                        <ActionButton
                            color="primary"
                            iconClass={SearchIcon}
                            title="Search"
                            variant="contained"
                            inProgress={isMutating}
                            type="submit"
                            data-testid="ClaimLicense_SearchButton"
                        />
                    </CardActions>
                </FormContainer>
            </Card>
            <ClaimConfirmationDialog
                isOpen={confirmDialogOpen}
                setOpen={setConfirmDialogOpen}
                username={user?.username}
                sn={targetSN}
                prod={targetProd}
                pc={targetConfig}
                onAssign={onConfirm}
                onCancel={onCancel}
            />
        </>
    );
}
