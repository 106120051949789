import { Redirect, Route, Switch } from 'react-router-dom';

import { Box } from '@mui/material';
import { CreateCustomer } from 'customers/CreateCustomer';
import { CustomerDetails } from 'customers/CustomerDetails';
import { CustomerList } from 'customers/CustomerList';
import { EditCustomerWrapper } from 'customers/EditCustomerWrapper';
import { RevertCustomer } from 'customers/RevertCustomer';
import { Dashboard } from 'dashboard/Dashboard';
import { ConfigurationDetails } from 'productConfigurations/ConfigurationDetails';
import { ConfigurationList } from 'productConfigurations/ConfigurationList';
import { CreateConfiguration } from 'productConfigurations/CreateConfiguration';
import { EditConfiguration } from 'productConfigurations/EditConfiguration';
import { ProfilePage } from 'profile/ProfilePage';

import { ClaimLicense } from 'commonComponents/ClaimLicense';
import { LoadingIndicator } from 'commonComponents/LoadingIndicator';

import { useUser } from 'api/authorization';
import { useCurrentProduct } from 'api/products';
import { useSettings } from 'api/settings';
import { AppBar } from 'appBar/AppBar';
import { CreateLicense } from 'licenses/CreateLicense';
import { EditLicense } from 'licenses/EditLicense';
import { LicenseBilling } from 'licenses/LicenseBilling';
import { LicenseDetails } from 'licenses/LicenseDetails/LicenseDetails';
import { LicenseFileDetails } from 'licenses/LicenseFiles/LicenseFileDetails';
import { CreateLicenseFile } from 'licenses/LicenseFiles/LicenseFileForm/CreateLicenseFile';
import { EditLicenseFile } from 'licenses/LicenseFiles/LicenseFileForm/EditLicenseFile';
import { LicenseList } from 'licenses/LicenseList/LicenseList';
import { UpgradeLicense } from 'licenses/UpgradeLicense';
import { LoginPage } from 'loginPage/LoginPage';
import { ResetPasswordConfirmPage } from 'resetPassword/ResetPasswordConfirmPage';
import { ResetPasswordPage } from 'resetPassword/ResetPasswordPage';
import { ROLE_DEFINITIONS } from 'roles/definitions';
import { NotAllowedPage } from 'routes/NotAllowedPage';
import { NotFoundPage } from 'routes/NotFoundPage';
import { RequireAuth, RequireRole, RequireValidUser } from 'routes/PrivateRoute';
import { ROUTES, useDefaultRoute } from 'routes/routes';
import { CreateUser } from 'users/CreateUser';
import { EditUser } from 'users/EditUser';
import { EmailUser } from 'users/EmailUser';
import { UserConfirmation } from 'users/UserConfirmation';
import { UserDetails } from 'users/UserDetails';
import { UserList } from 'users/UserList';
import { UserRegistration } from 'users/UserRegistration';
import { UserRegistrationIncomplete } from 'users/UserRegistrationIncomplete';

function RedirectToDefaultRoute() {
    const defaultRoute = useDefaultRoute();
    return <Redirect to={defaultRoute} />;
}

export function App() {
    const globalDataLoaded = useLoadGlobalData();

    if (!globalDataLoaded) {
        return <LoadingIndicator />;
    }

    return (
        <Box position="fixed" display="flex" flexDirection="column" width="100%" height="100%" data-testid="App">
            <AppBar />
            <Box display="flex" flexDirection="column" overflow="hidden" flexGrow={1}>
                <Switch>
                    <Route exact path={ROUTES.LOGIN}>
                        <LoginPage />
                    </Route>
                    <Route exact path={ROUTES.DASHBOARD}>
                        <RequireRole role={ROLE_DEFINITIONS.LicenseManager}>
                            <Dashboard />
                        </RequireRole>
                    </Route>
                    <Route exact path={ROUTES.LICENSE_CREATE}>
                        <RequireRole role={ROLE_DEFINITIONS.PrivilegedCustomer}>
                            <CreateLicense />
                        </RequireRole>
                    </Route>
                    <Route exact path={ROUTES.LICENSE_CLAIM}>
                        <RequireRole role={ROLE_DEFINITIONS.Customer}>
                            <RequireValidUser>
                                <ClaimLicense />
                            </RequireValidUser>
                        </RequireRole>
                    </Route>
                    <Route exact path={ROUTES.LICENSE_LIST}>
                        <RequireAuth>
                            <LicenseList />
                        </RequireAuth>
                    </Route>
                    <Route exact path={ROUTES.LICENSE_DETAIL}>
                        <RequireAuth>
                            <LicenseDetails />
                        </RequireAuth>
                    </Route>
                    <Route exact path={ROUTES.LICENSEFILE_DETAIL}>
                        <RequireAuth>
                            <LicenseFileDetails />
                        </RequireAuth>
                    </Route>
                    <Route exact path={ROUTES.LICENSEFILE_CREATE}>
                        <RequireRole role={ROLE_DEFINITIONS.Customer}>
                            <CreateLicenseFile />
                        </RequireRole>
                    </Route>
                    <Route exact path={ROUTES.LICENSEFILE_EDIT}>
                        <RequireRole role={ROLE_DEFINITIONS.LicenseManager}>
                            <EditLicenseFile />
                        </RequireRole>
                    </Route>
                    <Route exact path={ROUTES.LICENSE_BILLING}>
                        <RequireRole role={ROLE_DEFINITIONS.LicenseManager}>
                            <LicenseBilling />
                        </RequireRole>
                    </Route>
                    <Route exact path={ROUTES.LICENSE_EDIT}>
                        <RequireRole role={ROLE_DEFINITIONS.PrivilegedCustomer}>
                            <EditLicense />
                        </RequireRole>
                    </Route>
                    <Route exact path={ROUTES.LICENSE_UPGRADE}>
                        <RequireRole role={ROLE_DEFINITIONS.PrivilegedCustomer}>
                            <UpgradeLicense />
                        </RequireRole>
                    </Route>
                    <Route exact path={ROUTES.CUSTOMER_LIST}>
                        <RequireRole role={ROLE_DEFINITIONS.Customer}>
                            <CustomerList />
                        </RequireRole>
                    </Route>
                    <Route exact path={ROUTES.CUSTOMER_CREATE}>
                        <RequireRole role={ROLE_DEFINITIONS.PrivilegedCustomer}>
                            <CreateCustomer />
                        </RequireRole>
                    </Route>
                    <Route exact path={ROUTES.CUSTOMER_DETAIL}>
                        <RequireRole role={ROLE_DEFINITIONS.Customer}>
                            <CustomerDetails />
                        </RequireRole>
                    </Route>
                    <Route exact path={ROUTES.CUSTOMER_EDIT}>
                        <RequireRole role={ROLE_DEFINITIONS.PrivilegedCustomer}>
                            <EditCustomerWrapper />
                        </RequireRole>
                    </Route>
                    <Route exact path={ROUTES.CUSTOMER_REVERT}>
                        <RequireRole role={ROLE_DEFINITIONS.LicenseManager}>
                            <RevertCustomer />
                        </RequireRole>
                    </Route>
                    <Route exact path={ROUTES.USER_CREATE}>
                        <RequireRole role={ROLE_DEFINITIONS.Administrator}>
                            <CreateUser />
                        </RequireRole>
                    </Route>
                    <Route exact path={ROUTES.USER_REGISTER}>
                        <UserRegistration />
                    </Route>
                    <Route exact path={ROUTES.USER_REGISTER_INCOMPLETE}>
                        <UserRegistrationIncomplete />
                    </Route>
                    <Route exact path={ROUTES.USER_CONFIRM}>
                        <UserConfirmation />
                    </Route>
                    <Route exact path={ROUTES.USER_EMAIL}>
                        <RequireRole role={ROLE_DEFINITIONS.LicenseManager}>
                            <EmailUser />
                        </RequireRole>
                    </Route>
                    <Route exact path={ROUTES.USER_LIST}>
                        <RequireRole role={ROLE_DEFINITIONS.Administrator}>
                            <UserList />
                        </RequireRole>
                    </Route>
                    <Route exact path={ROUTES.USER_DETAIL}>
                        <RequireRole role={ROLE_DEFINITIONS.Administrator}>
                            <UserDetails />
                        </RequireRole>
                    </Route>
                    <Route exact path={ROUTES.USER_EDIT}>
                        <RequireRole role={ROLE_DEFINITIONS.Administrator}>
                            <EditUser />
                        </RequireRole>
                    </Route>
                    <Route exact path={ROUTES.CONFIGURATION_LIST}>
                        <RequireRole role={ROLE_DEFINITIONS.PrivilegedCustomer}>
                            <ConfigurationList />
                        </RequireRole>
                    </Route>
                    <Route exact path={ROUTES.CONFIGURATION_CREATE}>
                        <RequireRole role={ROLE_DEFINITIONS.LicenseManager}>
                            <CreateConfiguration />
                        </RequireRole>
                    </Route>
                    <Route exact path={ROUTES.CONFIGURATION_DETAIL}>
                        <RequireRole role={ROLE_DEFINITIONS.PrivilegedCustomer}>
                            <ConfigurationDetails />
                        </RequireRole>
                    </Route>
                    <Route exact path={ROUTES.CONFIGURATION_EDIT}>
                        <RequireRole role={ROLE_DEFINITIONS.LicenseManager}>
                            <EditConfiguration />
                        </RequireRole>
                    </Route>
                    <Route exact path={ROUTES.RESET_PASSWORD_REQUEST}>
                        <ResetPasswordPage />
                    </Route>
                    <Route exact path={ROUTES.RESET_PASSWORD_CONFIRM}>
                        <ResetPasswordConfirmPage />
                    </Route>
                    <Route exact path={ROUTES.PROFILE}>
                        <RequireAuth>
                            <ProfilePage />
                        </RequireAuth>
                    </Route>
                    <Route exact path={ROUTES.NOT_ALLOWED}>
                        <RequireAuth>
                            <NotAllowedPage />
                        </RequireAuth>
                    </Route>
                    <Route exact path={'/'}>
                        <RequireAuth>
                            <RedirectToDefaultRoute />
                        </RequireAuth>
                    </Route>
                    <Route>
                        <NotFoundPage />
                    </Route>
                </Switch>
            </Box>
        </Box>
    );
}

function useLoadGlobalData() {
    const { isLoading: isLoadingUser, data: user } = useUser();
    const { isLoading: isLoadingSettings } = useSettings();
    const { isLoading: isLoadingCurrentProduct } = useCurrentProduct();

    return !isLoadingUser && !isLoadingSettings && (!isLoadingCurrentProduct || !Boolean(user));
}
