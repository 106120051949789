import { useMemo } from 'react';

import { createFilterOptions } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { AutocompleteElement } from 'commonComponents/AutocompleteElement';

import { loadLicenseSerialNumbers } from 'api/licenses';

export function SerialNumber({
    currentProduct,
    onSerialNumberSelected,
    label = 'Serial Number',
    name = 'serial_number',
    disabled = false,
}) {
    const { data: serialNumbers, isLoading } = useQuery({
        queryKey: ['LicenseSerialNumbers', 'list', { product: currentProduct?.id }],
        queryFn: () => loadLicenseSerialNumbers({ product: currentProduct?.id }),
        meta: { errorMessage: 'Loading serial numbers failed.' },
    });

    const options = useMemo(() => serialNumbers?.map(value => value.serial_number) ?? [], [serialNumbers]);

    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        limit: 100,
    });

    const isOptionEqualToValue = (option, value) => option?.toLowerCase() === value?.toLowerCase();

    const handleChanged = value => {
        if (onSerialNumberSelected && serialNumbers) {
            const matchingSn = serialNumbers.find(i => isOptionEqualToValue(i.serial_number, value));
            if (matchingSn) {
                onSerialNumberSelected(matchingSn);
            }
        }
    };

    return (
        <AutocompleteElement
            label={label}
            name={name}
            options={options}
            autoSelect
            freeSolo
            filterOptions={filterOptions}
            isOptionEqualToValue={isOptionEqualToValue}
            onChangeCallback={(event, value, _reason, _details) => handleChanged(value)}
            loading={isLoading}
            disabled={disabled}
        />
    );
}
