import { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { ClickAwayListener, Divider, ListItemIcon, ListItemText, MenuItem, MenuList, Popover } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { noop } from 'lodash';

import { logout, useUser } from 'api/authorization';
import { clearCache } from 'api/cache';
import { ExitToAppIcon, HelpIcon, InfoIcon } from 'icons';
import { ROUTES } from 'routes/routes';

import { AboutDialog } from './AboutDialog';
import { ProfileMenuItem } from './ProfileMenuItem';

const ANCHOR_ORIGIN = {
    vertical: 'bottom',
    horizontal: 'right',
} as const;

const TRANSFORM_ORIGIN = {
    vertical: 'top',
    horizontal: 'right',
} as const;

export function AppBarMenu({ anchor, onClose, open }) {
    const [aboutDialogOpen, setAboutDialogOpen] = useState(false);
    const { data: user } = useUser();
    const history = useHistory();

    const onAboutClick = useCallback(() => {
        setAboutDialogOpen(true);
        onClose();
    }, [onClose]);

    const queryClient = useQueryClient();
    const { mutate: triggerLogout } = useMutation({
        mutationFn: logout,
        onSuccess: () => {
            clearCache(queryClient);
            history.push(ROUTES.LOGIN);
        },
        onSettled: () => {
            onClose();
        },
    });

    const closeAboutDialog = useCallback(() => {
        setAboutDialogOpen(false);
    }, []);

    return (
        <>
            <ClickAwayListener onClickAway={noop}>
                <Popover
                    open={open}
                    onClose={onClose}
                    anchorEl={anchor}
                    anchorOrigin={ANCHOR_ORIGIN}
                    transformOrigin={TRANSFORM_ORIGIN}
                >
                    <MenuList>
                        <MenuItem onClick={onAboutClick}>
                            <ListItemIcon>
                                <InfoIcon />
                            </ListItemIcon>
                            <ListItemText>About...</ListItemText>
                        </MenuItem>

                        {user && (
                            <MenuItem component={Link} to="/help/help/" target="_blank" onClick={onClose}>
                                <ListItemIcon>
                                    <HelpIcon />
                                </ListItemIcon>
                                <ListItemText>Help</ListItemText>
                            </MenuItem>
                        )}

                        {user && <Divider />}
                        {user && <ProfileMenuItem onClose={onClose} />}
                        {user && (
                            <MenuItem onClick={() => triggerLogout()}>
                                <ListItemIcon>
                                    <ExitToAppIcon />
                                </ListItemIcon>
                                <ListItemText>Logout</ListItemText>
                            </MenuItem>
                        )}
                    </MenuList>
                </Popover>
            </ClickAwayListener>
            <AboutDialog open={aboutDialogOpen} onClose={closeAboutDialog} />
        </>
    );
}
