import { useCallback } from 'react';

import { createFilterOptions } from '@mui/material';
import _ from 'lodash';

import { AutocompleteElement } from 'commonComponents/AutocompleteElement';

const filter = createFilterOptions();

export function ApplicationNames({
    availableRuntimeFeatures,
    currentProduct,
    form,
    name = 'runtimeFeatures',
    disabled = false,
}) {
    const { setValue, register } = form;

    const handleChange = useCallback(
        (_, newValue) => {
            newValue = newValue.map(f => {
                if (typeof f === 'string') {
                    // user committed a new value by pressing enter
                    return { name: f, product: currentProduct.id, featureType: 'Runtime' };
                }

                if (f.inputValue) {
                    // user selected 'Add "value"'
                    return { name: f.inputValue, product: currentProduct.id, featureType: 'Runtime' };
                }

                // existing option
                return f;
            });
            setValue(name, newValue);
        },
        [currentProduct.id, name, setValue]
    );

    const isOptionEqualToValue = useCallback((option, value) => {
        return _.isEqual(option, value);
    }, []);

    const getRuntimeLabel = useCallback(runtime => {
        if (typeof runtime === 'string') {
            return runtime;
        }

        if (runtime.display) {
            return runtime.display;
        }

        return runtime.name;
    }, []);

    const filterOptions = (options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;

        const isExisting = options.some(option => inputValue === option.name);
        if (inputValue !== '' && !isExisting) {
            filtered.push({ inputValue, display: `Add "${inputValue}"` });
        }

        return filtered;
    };

    if (!currentProduct.is_application) {
        return <input type="hidden" {...register(name)} />;
    }

    return (
        <AutocompleteElement
            label="Application Names"
            name={name}
            options={availableRuntimeFeatures}
            isOptionEqualToValue={isOptionEqualToValue}
            freeSolo
            forcePopupIcon
            multiple
            disableCloseOnSelect
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            placeholder="Type or select application names"
            getOptionLabel={getRuntimeLabel}
            onChange={handleChange}
            disabled={disabled}
            filterOptions={filterOptions}
        ></AutocompleteElement>
    );
}
