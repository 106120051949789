import { pluralize } from 'common/utils';

export const units = [
    { label: 'day', value: 'd' },
    { label: 'month', value: 'm' },
    { label: 'year', value: 'y' },
];

export function lookup(value) {
    const match = units.find(u => u.value === value);
    return match ? match.label : '';
}

export function durationOrUnlimited(duration, unit) {
    return duration ? pluralize(duration, lookup(unit)) : 'unlimited';
}

export function approxDaysForDuration(duration, unit) {
    return !duration ? Number.MAX_SAFE_INTEGER : duration * factorForUnit(unit);
}

function factorForUnit(unit) {
    switch (unit) {
        case 'm':
            return 30;
        case 'y':
            return 365;
        default:
            return 1;
    }
}
