import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { triggerDownload } from 'common/fileUtils';

import { ActionButton } from 'commonComponents/ActionButton';

import { downloadLicenseFile, LicenseDetails, LicenseFile } from 'api/licenses';
import { SaveIcon } from 'icons';
import { ROLE_DEFINITIONS } from 'roles/definitions';
import { RoleDependentComponent } from 'roles/RoleDependentComponent';

export function LicenseFileDownloadButton({
    licenseFile,
    license,
}: {
    licenseFile: LicenseFile;
    license: LicenseDetails;
}) {
    const { mutate: trigger, isLoading: isMutating } = useMutation({
        mutationFn: () => downloadLicenseFile(licenseFile.id).then(triggerDownload),
        meta: { errorMessage: 'Downloading license failed' },
    });

    const { enqueueSnackbar } = useSnackbar();
    const download = () => {
        if (!license.features || license.features.length === 0) {
            enqueueSnackbar('Cannot download file: License has no features', { variant: 'error' });
            return;
        }
        trigger();
    };

    if (
        license.invalidated ||
        licenseFile.deactivated ||
        !licenseFile.hardwareBinding?.licenseGenerator?.allowFileDownload
    )
        return null;

    return (
        <RoleDependentComponent
            userRole={ROLE_DEFINITIONS.Customer}
            component={ActionButton}
            iconClass={SaveIcon}
            variant="contained"
            color="primary"
            onClick={download}
            title="Download"
            data-testid="LicenseDetails_Download"
            disabled={license.isExpired || isMutating}
        />
    );
}
