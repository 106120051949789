import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import {
    Box,
    Card,
    CardActions,
    CardContent,
    Table,
    TableBody,
    TextareaAutosize,
    TextField,
    Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { makeStyles } from 'makeStyles';
import { useSnackbar } from 'notistack';
import ShortId from 'shortid';

import { useHandleExpiredSession } from 'common/hooks';

import { ActionButton } from 'commonComponents/ActionButton';
import { LinkButton } from 'commonComponents/LinkButton';
import { LoadingIndicator } from 'commonComponents/LoadingIndicator';
import { PageContainer } from 'commonComponents/PageContainer';
import { TableRow } from 'commonComponents/TableRow';

import { loadCustomerDetails } from 'api/customers';
import { loadEmailTemplate } from 'api/emailTemplates';
import { welcomeUser } from 'api/users';
import { EditIcon, UndoIcon } from 'icons';
import { ROUTES } from 'routes/routes';

const useStyles = makeStyles()(theme => ({
    inputField: {
        marginBottom: theme.spacing(2),
        '& input:disabled': {
            color: 'rgb(84, 84, 84)',
            backgroundColor: 'rgba(239, 239, 239, 0.3)',
        },
    },
}));

const ROW_DATA_MAPPER = [
    {
        title: 'Full Name',
        accessor: 'full_name',
    },
    {
        title: 'Email address',
        accessor: 'email',
    },
    {
        title: 'User Type',
        accessor: 'userType',
    },
];

export function EmailUser() {
    const { classes } = useStyles();
    const location = useLocation();
    const returnPath = new URLSearchParams(location.search).get('returnPath') || ROUTES.USER_LIST;
    const { enqueueSnackbar } = useSnackbar();
    const [requestInProgress, setRequestInProgress] = useState(false);
    const [emailSendSuccess, setEmailSendSuccess] = useState(false);
    const handleExpiredSession = useHandleExpiredSession();
    const { id: userId } = useParams<{ id: string }>();
    const defaultProps = {
        disabled: true,
        className: classes.inputField,
        variant: 'outlined',
    } as const;
    const { data: user, error: userError } = useQuery({
        queryKey: ['Customer', 'detail', Number(userId)],
        queryFn: () => loadCustomerDetails(Number(userId)),
        meta: { errorMessage: 'Loading user failed' },
    });
    const { data: emailTemplate, error: emailTemplateError } = useQuery({
        enabled: Boolean(user),
        queryKey: ['EmailTemplate', { ...user, type: 'Welcome' }],
        queryFn: () => loadEmailTemplate({ ...user, type: 'Welcome' }),
        meta: { errorMessage: 'Loading email template failed.' },
    });
    const error = userError || emailTemplateError;

    if (error) {
        return null;
    }

    if (!user || !emailTemplate) {
        return <LoadingIndicator />;
    }

    const rowData = ROW_DATA_MAPPER.map(rowData => {
        const value = user[rowData.accessor];
        return <TableRow key={ShortId.generate()} title={rowData.title} value={value} />;
    });

    const onSubmit = async () => {
        setEmailSendSuccess(false);
        setRequestInProgress(true);

        try {
            await welcomeUser(userId, {
                email: user.email,
                cc: emailTemplate.additionalRecipients,
                subject: emailTemplate.subject,
                message: emailTemplate.plainText,
                sender: emailTemplate.sender,
            });
            enqueueSnackbar('Welcome email sent successfully', { variant: 'success' });
            setEmailSendSuccess(true);
        } catch (error) {
            enqueueSnackbar('User update failed.', { variant: 'error' });
            handleExpiredSession(error);
        } finally {
            setRequestInProgress(false);
        }
    };

    return (
        <PageContainer maxWidth="md">
            <Card>
                <CardContent>
                    <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                        User Account Created
                    </Typography>
                    <Typography variant="h5" component="h2">
                        User Name: {user.username}
                    </Typography>
                    <Table size="small">
                        <TableBody>{rowData}</TableBody>
                    </Table>
                </CardContent>
                <CardContent>
                    <Typography variant="h5" component="h2">
                        Send Message to New User
                    </Typography>
                    <Box mt={1} display="flex" flexDirection="column">
                        <TextField
                            style={{ color: 'black !important' }}
                            label="Email"
                            name="email"
                            value={user.email}
                            {...defaultProps}
                        />
                        <TextField label="CC" name="cc" value={emailTemplate.additionalRecipients} {...defaultProps} />
                        <TextField label="Subject" name="subject" value={emailTemplate.subject} {...defaultProps} />
                        <TextareaAutosize
                            style={{ resize: 'vertical', padding: '14px' }}
                            name="message"
                            value={emailTemplate.plainText}
                            minRows={12}
                            maxRows={12}
                            {...defaultProps}
                        />
                    </Box>
                </CardContent>
                <CardActions>
                    {!emailSendSuccess && (
                        <ActionButton
                            color="secondary"
                            iconClass={EditIcon}
                            inProgress={requestInProgress}
                            title="Send"
                            type="submit"
                            variant="contained"
                            onClick={onSubmit}
                        />
                    )}
                    {!emailSendSuccess && <LinkButton iconClass={UndoIcon} route={returnPath} title="Cancel" />}
                    {emailSendSuccess && <LinkButton iconClass={UndoIcon} route={returnPath} title="Close" />}
                </CardActions>
            </Card>
        </PageContainer>
    );
}
