import { useState } from 'react';

import { ActionButton } from 'commonComponents/ActionButton';

import { DeleteIcon } from 'icons';
import { ROLE_DEFINITIONS } from 'roles/definitions';
import { RoleDependentComponent } from 'roles/RoleDependentComponent';

import { ConfigurationInvalidateDialog } from './ConfigurationInvalidateDialog';

export function ConfigurationInvalidateButton({ configuration }) {
    const [invalidateDialogOpen, setInvalidateDialogOpen] = useState(false);

    if (configuration.invalidated) {
        return null;
    }
    return (
        <>
            <RoleDependentComponent
                userRole={ROLE_DEFINITIONS.LicenseManager}
                component={ActionButton}
                onClick={() => setInvalidateDialogOpen(true)}
                color="secondary"
                iconClass={DeleteIcon}
                title="Invalidate"
                variant="contained"
            />
            <ConfigurationInvalidateDialog
                configuration={configuration}
                open={invalidateDialogOpen}
                onClose={() => {
                    setInvalidateDialogOpen(false);
                }}
            />
        </>
    );
}
