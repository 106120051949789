import { useQuery } from '@tanstack/react-query';

import { get, post } from './axiosWrapper';
import { API_ENDPOINTS } from './endpoints';
import { type Product } from './products';

export type LoggedInUser = {
    default_product: Product | null;
    default_screen: 'D' | 'L';
    email: string;
    full_name: string;
    groups: string[];
    id: number;
    invalidated: boolean;
    is_active: boolean;
    is_superuser: boolean;
    products: Product[];
    user_permissions: string[];
    username: string;
    owner_id: string;
};

export function useUser() {
    return useQuery({
        queryKey: ['LoggedInUser'],
        queryFn: loadUser,
        select: res => res.user,
        meta: { errorMessage: 'Loading user failed' },
    });
}

export function loadUser() {
    return get<{ user: LoggedInUser | null }>(API_ENDPOINTS.AUTHORIZATION_LOAD_USER).then(res => res.data);
}

export function login(payload: { username: string; password: string }) {
    return post<{ user: LoggedInUser }>(API_ENDPOINTS.AUTHORIZATION_LOGIN, payload).then(res => res.data);
}

export async function logout() {
    return get(API_ENDPOINTS.AUTHORIZATION_LOGOUT).then(res => res.data);
}
