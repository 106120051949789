import { generatePath } from 'react-router';

import {
    Box,
    Divider,
    List,
    ListItem,
    ListItemButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { format, parseJSON } from 'date-fns';
import { toString } from 'lodash';
import { makeStyles } from 'makeStyles';

import { DATE_TIME_FORMAT } from 'common/constants';

import type { HistoryEntry as HistoryEntryType } from 'api/history';

import { LinkButton } from './LinkButton';

const useStyles = makeStyles()(() => ({
    listItem: {
        cursor: 'default',
        flexDirection: 'column',
    },
    table: {
        width: '100%',
    },
    tableCell: {
        borderWidth: 0,
        width: '29%',
    },
    tableLast: {
        borderWidth: 0,
        minWidth: '118px',
    },
}));

function getChangeDescription(entry: HistoryEntryType, modelName: string) {
    if (entry.history_type === '+') {
        return `created the ${modelName}`;
    }
    if (entry.history_type === '~') {
        return entry.history_change_reason || `updated the ${modelName}`;
    }
    return 'Unknown change';
}

function HistoryEntry({
    entry,
    modelName,
    revertUrl,
}: {
    entry: HistoryEntryType;
    modelName: string;
    revertUrl?: string;
}) {
    const { classes } = useStyles();
    const formattedDate = format(parseJSON(entry.history_date), DATE_TIME_FORMAT);
    const subheader = `${entry.history_user} ${getChangeDescription(entry, modelName)} - ${formattedDate}`;
    return (
        <>
            <Divider />
            <ListItemButton alignItems="flex-start" className={classes.listItem} disableRipple>
                <Box pl={2} display="flex" justifyContent="space-between" alignSelf="stretch" alignItems="center">
                    <Typography>{subheader}</Typography>
                    {revertUrl && (
                        <Box minWidth="104px">
                            <LinkButton
                                variant="outlined"
                                color="primary"
                                route={revertUrl}
                                title="Restore"
                                size="small"
                            />
                        </Box>
                    )}
                </Box>
                {entry.changes && (
                    <Table className={classes.table} size="small">
                        <TableBody>
                            {Object.entries(entry.changes).map(change => {
                                const [key, value] = change;
                                const [oldValue, newValue] = value;
                                return (
                                    <TableRow key={key}>
                                        <TableCell className={classes.tableCell}>{key}</TableCell>
                                        <TableCell className={classes.tableCell}>{toString(oldValue)}</TableCell>
                                        <TableCell className={classes.tableCell}>{toString(newValue)}</TableCell>
                                        <TableCell className={classes.tableLast} />
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                )}
            </ListItemButton>
        </>
    );
}

export function HistoryList({
    entries,
    modelName,
    revertUrl,
}: {
    entries: HistoryEntryType[];
    modelName: string;
    revertUrl?: string;
}) {
    const { classes } = useStyles();
    return (
        <List dense>
            <ListItem alignItems="flex-start" className={classes.listItem}>
                <Table className={classes.table} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableCell}>Attribute</TableCell>
                            <TableCell className={classes.tableCell}>Old value</TableCell>
                            <TableCell className={classes.tableCell}>New Value</TableCell>
                            {revertUrl && <TableCell className={classes.tableLast}>Restore</TableCell>}
                        </TableRow>
                    </TableHead>
                </Table>
            </ListItem>
            {entries.map((e, index) => (
                <HistoryEntry
                    key={e.history_id}
                    entry={e}
                    modelName={modelName}
                    revertUrl={revertUrl && index !== 0 && `${generatePath(revertUrl, { historyId: e.history_id })}`}
                />
            ))}
        </List>
    );
}
