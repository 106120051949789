import { number, shape } from 'prop-types';

import { LicenseDeleteButton } from './LicenseDeleteButton';
import { LicenseInvalidateButton } from './LicenseInvalidateButton';

export function DeleteOrInvalidateLicense({ licenseDetails }) {
    if (licenseDetails.licenseFilesCount) {
        return <LicenseInvalidateButton licenseDetails={licenseDetails} />;
    }

    return <LicenseDeleteButton licenseDetails={licenseDetails} />;
}

DeleteOrInvalidateLicense.propTypes = {
    licenseDetails: shape({
        licenseFilesCount: number.isRequired,
    }).isRequired,
};
