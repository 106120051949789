import { Box } from '@mui/material';
import { makeStyles } from 'makeStyles';

import { getNumberOfMacAddresses, hasMacAddress } from 'licenses/utils';

import { MacAddress } from './MacAddress';

const useStyles = makeStyles()(theme => ({
    macAddressesContainer: {
        borderStyle: 'solid',
        padding: theme.spacing(2),
        borderWidth: '1px',
        borderRadius: '4px',
        borderColor: 'rgba(0,0,0,0.2)',
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(1),
        '& > *': {
            flex: '25ch',
        },
    },
}));

export function MacAddresses({ productConfiguration, disabledFields, ...otherProps }) {
    const { classes } = useStyles();

    if (!hasMacAddress(productConfiguration)) {
        return null;
    }

    return (
        <Box className={classes.macAddressesContainer}>
            {Array(getNumberOfMacAddresses(productConfiguration))
                .fill()
                .map((_, index) => (
                    <MacAddress
                        key={`mac_address_${index}`}
                        required={index === 0}
                        disabled={disabledFields.includes('mac_address')}
                        {...{ index, ...otherProps }}
                    />
                ))}
        </Box>
    );
}
