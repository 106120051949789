import { useMemo } from 'react';
import { TextFieldElement } from 'react-hook-form-mui';

import { buildSmartDrLegacySiteIdMaskInput } from 'commonComponents/SmartDrLegacySiteIdMaskInput';

import { hasSmartDrLegacySiteId } from 'licenses/utils';
const SITEID_REGEX =
    /^((([0123456789ABCDEFGHKMNPQRSTUVWXYZ]{4}-){2}[0123456789ABCDEFGHKMNPQRSTUVWXYZ]{3})|(([0123456789ABCDEFGHKMNPQRSTUVWXYZ]{4}-){5}[0123456789ABCDEFGHKMNPQRSTUVWXYZ]{2})|(([0123456789ABCDEFGHKMNPQRSTUVWXYZ]{4}-){8}[0123456789ABCDEFGHKMNPQRSTUVWXYZ]{1}))$/i;

export function SmartDrLegacySiteId({ productConfiguration, defaultProps }) {
    const InputMask = useMemo(() => {
        return buildSmartDrLegacySiteIdMaskInput([
            {
                mask: '0000-0000-0000-0000-0000-0000-0000-0000-0',
                definitions: { 0: /[0123456789ABCDEFGHKMNPQRSTUVWXYZ]/ },
                placeholderChar: '\u2000',
            },
        ]);
    }, []);

    if (!hasSmartDrLegacySiteId(productConfiguration)) return null;

    return (
        <TextFieldElement
            label="Site ID"
            name="site_id"
            required
            {...defaultProps}
            data-testid="LicenseFileForm_SiteId"
            InputProps={{ inputComponent: InputMask }}
            InputLabelProps={{ shrink: true }}
            validation={{ pattern: SITEID_REGEX }}
            parseError={error => {
                if (error.message) {
                    return error.message;
                }
                if (error.type === 'pattern') {
                    return 'Invalid Site ID';
                }
                return 'Unknown error';
            }}
        />
    );
}
