import ReactDOM from 'react-dom';

import { App } from './App';
import { AppEnvironment } from './AppEnvironment';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <AppEnvironment>
        <App />
    </AppEnvironment>,
    document.getElementById('root')
);

serviceWorker.unregister();
