import { Link as RouterLink } from 'react-router-dom';

import { Card, CardContent, CardHeader, Link, Typography, useTheme } from '@mui/material';

import { PersonIcon } from 'icons';
import { ROUTES } from 'routes/routes';

export function SelectProduct() {
    const theme = useTheme();

    return (
        <Card>
            <CardHeader title="Please select a product" />
            <CardContent sx={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
                <Typography>Note that you can set your default product in your</Typography>
                <Link
                    to={ROUTES.PROFILE}
                    component={RouterLink}
                    sx={{ display: 'flex', alignItems: 'center', gap: theme.spacing(0.5) }}
                >
                    <PersonIcon />
                    Profile
                </Link>
            </CardContent>
        </Card>
    );
}
