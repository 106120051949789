import { useForm } from 'react-hook-form';
import { FormContainer } from 'react-hook-form-mui';
import { generatePath, Redirect } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';

import { Card, CardActions, CardHeader } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';

import { ActionButton } from 'commonComponents/ActionButton';
import { CommonFormComponents } from 'commonComponents/CommonFormComponents';
import { LinkButton } from 'commonComponents/LinkButton';
import { LoadingIndicator } from 'commonComponents/LoadingIndicator';
import { PageContainer } from 'commonComponents/PageContainer';

import { useUser } from 'api/authorization';
import { useHandleFormError } from 'api/hooks/errorHandling';
import type { UserDetails } from 'api/users';
import { loadUserDetails, mutateUser, updateUser } from 'api/users';
import { EditIcon, UndoIcon } from 'icons';
import { ROUTES } from 'routes/routes';

import { UserFormContent, UserFormContentProps, UserFormFields } from './UserFormContent';
import { otherAdminUserIsLoggedInUser } from './utils';

function EditUserForm({ user }: { user: UserDetails }) {
    const history = useHistory();
    const formContext = useForm<UserFormFields>({
        defaultValues: _.pick(user, [
            'address',
            'city',
            'comment',
            'company',
            'country',
            'email',
            'full_name',
            'groups',
            'is_internal',
            'is_active',
            'owner_id',
            'phone_number',
            'products',
            'username',
        ]),
    });

    const handleFormError = useHandleFormError(formContext);
    const queryClient = useQueryClient();
    const { isLoading: requestInProgress, mutate: trigger } = useMutation({
        mutationFn: (values: UserFormFields) => {
            return updateUser(user.id, {
                ...values,
                products: values.products.map(p => p.id),
                default_product: values.products.find(p => p.id === user.default_product?.id)
                    ? user.default_product.id
                    : null,
            });
        },
        onSuccess: (response, values) => {
            mutateUser(queryClient, response);
            formContext.reset(values);
            const userDetailRoute = generatePath(ROUTES.USER_DETAIL, { id: response.id });
            if (!user.is_active && values.is_active) {
                history.push(
                    `${generatePath(ROUTES.USER_EMAIL, { id: response.id })}?${new URLSearchParams({
                        returnPath: userDetailRoute,
                    })}`
                );
            } else {
                history.push(userDetailRoute);
            }
        },
        onError: handleFormError,
        meta: { errorMessage: 'User update failed' },
    });

    const defaultProps: UserFormContentProps['defaultProps'] = {
        autoComplete: 'off',
        variant: 'outlined',
        margin: 'none',
    };

    return (
        <PageContainer maxWidth="md">
            <FormContainer formContext={formContext} onSuccess={values => trigger(values)}>
                <CommonFormComponents />
                <Card>
                    <CardHeader title="Edit User" />
                    <UserFormContent defaultProps={defaultProps} form={formContext} />
                    <CardActions>
                        <ActionButton
                            color="secondary"
                            iconClass={EditIcon}
                            inProgress={requestInProgress}
                            title="Submit"
                            type="submit"
                            variant="contained"
                        />
                        <LinkButton
                            iconClass={UndoIcon}
                            route={generatePath(ROUTES.USER_DETAIL, { id: user.id })}
                            title="Cancel"
                        />
                    </CardActions>
                </Card>
            </FormContainer>
        </PageContainer>
    );
}

export function EditUser() {
    const { id: userId } = useParams<{ id: string }>();
    const { data: loggedInUser } = useUser();

    const { data: user, error } = useQuery({
        queryKey: ['User', 'detail', userId],
        queryFn: () => loadUserDetails(Number(userId)),
        meta: { errorMessage: 'Loading user failed' },
    });

    if (error) {
        return null;
    }

    if (!user) {
        return <LoadingIndicator />;
    }

    if (!otherAdminUserIsLoggedInUser(user, loggedInUser)) {
        return (
            <Redirect
                to={{
                    pathname: ROUTES.NOT_ALLOWED,
                }}
                exact
            />
        );
    }

    return <EditUserForm user={user} />;
}
