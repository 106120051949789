import { useState } from 'react';

import { ActionButton } from 'commonComponents/ActionButton';

import { RenewIcon } from 'icons';
import { ROLE_DEFINITIONS } from 'roles/definitions';
import { RoleDependentComponent } from 'roles/RoleDependentComponent';

import { LicenseRenewDialog } from './LicenseRenewDialog';

export function LicenseRenewButton({ licenseDetails }) {
    const { invalidated, licenseFilesCount, expiration_date, support_expiration_date } = licenseDetails;
    const [renewDialogOpen, setRenewDialogOpen] = useState(false);
    if (licenseFilesCount === 0) return null;
    if (invalidated) return null;
    if (Boolean(expiration_date) === false && Boolean(support_expiration_date) === false) return null;
    return (
        <>
            <RoleDependentComponent
                userRole={ROLE_DEFINITIONS.PrivilegedCustomer}
                component={ActionButton}
                onClick={() => setRenewDialogOpen(true)}
                color="secondary"
                iconClass={RenewIcon}
                title=" Renew"
                variant="contained"
                data-testid="LicenseDetails_Renew"
            />

            <LicenseRenewDialog
                license={licenseDetails}
                open={renewDialogOpen}
                onClose={() => setRenewDialogOpen(false)}
            />
        </>
    );
}
