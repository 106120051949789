import { useDropzone } from 'react-dropzone';

import { Box, Typography, useTheme } from '@mui/material';
import { makeStyles } from 'makeStyles';

import { CloudUploadIcon } from 'icons';

const useStyles = makeStyles()(theme => ({
    root: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: theme.palette.background.paper,
        zIndex: 99,
        border: 'dashed',
        borderRadius: theme.shape.borderRadius,
    },
    overlay: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignItems: 'center',
    },
}));

export function DropZone({
    children,
    onDrop,
    enable = true,
    label = 'Drop file here...',
    color = 'primary',
    ...remainingArgs
}) {
    const theme = useTheme();
    const { classes } = useStyles();
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        ...remainingArgs,
    });

    return enable ? (
        <Box position="relative" {...getRootProps()}>
            {isDragActive && (
                <Box className={classes.root} style={{ borderColor: theme.palette[color].light }}>
                    <Box className={classes.overlay}>
                        <CloudUploadIcon fontSize="large" color={color} />
                        <Typography variant="h6" color={color}>
                            {label}
                        </Typography>
                    </Box>
                </Box>
            )}

            <input {...getInputProps()} />
            {children}
        </Box>
    ) : (
        <>{children}</>
    );
}
