import { useForm } from 'react-hook-form';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { useHistory } from 'react-router-dom';

import { Box, Card, CardActions, CardContent, CardHeader, useTheme } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { ActionButton } from 'commonComponents/ActionButton';
import { NonFieldErrors } from 'commonComponents/CommonFormComponents';
import { PageContainer } from 'commonComponents/PageContainer';

import { useHandleFormError } from 'api/hooks/errorHandling';
import { confirmPasswordReset } from 'api/passwordReset';
import { SaveIcon } from 'icons';
import { ROUTES } from 'routes/routes';

export function ResetPasswordConfirmPage() {
    const theme = useTheme();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const queryParams = new URLSearchParams(window.location.search);
    const defaultValues = { password: '', passwordConfirm: '' };
    const formContext = useForm({ defaultValues });
    const { watch } = formContext;
    const handleFormError = useHandleFormError(formContext);

    const { mutate, isLoading } = useMutation({
        mutationFn: (values: typeof defaultValues) =>
            confirmPasswordReset({ password: values.password, token: queryParams.get('token') }),
        onSuccess: () => {
            enqueueSnackbar('Your password was sucessfully updated. Please log in with your new credentials.', {
                variant: 'success',
            });
            history.push(ROUTES.LOGIN);
        },
        onError: handleFormError,
        meta: { errorMessage: 'Password could not be set' },
    });

    return (
        <PageContainer maxWidth="sm">
            <FormContainer formContext={formContext} onSuccess={values => mutate(values)}>
                <Card>
                    <CardHeader title="Set new Password" />
                    <CardContent>
                        <Box display="flex" flexDirection="column" gap={theme.spacing(2)}>
                            <TextFieldElement
                                fullWidth
                                label="Password"
                                name="password"
                                required
                                type="password"
                                variant="outlined"
                            />
                            <TextFieldElement
                                fullWidth
                                label="Confirm Password"
                                name="passwordConfirm"
                                required
                                type="password"
                                variant="outlined"
                                validation={{
                                    validate: value => {
                                        if (watch('password') !== value) {
                                            return 'Passwords are not identical.';
                                        }
                                    },
                                }}
                            />
                        </Box>
                        <NonFieldErrors />
                    </CardContent>
                    <CardActions>
                        <ActionButton
                            color="secondary"
                            iconClass={SaveIcon}
                            inProgress={isLoading}
                            title="Set Password"
                            type="submit"
                            variant="contained"
                        />
                    </CardActions>
                </Card>
            </FormContainer>
        </PageContainer>
    );
}
