import { add, format, set, startOfWeek } from 'date-fns';

export function build(products, scale, numberOfDatapoints, colorsPerProduct, result) {
    const { productDatasetMap, datasets } = createDatasets(products, colorsPerProduct);
    const statistics = transformResult(result);
    const labels = [];

    for (const [key, label] of generateDataPoints(scale, numberOfDatapoints)) {
        labels.push(label);

        if (key in statistics) {
            for (const [product, datasetIndex] of Object.entries(productDatasetMap)) {
                const count = product in statistics[key] ? statistics[key][product] : 0;
                datasets[datasetIndex].data.push(count);
            }
        } else {
            // no statistical data available for date, add 0 for all products
            for (const dataset of datasets) {
                dataset.data.push(0);
            }
        }
    }

    return { labels, datasets };
}

export function chartTitle(scale, includeInternalLicenses) {
    return `Licenses Created ${includeInternalLicenses ? 'with Internal ' : ''}(${titleForScale(scale)})`;
}

export function startDateForScale(scale, numberOfDatapoints) {
    switch (scale) {
        case 'day':
            return add(refDate(scale), { days: -numberOfDatapoints });
        case 'week':
            return add(refDate(scale), { weeks: -numberOfDatapoints });
        case 'month':
            return add(refDate(scale), { months: -numberOfDatapoints });
        default:
            throw new Error('Invalid scale');
    }
}

export function colorsForProducts(products) {
    const provider = new ColorProvider();
    return Object.fromEntries(products.map(p => [p.id, provider.next()]));
}

function createDatasets(products, colorsPerProduct) {
    const productDatasetMap = {};
    const datasets = [];

    const productIdsAndNames = products.map(p => ({
        id: p.id,
        name: p.name,
    }));
    productIdsAndNames.sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
    for (const [index, { id, name }] of productIdsAndNames.entries()) {
        datasets[index] = {
            label: name,
            backgroundColor: colorsPerProduct[id],
            data: [],
        };
        productDatasetMap[id] = index;
    }

    return { productDatasetMap, datasets };
}

function transformResult(result) {
    // transform result into a structure indexed first by date, then by product
    const statistics = {};

    for (const { key, product, count } of result) {
        if (!(key in statistics)) {
            statistics[key] = {};
        }
        statistics[key][product] = count;
    }

    return statistics;
}

function refDate(scale) {
    switch (scale) {
        case 'day':
            return set(new Date(), { hours: 12, minutes: 0, seconds: 0, milliseconds: 0 });
        case 'week':
            // weekStartsOn must match the backend
            return set(startOfWeek(new Date(), { weekStartsOn: 1 }), {
                hours: 12,
                minutes: 0,
                seconds: 0,
                milliseconds: 0,
            });
        case 'month':
            return set(new Date(), { date: 1, hours: 12, minutes: 0, seconds: 0, milliseconds: 0 });
        default:
            throw new Error('Invalid scale');
    }
}

function* generateDataPoints(scale, numberOfDatapoints) {
    for (let datapointIndex = -(numberOfDatapoints - 1); datapointIndex <= 0; datapointIndex++) {
        yield keyAndLabelForDataPoint(datapointIndex, scale);
    }
}

function keyAndLabelForDataPoint(datapointIndex, scale) {
    switch (scale) {
        case 'day': {
            const currDate = add(refDate(scale), { days: datapointIndex });
            const dateStr = format(currDate, 'yyyy-MM-dd');
            return [dateStr, dateStr];
        }
        case 'week': {
            const currDate = add(refDate(scale), { weeks: datapointIndex });
            const key = format(currDate, 'yyyy-MM-dd');
            const label = format(currDate, 'II/yyyy');
            return [key, label];
        }
        case 'month': {
            const currDate = add(refDate(scale), { months: datapointIndex });
            const dateStr = format(currDate, 'yyyy-MM');
            return [dateStr, dateStr];
        }
        default:
            throw new Error('Invalid scale');
    }
}

function titleForScale(scale) {
    switch (scale) {
        case 'day':
            return 'Daily';
        case 'week':
            return 'Weekly';
        case 'month':
            return 'Monthly';
        default:
            return '';
    }
}

class ColorProvider {
    static colors = [
        'rgb(51, 34, 136)',
        'rgb(102, 153, 204)',
        'rgb(136, 204, 238)',
        'rgb(58, 170, 153)',
        'rgb(17, 119, 51)',
        'rgb(153, 153, 51)',
        'rgb(221, 204, 119)',
        'rgb(102, 17, 0)',
        'rgb(204, 102, 119)',
        'rgb(170, 68, 102)',
        'rgb(136, 34, 85)',
        'rgb(170, 68, 153)',
    ];

    nextIndex = 0;

    next() {
        const nextColor = ColorProvider.colors[this.nextIndex];
        this.nextIndex++;
        if (this.nextIndex >= ColorProvider.colors.length) {
            this.nextIndex = 0;
        }

        return nextColor;
    }
}
