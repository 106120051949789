import { useEffect, useMemo, useState } from 'react';

import { TextField } from '@mui/material';
import { debounce } from 'lodash';

import { useTableInteractionStyles } from './styles';

/**
 * @param {Object} props
 * @param {import('react-table').TableInstance} props.table
 */
export function ObjectTableSearch({ table }) {
    const { classes } = useTableInteractionStyles();
    const updateSearch = useMemo(() => debounce(search => table.setGlobalFilter(search.trim()), 300), [table]);
    const [searchText, setSearchText] = useState(table.initialState.globalFilter || '');
    useEffect(() => {
        setSearchText(table.initialState.globalFilter);
    }, [table.initialState.globalFilter]);

    const onChange = e => {
        const search = e.target.value;
        setSearchText(search);
        updateSearch(search);
    };

    return (
        <TextField
            type="search"
            className={classes.searchField}
            label="Search"
            onChange={onChange}
            margin="normal"
            variant="outlined"
            value={searchText}
        />
    );
}
