import { SelectElement } from 'react-hook-form-mui';

import { sortBy } from 'lodash';

import { useCurrentProduct } from 'api/products';

export function HardwareBinding({ defaultProps, form, name = 'hardwareBinding', disabled = false }) {
    const { data: currentProduct } = useCurrentProduct();
    const { watch } = form;
    const product = watch('product') || currentProduct;

    const options = sortBy(product.hardwareBindings, 'orderNumber');
    return (
        <SelectElement
            label="Hardware Binding"
            name={name}
            options={options}
            valueKey="id"
            labelKey="type"
            disabled={disabled || options.length === 1}
            {...defaultProps}
        />
    );
}
