import { useEffect, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';

import { Box, Card, CardContent, Checkbox, FormControlLabel, useTheme } from '@mui/material';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { format } from 'date-fns';

import { usePersistentState } from 'common/hooks';

import { PageContainer } from 'commonComponents/PageContainer';

import { useUser } from 'api/authorization';
import { loadLicenseStatistics } from 'api/licenses';

import { ChartContainer } from './ChartContainer';
import { build, chartTitle, colorsForProducts, startDateForScale } from './dashboardHelpers';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export function Dashboard() {
    const [scale, setScale] = usePersistentState('dashboard_scale', 'month');
    const [includeInternal, setIncludeInternal] = usePersistentState('dashboard_internal', false);
    const numberOfDatapoints = useMemo(() => 40, []);
    const startDate = useMemo(
        () => format(startDateForScale(scale, numberOfDatapoints), 'yyyy-MM-dd'),
        [scale, numberOfDatapoints]
    );

    const options = useMemo(
        () => ({
            plugins: {
                title: {
                    display: true,
                    text: chartTitle(scale, includeInternal),
                },
            },
            responsive: true,
            scales: {
                x: {
                    stacked: true,
                },
                y: {
                    stacked: true,
                },
            },
            maintainAspectRatio: false,
        }),
        [includeInternal, scale]
    );

    const { data: user } = useUser();

    const colorsPerProduct = useMemo(() => colorsForProducts(user.products), [user.products]);

    const [data, setData] = useState({
        datasets: [],
    });

    const { data: result } = useQuery({
        queryKey: ['LicenseStatistics', { scale, includeInternal, startDate }],
        queryFn: () => loadLicenseStatistics(scale, includeInternal, startDate),
        meta: { errorMessage: 'Loading license statistics failed.' },
    });

    useEffect(() => {
        if (!result || !user) {
            return;
        }

        const chartData = build(user.products, scale, numberOfDatapoints, colorsPerProduct, result);
        setData(chartData);
    }, [result, user, scale, numberOfDatapoints, colorsPerProduct]);

    const theme = useTheme();

    const handleScaleChanged = (_ev, newScale) => {
        if (newScale != null) {
            setScale(newScale);
        }
    };

    return (
        <PageContainer dataTestId="Dashboard_Container">
            <Box display="flex" flexDirection="column" flexGrow={1} gap={theme.spacing(1)}>
                <Card>
                    <CardContent>
                        <ToggleButtonGroup exclusive color="primary" value={scale} onChange={handleScaleChanged}>
                            <ToggleButton value="day">Daily</ToggleButton>
                            <ToggleButton value="week">Weekly</ToggleButton>
                            <ToggleButton value="month">Monthly</ToggleButton>
                        </ToggleButtonGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={includeInternal}
                                    onChange={event => setIncludeInternal(event.target.checked)}
                                    name="includeInternalLicenses"
                                />
                            }
                            label="Include Internal Licenses"
                            style={{ marginLeft: theme.spacing(2) }}
                        />
                    </CardContent>
                </Card>
                <ChartContainer>
                    <Bar options={options} data={data} />
                </ChartContainer>
            </Box>
        </PageContainer>
    );
}
