import { useRef } from 'react';
import { generatePath } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';

import { Card, CardHeader } from '@mui/material';

import { LoadingIndicator } from 'commonComponents/LoadingIndicator';
import { PageContainer } from 'commonComponents/PageContainer';

import { updateProductConfiguration, useProductConfiguration } from 'api/productConfigurations';
import { CreateIcon } from 'icons/CreateIcon';
import { ROUTES } from 'routes/routes';

import { ConfigurationForm } from './ConfigurationForm/ConfigurationForm';

export function EditConfiguration() {
    const history = useHistory();
    const configurationRef = useRef();
    const { id: definitionId } = useParams<{ id: string }>();

    const { data: details, isLoading } = useProductConfiguration(Number(definitionId));
    if (isLoading || !details) {
        return <LoadingIndicator />;
    }

    const initialValues = {
        ...details,
        validity: details.validity || null,
        supportValidity: details.supportValidity || null,
    };

    return (
        <PageContainer>
            <Card>
                <CardHeader title="Edit Configuration" />
                <ConfigurationForm
                    cancelButtonRoute={generatePath(ROUTES.CONFIGURATION_DETAIL, { id: definitionId })}
                    history={history}
                    initialValues={initialValues}
                    submitApiCall={values => updateProductConfiguration(definitionId, values)}
                    submitButtonIconClass={CreateIcon}
                    submitButtonText="Submit"
                    submitErrorMessage="Configuration update failed."
                    configurationRef={configurationRef}
                />
            </Card>
        </PageContainer>
    );
}
