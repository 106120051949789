import type { QueryClient } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';

import { useHandleExpiredSession } from 'common/hooks';
import { isSessionExpired } from 'common/isSessionExpired';

export function isAxiosError(error: unknown): error is AxiosError {
    return (error as AxiosError)?.isAxiosError;
}

export function useHandleError(queryClient?: QueryClient) {
    const handleExpiredSession = useHandleExpiredSession(queryClient);
    const { enqueueSnackbar } = useSnackbar();

    return function (error, errorMessage) {
        // eslint-disable-next-line no-console
        console.error(error);
        if (isSessionExpired(error)) {
            handleExpiredSession(error);
        } else if (errorMessage) {
            enqueueSnackbar(errorMessage, { variant: 'error' });
        }
    };
}

export function useHandleFormError(formContext) {
    const { setError } = formContext;

    return function (error) {
        if (error.isAxiosError && error.response?.status === 400 && typeof error.response?.data === 'object') {
            for (const [key, value] of Object.entries(error.response.data)) {
                setError(key === 'non_field_errors' ? 'root.serverError' : key, {
                    type: 'server',
                    message: Array.isArray(value) ? value[0] : value,
                });
            }
        } else {
            setError('root.serverError', {
                type: 'server',
                message: error.response?.statusText,
            });
        }
    };
}
