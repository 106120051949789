import { QueryClient, useQuery } from '@tanstack/react-query';
import { formatISO } from 'date-fns';

import { destroy, get, patch, post, put } from './axiosWrapper';
import { replaceObjectInReactQueryCache } from './cache';
import { API_ENDPOINTS } from './endpoints';
import { HistoryEntry } from './history';
import { ProductConfiguration } from './productConfigurations';
import type { Feature, HardwareBinding } from './products';

export type LicenseDetails = {
    activeLicenseFilesCount: number;
    comment: string;
    configuration: ProductConfiguration;
    creation_date: string;
    creator: string;
    creator_fullname: string;
    customerCompany: string;
    customerEmail: string;
    customerFullName: string;
    expiration_date: string | null;
    features: Feature[];
    id: number;
    internal: boolean;
    invalidated: boolean;
    isExpired: boolean;
    latestLicenseFileHardwareIdentifiers: string;
    licenseFilesCount: number;
    maxNumberOfConcurrentUsers: number | null;
    ownerId: string;
    productName: string;
    properties: any[];
    purchase_order_number: string;
    reason_for_invalidity: string | null;
    renewal_date: string | null;
    sales_order_number: string;
    serial_number: string;
    support_expiration_date: string | null;
    user: number;
};

export function createLicense(payload) {
    return post(API_ENDPOINTS.LICENSE_CREATE, payload).then(res => res.data);
}

export function assignLicense(sn, confirmed) {
    return post<LicenseDetails>(API_ENDPOINTS.LICENSE_ASSIGN, { serial_number: sn, confirmed: confirmed }).then(
        res => res.data
    );
}

export function updateLicense(id, payload) {
    return put<LicenseDetails>(API_ENDPOINTS.LICENSE_DETAIL, id, payload).then(res => res.data);
}

type LicenseReportPayload = {
    product: string;
    start: Date;
    end: Date;
};

function downloadLicenseReport(url: string, payload: LicenseReportPayload) {
    return get(url, {
        params: {
            product: payload.product,
            creation_date_after: payload.start && formatISO(payload.start, { representation: 'date' }),
            creation_date_before: payload.end && formatISO(payload.end, { representation: 'date' }),
            format: 'xlsx',
        },
        responseType: 'blob',
    });
}

export const downloadSoldLicenses = downloadLicenseReport.bind(null, [API_ENDPOINTS.REPORT_SOLD_LICENSES]);
export const downloadRenewedLicenses = downloadLicenseReport.bind(null, [API_ENDPOINTS.REPORT_RENEWED_LICENSES]);

export function downloadSystemInfoFiles(productId) {
    return get(API_ENDPOINTS.REPORT_SYSTEM_INFO_FILES, {
        params: {
            product: productId,
            format: 'xlsx',
        },
        responseType: 'blob',
    });
}

export function renewLicense(id, expirationDate, supportExpirationDate) {
    var dateString = expirationDate ? formatISO(expirationDate, { representation: 'date' }) : '';
    var supportDateString = supportExpirationDate ? formatISO(supportExpirationDate, { representation: 'date' }) : '';
    return post(`${API_ENDPOINTS.LICENSE_DETAIL}${id}/renew/`, {
        expiration_date: dateString,
        support_expiration_date: supportDateString,
    }).then(res => res.data);
}

export function invalidateLicense(payload) {
    return post(`${API_ENDPOINTS.LICENSE_DETAIL}${payload.licenseId}/invalidate/`, { reason: payload.reason });
}

export function loadLicenseDetails(licenseId: number) {
    return get<LicenseDetails>(`${API_ENDPOINTS.LICENSE_DETAIL}${licenseId}`).then(res => res.data);
}

export function useLicense(licenseId: number, enabled = true) {
    return useQuery({
        enabled,
        queryKey: ['License', 'detail', Number(licenseId)],
        queryFn: () => loadLicenseDetails(Number(licenseId)),
        meta: { errorMessage: 'Loading license failed' },
    });
}

export type LicenseListEntry = {
    id: number;
    configuration: string;
    internal: boolean;
    serial_number: string;
    sales_order_number: string;
    purchase_order_number: string;
    ownerId: string;
    creation_date: string;
    invalidated: boolean;
    validity: number;
    validityUnit: 'd' | 'm' | 'y';
    expiration_date: string;
    support_expiration_date: string;
    isExpired: boolean;
    renewal_date: string;
    creator: string;
    licenseFilesCount: number;
    latestLicenseFileHardwareIdentifiers: string;
};

export type LicenseList = {
    count: number;
    next: string | null;
    previous: string | null;
    results: LicenseListEntry[];
};

export type LicenseListParams = {
    page_size?: string | number;
    page?: string | number;
    filter?: string;
    product?: string | number;
    order?: string;
    macAddresses?: string;
};

export function loadLicenseList(params: LicenseListParams) {
    return get<LicenseList>(API_ENDPOINTS.LICENSE_LIST, { params }).then(res => res.data);
}

export function useCustomerLicenses({ customerId, invalidated }: { customerId: number; invalidated?: boolean }) {
    const params = {
        user: customerId,
        filterUserProducts: true,
        invalidated: invalidated,
        page_size: 1000,
    };
    return useQuery({
        queryKey: ['License', 'list', params],
        queryFn: () => loadLicenseList(params),
        meta: { errorMessage: 'Loading customer licenses failed' },
    });
}

export function deleteLicense(id) {
    return destroy(API_ENDPOINTS.LICENSE_DETAIL, id);
}

export function loadHistory(licenseId: number) {
    return get<HistoryEntry[]>(API_ENDPOINTS.LICENSE_HISTORY, { params: { id: licenseId } }).then(res => res.data);
}

export type LicenseFile = {
    id: number;
    creator: string;
    creator_fullname: string;
    creation_date: string;
    isExpired: boolean;
    filename: string;
    expiration_date: string;
    support_expiration_date: string;
    license: number;
    hardwareBinding: HardwareBinding;
    hardwareIdentifiers: string[];
    license_identifier: string;
    license_version: number;
    floating_count: number;
    license_can_sign_files: boolean;
    platforms: string;
    comment: string;
    licenseInfo: string;
    computer_name: string;
    deactivated: boolean;
    deactivation_reason: string;
};

export function loadLicenseFileList(payload) {
    return get<LicenseFile[]>(API_ENDPOINTS.LICENSEFILE_LIST, { params: { license: payload.licenseId } }).then(
        res => res.data
    );
}

export function loadLicenseFileHistory(licenseFileId: number) {
    return get<HistoryEntry[]>(API_ENDPOINTS.LICENSEFILE_HISTORY, { params: { id: licenseFileId } }).then(
        res => res.data
    );
}

export function loadLicenseFileDetails(licenseFileId: number) {
    return get<LicenseFile>(`${API_ENDPOINTS.LICENSEFILE_DETAIL}${licenseFileId}`).then(res => res.data);
}

export function useLicenseFile(licenseFileId: number) {
    return useQuery({
        queryKey: ['LicenseFile', 'detail', Number(licenseFileId)],
        queryFn: () => loadLicenseFileDetails(Number(licenseFileId)),
        meta: { errorMessage: 'Loading license file failed.' },
    });
}

export function downloadLicenseFile(licenseFileId) {
    return post(`${API_ENDPOINTS.LICENSEFILE_DETAIL}${licenseFileId}/download/`, null, {
        transformResponse: [data => data],
    });
}

export function sendLicenseFileViaEmail(licenseFileId) {
    return post(`${API_ENDPOINTS.LICENSEFILE_DETAIL}${licenseFileId}/email/`);
}

export function createLicenseFile(payload) {
    return post<LicenseFile>(API_ENDPOINTS.LICENSEFILE_LIST, payload).then(res => res.data);
}

export function updateLicenseFile(id, payload) {
    return put<LicenseFile>(API_ENDPOINTS.LICENSEFILE_DETAIL, id, payload).then(res => res.data);
}

export function deactivateLicenseFile(licenseFileId: number, reason: string) {
    return post<{ status: string }>(`${API_ENDPOINTS.LICENSEFILE_DETAIL}${licenseFileId}/deactivate`, { reason }).then(
        res => res.data
    );
}

export function moveLicenseFile(payload: { id: number; licenseId: number }) {
    return patch(API_ENDPOINTS.LICENSEFILE_DETAIL, payload.id, { license: payload.licenseId }).then(res => res.data);
}

export function loadLicenseStatistics(scale, includeInternalLicenses, startDate) {
    return get(API_ENDPOINTS.REPORT_LICENSE_STATISTICS, {
        params: { scale, creation_date__date__gte: startDate, isInternal: includeInternalLicenses ? undefined : false },
    }).then(res => res.data);
}

export function loadLicenseSerialNumbers(payload) {
    return get<Record<string, any>>(API_ENDPOINTS.LICENSE_SERIALNUMBERS, { params: payload }).then(res => res.data);
}

export function loadUpgradeSystemIds(licenseId) {
    return get<any[]>(`${API_ENDPOINTS.LICENSE_DETAIL}${licenseId}/upgrade-systemid`).then(res => res.data);
}

export function mutateLicenseFile(queryClient: QueryClient, licenseFile: LicenseFile) {
    replaceObjectInReactQueryCache(queryClient, licenseFile, 'LicenseFile');
}

export function searchDuplicateMacAddresses(licenseId: number, hardwareIdentifiers: string[]) {
    return post<boolean>(`${API_ENDPOINTS.LICENSEFILE_DETAIL}${licenseId}/searchDuplicateMacAddresses/`, {
        params: hardwareIdentifiers,
    }).then(res => res.data);
}

export type LicenseGeneratorVersion = {
    name: string;
    version: string;
};

export function useLicenseGeneratorsVersion() {
    return useQuery({
        queryKey: ['LicenseGeneratorsVersion'],
        queryFn: () => getLicenseGeneratorsVersion(),
        meta: { errorMessage: 'Loading license generators version failed' },
    });
}

export function getLicenseGeneratorsVersion() {
    return get<LicenseGeneratorVersion[]>(API_ENDPOINTS.LICENSE_GENERATORS_VERSION).then(res => res.data);
}
