export const DATE_FORMAT = 'yyyy-MM-dd';
export const TIME_FORMAT = 'hh:mm:ss aaa';
export const DATE_TIME_FORMAT = DATE_FORMAT + ', ' + TIME_FORMAT;

export const HARDWARE_BINDING_NAMES = [
    'None',
    'MAC Address',
    'System ID',
    'MBC System ID',
    'SmartDR Legacy SiteID',
    'Inficrypt System Id',
] as const;

export type HARDWARE_BINDING_NAME = (typeof HARDWARE_BINDING_NAMES)[number];
