import { useHistory } from 'react-router-dom';

import { Button, Card, CardActions, CardContent, CardHeader } from '@mui/material';

import { PageContainer } from 'commonComponents/PageContainer';

export function NotAllowedPage() {
    const history = useHistory();
    return (
        <PageContainer maxWidth="sm" dataTestId="notAllowedContainer">
            <Card>
                <CardHeader title="Not Allowed" />
                <CardContent>You have insufficient privileges to access this page.</CardContent>
                <CardActions>
                    <Button color="primary" onClick={history.goBack} variant="contained">
                        Back
                    </Button>
                </CardActions>
            </Card>
        </PageContainer>
    );
}
