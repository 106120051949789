import { generatePath } from 'react-router';

import { LinkButton } from 'commonComponents/LinkButton';

import { EditIcon } from 'icons';
import { ROLE_DEFINITIONS } from 'roles/definitions';
import { RoleDependentComponent } from 'roles/RoleDependentComponent';
import { ROUTES } from 'routes/routes';

export function LicenseEditButton({ licenseDetails }) {
    const { invalidated, id: licenseId } = licenseDetails;
    if (invalidated) return null;
    return (
        <RoleDependentComponent
            userRole={ROLE_DEFINITIONS.PrivilegedCustomer}
            component={LinkButton}
            iconClass={EditIcon}
            route={generatePath(ROUTES.LICENSE_EDIT, { id: licenseId })}
            title="Edit"
            data-testid="LicenseDetails_Edit"
        />
    );
}
