import { useParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { LoadingIndicator } from 'commonComponents/LoadingIndicator';

import { loadHistoricalCustomer } from '../api/customers';
import { EditCustomer } from './EditCustomer';

export function RevertCustomer() {
    const { historyId } = useParams<{ historyId: string }>();
    const { data: customer, error } = useQuery({
        queryKey: ['CustomerHistory', 'detail', Number(historyId)],
        queryFn: () => loadHistoricalCustomer(Number(historyId)),
    });

    if (error) {
        return null;
    }

    if (!customer) {
        return <LoadingIndicator />;
    }

    return <EditCustomer customer={customer} />;
}
