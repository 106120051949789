import { useState } from 'react';
import { generatePath } from 'react-router';

import { Box, Checkbox, FormControlLabel, IconButton, Menu, MenuItem } from '@mui/material';

import { BASE_URL } from 'common/baseUrl';
import { triggerDownload } from 'common/fileUtils';

import { LinkButton } from 'commonComponents/LinkButton';
import { useTableInteractionStyles } from 'commonComponents/ObjectTable/styles';

import { useUser } from 'api/authorization';
import { API_ENDPOINTS } from 'api/endpoints';
import { useHandleError } from 'api/hooks/errorHandling';
import { downloadSystemInfoFiles } from 'api/licenses';
import { Product, useCurrentProduct } from 'api/products';
import { EuroSymbolIcon, MoreVertIcon } from 'icons';
import { ClaimIcon } from 'icons/ClaimIcon';
import { CreateIcon } from 'icons/CreateIcon';
import { ROLE_DEFINITIONS } from 'roles/definitions';
import { RoleDependentComponent } from 'roles/RoleDependentComponent';
import { ROUTES } from 'routes/routes';

function ClaimedLicensesLink({ currentProduct }: { currentProduct: Product }) {
    const path = generatePath(API_ENDPOINTS.REPORT_CLAIMED_LICENSES);
    const params = new URLSearchParams({ format: 'xlsx', configuration__product: currentProduct.id.toString() });
    const url = new URL(path.slice(1), `${[window.location.origin, BASE_URL].join('/')}/`);
    url.search = params.toString();

    return (
        <MenuItem
            // react-router-dom Link doesn't work here when deployed under a base url
            component="a"
            href={url.href}
            download
        >
            Claimed Licenses
        </MenuItem>
    );
}

function AdditionalActions() {
    const [anchorEl, setAnchorEl] = useState(null);
    const { data: currentProduct } = useCurrentProduct();

    const handleError = useHandleError();

    if (!currentProduct) {
        return null;
    }

    const handleClick = event => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const triggerDownloadSystemInfoFiles = () => {
        handleClose();
        downloadSystemInfoFiles(currentProduct.id)
            .then(triggerDownload)
            .catch(error => handleError(error, 'Downloading system info files failed.'));
    };

    return (
        <>
            <IconButton
                aria-label="more"
                aria-controls="addactions-menu"
                aria-haspopup="true"
                onClick={handleClick}
                color="primary"
                size="large"
            >
                <MoreVertIcon />
            </IconButton>
            <Menu id="addactions-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={triggerDownloadSystemInfoFiles}>Download System Info files</MenuItem>
                <ClaimedLicensesLink currentProduct={currentProduct} />
            </Menu>
        </>
    );
}

export function LicenseListFilters({
    showExpiredLicenses,
    setShowExpiredLicenses,
    showInternalLicenses,
    setShowInternalLicenses,
}) {
    return (
        <Box flexGrow={1} display="flex" flexDirection="row" paddingLeft={5}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={showExpiredLicenses}
                        onChange={event => setShowExpiredLicenses(event.target.checked)}
                        name="showExpiredLicenses"
                    />
                }
                label="Show Expired Licenses"
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={showInternalLicenses}
                        onChange={event => setShowInternalLicenses(event.target.checked)}
                        name="showInternalLicenses"
                    />
                }
                label="Show Internal Licenses"
            />
        </Box>
    );
}

export function LicenseListActions() {
    const { classes } = useTableInteractionStyles();
    const { data: user } = useUser();

    return (
        <>
            <RoleDependentComponent
                userRole={ROLE_DEFINITIONS.LicenseManager}
                component={LinkButton}
                className={classes.button}
                iconClass={EuroSymbolIcon}
                route={ROUTES.LICENSE_BILLING}
                title="Billing"
                variant="outlined"
            />
            <RoleDependentComponent
                userRole={ROLE_DEFINITIONS.PrivilegedCustomer}
                component={LinkButton}
                className={classes.button}
                iconClass={CreateIcon}
                route={ROUTES.LICENSE_CREATE}
                title="Create"
            />
            {!user?.invalidated && (
                <RoleDependentComponent
                    userRole={ROLE_DEFINITIONS.Customer}
                    component={LinkButton}
                    className={classes.button}
                    iconClass={ClaimIcon}
                    route={ROUTES.LICENSE_CLAIM}
                    title="Claim"
                />
            )}
            <RoleDependentComponent userRole={ROLE_DEFINITIONS.Administrator} component={AdditionalActions} />
        </>
    );
}
