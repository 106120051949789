import { useState } from 'react';

import { Card, CardContent, CardHeader, Collapse, Divider, IconButton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { makeStyles } from 'makeStyles';

import { HistoryList } from 'commonComponents/HistoryList';
import { LoadingIndicator } from 'commonComponents/LoadingIndicator';

import { loadLicenseFileHistory } from 'api/licenses';
import { ExpandLessIcon, ExpandMoreIcon } from 'icons';
import { CircularProgressIcon } from 'icons/CircularProgressIcon';

const useStyles = makeStyles()(theme => ({
    card: {
        marginTop: theme.spacing(1),
    },
    cardContent: {
        paddingTop: 0,
        '&:last-child': {
            paddingBottom: 0,
        },
    },
    cardHeader: {
        cursor: 'pointer',
    },
}));

function HistoryCard({ open, licenseFileHistory }) {
    const { classes } = useStyles();
    return (
        <Collapse in={open} timeout="auto" unmountOnExit>
            <Divider />
            <CardContent className={classes.cardContent}>
                <HistoryList entries={licenseFileHistory} modelName="license file" />
            </CardContent>
        </Collapse>
    );
}

export function LicenseFileHistory({ licenseFileId }: { licenseFileId: number }) {
    const { classes } = useStyles();
    const {
        data: licenseFileHistory,
        error,
        isLoading,
    } = useQuery({
        queryKey: ['LicenseFileHistory', 'list', licenseFileId],
        queryFn: () => loadLicenseFileHistory(licenseFileId),
        meta: { errorMessage: 'Loading license file history failed.' },
    });
    const [open, setOpen] = useState(false);

    if (error) {
        return null;
    }
    return (
        <Card className={classes.card}>
            <CardHeader
                onClick={() => setOpen(!isLoading && !open)}
                subheader="History"
                action={
                    <IconButton size="large">
                        {isLoading ? <CircularProgressIcon /> : open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                }
                className={classes.cardHeader}
                data-testid="LicenseFileHistory_CardHeader"
            >
                <LoadingIndicator />
            </CardHeader>
            {!isLoading && <HistoryCard open={open} licenseFileHistory={licenseFileHistory} />}
        </Card>
    );
}
