import * as React from 'react';

import { List, ListItem, ListItemText, Menu, MenuItem } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { makeStyles } from 'makeStyles';

import { useUser } from 'api/authorization';
import { productMenuDisabledAtom } from 'api/gui';
import { setCurrentProductById, useCurrentProduct } from 'api/products';

const useStyles = makeStyles()(theme => ({
    root: {
        flexGrow: '1',
        paddingRight: theme.spacing(2),
        display: 'flex',
        width: 'min-content',
        maxHeight: theme.spacing(9),
    },
    listItemText: {
        padding: 0,
        maxHeight: theme.spacing(8),
        overflow: 'hidden',
    },
    multiline: {
        overflowWrap: 'anywhere',
        overflow: 'hidden',
    },
}));

export function ProductMenu() {
    const { classes } = useStyles();
    const { data: user } = useUser();
    const { data: currentProduct } = useCurrentProduct();
    const [productMenuDisabled] = useAtom(productMenuDisabledAtom);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const queryClient = useQueryClient();

    const handleClickListItem = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event, index) => {
        setCurrentProductById(user.products[index].id);
        queryClient.invalidateQueries(['currentProduct']);
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    if (!user) {
        return null;
    }

    if (user.products.length === 0) {
        return (
            <List disablePadding classes={{ root: classes.root }}>
                <ListItem disabled button title={'test'}>
                    <ListItemText
                        classes={{ root: classes.listItemText, multiline: classes.multiline }}
                        primary="No product assigned"
                    />
                </ListItem>
            </List>
        );
    }

    return (
        <>
            <List disablePadding classes={{ root: classes.root }}>
                <ListItem
                    disabled={productMenuDisabled}
                    button
                    data-testid="ProductMenuButton"
                    onClick={handleClickListItem}
                >
                    <ListItemText
                        classes={{ root: classes.listItemText, multiline: classes.multiline }}
                        primary="Product:"
                        secondary={currentProduct?.name}
                        secondaryTypographyProps={{ color: 'inherit' }}
                    />
                </ListItem>
            </List>
            <Menu
                data-testid="ProductMenu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{ role: 'listbox' }}
            >
                {user.products.map((product, index) => (
                    <MenuItem
                        data-cy={`product-menu-item_${product.name}`}
                        key={`${product.name}_${product.id}`}
                        selected={product.id === currentProduct?.id}
                        onClick={event => handleMenuItemClick(event, index)}
                    >
                        {product.name}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}
