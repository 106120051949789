import { useState } from 'react';
import { Link } from 'react-router-dom';

import { Box, IconButton, TableCell, TableRow, Tooltip, Typography, useTheme } from '@mui/material';

import { cellValue } from 'commonComponents/ObjectTable/ObjectTableContext';

import { CheckCircleIcon, CopyIcon, OpenInNewIcon } from 'icons';

function LinkTo({ value, linkTo }) {
    const theme = useTheme();

    return (
        <Typography
            style={{ display: 'inherit', width: 'fit-content' }}
            color="primary"
            variant="body2"
            target="_blank"
            component={Link}
            to={linkTo}
        >
            <Tooltip title="Open customer page in a new tab" placement="bottom-start">
                <Box display="flex" alignItems="center" gap={theme.spacing(1)}>
                    {value}
                    <OpenInNewIcon style={{ fontSize: 'inherit' }} />
                </Box>
            </Tooltip>
        </Typography>
    );
}

function CopyToClipboard({ value, text }) {
    const theme = useTheme();
    const [isCopied, setIsCopied] = useState(false);

    async function copyToClipboard(text) {
        return await navigator.clipboard.writeText(text);
    }

    const copy = () => {
        copyToClipboard(text).then(() => {
            setIsCopied(true);
            setTimeout(() => {
                setIsCopied(false);
            }, 3000);
        });
    };

    return (
        <Typography style={{ display: 'inherit', width: 'fit-content' }} variant="body2" component="div">
            <Box display="flex" alignItems="center" gap={theme.spacing(1)}>
                {value}
                {isCopied ? (
                    <Tooltip title="Copied" placement="bottom-start">
                        {/* use an IconButton as well to keep the same size */}
                        <IconButton color="primary" size="small">
                            <CheckCircleIcon style={{ fontSize: 'inherit' }} />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <Tooltip title="Copy to Clipboard" placement="bottom-start">
                        <IconButton onClick={copy} color="primary" size="small">
                            <CopyIcon style={{ fontSize: 'inherit' }} />
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
        </Typography>
    );
}

function CellContent({ rowConfig: { accessor, route, clipboard }, details }) {
    const value = cellValue(details, { accessor: accessor });
    const linkTo = route && route(details);
    const copyToClipboard = clipboard && clipboard(details);

    if (linkTo) {
        return <LinkTo value={value} linkTo={linkTo} />;
    }

    if (copyToClipboard) {
        return <CopyToClipboard value={value} text={copyToClipboard} />;
    }

    return <Typography variant="body2">{value}</Typography>;
}

export function LicenseDetailsRow({ rowConfig, details }) {
    const show = rowConfig.hide == null || !rowConfig.hide(details);
    if (!show) return null;

    return (
        <TableRow>
            <TableCell component="th" scope="row">
                <Typography noWrap variant="subtitle2">
                    {rowConfig.title}
                </Typography>
            </TableCell>

            <TableCell data-testid={`LicenseDetailsRow_${rowConfig.title.toLowerCase().replaceAll(' ', '')}`}>
                <CellContent rowConfig={rowConfig} details={details} />
            </TableCell>
        </TableRow>
    );
}
