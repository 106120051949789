import { Controller, useFormContext } from 'react-hook-form';

import { Autocomplete, CircularProgress, FormControl, FormHelperText, TextField } from '@mui/material';

interface ExtendedProps {
    name: string;
    required?: boolean;
    label: string;
    testId?: string;
    onChangeCallback?: Function;
}

export function AutocompleteElement({
    name,
    testId,
    label,
    required,
    loading,
    options,
    onChangeCallback,
    placeholder,
    ...rest
}: Partial<React.ComponentProps<typeof Autocomplete>> & ExtendedProps) {
    const { control } = useFormContext();
    return (
        <Controller
            control={control}
            name={name}
            rules={required ? { required: 'This field is required' } : undefined}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormControl variant="standard" error={Boolean(error)} fullWidth>
                    <Autocomplete
                        data-testid={testId}
                        fullWidth
                        value={value}
                        autoComplete={false}
                        options={options}
                        onChange={(event, value, reason, details) => {
                            onChange(value);
                            if (onChangeCallback) {
                                onChangeCallback(event, value, reason, details);
                            }
                        }}
                        renderInput={params => (
                            <TextField
                                label={label}
                                variant="outlined"
                                {...params}
                                placeholder={placeholder}
                                error={Boolean(error)}
                                required={required}
                                margin="none"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        )}
                        {...rest}
                    ></Autocomplete>
                    <FormHelperText error>{error?.message}</FormHelperText>
                </FormControl>
            )}
        ></Controller>
    );
}
