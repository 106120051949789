import { QueryClient } from '@tanstack/react-query';

import { destroy, get, post, put } from './axiosWrapper';
import { removeObjectFromReactQueryCache, replaceObjectInReactQueryCache } from './cache';
import { API_ENDPOINTS } from './endpoints';
import type { HistoryEntry } from './history';
import { Product } from './products';

export type CustomerDetails = {
    address: string;
    city: string;
    comment: string;
    company: string;
    country: string;
    default_product: Product | null;
    default_screen: string;
    email: string;
    full_name: string;
    groups: string[];
    hasLicenses: boolean;
    id: number;
    is_active: boolean;
    is_internal: boolean;
    owner_id: string;
    phone_number: string;
    products: any[];
    username: string;
};

export function loadCustomerList(params: any) {
    return get<CustomerDetails[]>(API_ENDPOINTS.CUSTOMER_LIST, { params }).then(res => res.data);
}

export function loadCustomerDetails(customerId: number) {
    return get<CustomerDetails>(`${API_ENDPOINTS.CUSTOMER_DETAIL}${customerId}`).then(res => res.data);
}

export function loadCustomerHistory(customerId: number) {
    return get<HistoryEntry[]>(`${API_ENDPOINTS.CUSTOMER_DETAIL}${customerId}/history/`).then(res => res.data);
}

export function loadHistoricalCustomer(historyId: number) {
    return get<CustomerDetails>(`${API_ENDPOINTS.CUSTOMER_HISTORY_DETAIL}${historyId}`).then(res => res.data);
}

export type CreateCustomerDetails = {
    address: string;
    city: string;
    comment: string;
    company: string;
    email: string;
    groups: Array<string>;
    is_active: boolean;
    owner_id: string;
    phone_number: string;
    products: Array<number>;
    username: string;
};

export type UpdateCustomerDetails = Omit<CustomerDetails, 'products' | 'default_product'> & {
    products: Array<number>;
    default_product?: number;
};

export function createCustomer(payload: CreateCustomerDetails) {
    return post<CustomerDetails>(API_ENDPOINTS.CUSTOMER_CREATE, payload).then(res => res.data);
}

export function updateCustomer(id: number, payload: UpdateCustomerDetails) {
    return put<CustomerDetails>(API_ENDPOINTS.CUSTOMER_DETAIL, id, payload).then(res => res.data);
}

export function deleteCustomer(id: number) {
    return destroy(API_ENDPOINTS.CUSTOMER_DETAIL, id);
}

export function downloadCustomerOrigins() {
    return get(API_ENDPOINTS.REPORT_CUSTOMER_ORIGINS, {
        params: { format: 'xlsx', productId: window.localStorage.getItem('currentProductId') },
        responseType: 'blob',
    });
}

export function loadCompanyList({ onlyUserProducts }) {
    return get<string[]>(API_ENDPOINTS.COMPANY_LIST, { params: { onlyUserProducts } }).then(res => res.data);
}

export function loadOwnerIdList({ onlyUserProducts, product }: { onlyUserProducts: boolean; product?: string }) {
    return get<string[]>(API_ENDPOINTS.OWNER_ID_LIST, { params: { onlyUserProducts, product } }).then(res => res.data);
}

export function mutateCustomer(queryClient: QueryClient, customer: CustomerDetails) {
    replaceObjectInReactQueryCache(queryClient, customer, 'Customer');
}

export function forgetCustomer(queryClient: QueryClient, customerId: number) {
    removeObjectFromReactQueryCache(queryClient, customerId, 'Customer');
}
