import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ActionButton } from 'commonComponents/ActionButton';

import { mutateProductConfiguration, updateProductConfiguration } from 'api/productConfigurations';
import { DeleteIcon, UndoIcon } from 'icons';

export function ConfigurationInvalidateDialog({ configuration, open, onClose }) {
    const queryClient = useQueryClient();
    const { mutate: trigger, isLoading: isRequesting } = useMutation({
        mutationFn: () =>
            updateProductConfiguration(configuration.id, {
                ...configuration,
                features: configuration.features.map(f => f.id),
                product: configuration.product.id,
                invalidated: true,
            }),
        onSuccess: response => {
            mutateProductConfiguration(queryClient, response);
            onClose();
        },
        meta: { errorMessage: 'Invalidating license failed' },
    });

    return (
        <Dialog {...{ open, onClose }}>
            <DialogTitle>Invalidate Product Configuration</DialogTitle>
            <DialogContent>Invalidate this product configuration?</DialogContent>
            <DialogActions>
                <ActionButton
                    color="secondary"
                    iconClass={DeleteIcon}
                    disabled={isRequesting}
                    inProgress={isRequesting}
                    title="Invalidate"
                    variant="contained"
                    type="submit"
                    onClick={() => trigger()}
                />
                <ActionButton
                    color="primary"
                    iconClass={UndoIcon}
                    inProgress={false}
                    onClick={onClose}
                    title="Cancel"
                    variant="contained"
                />
            </DialogActions>
        </Dialog>
    );
}
