import { Link as RouterLink } from 'react-router-dom';

import { Link, ListItemIcon, ListItemText, MenuItem } from '@mui/material';

import { PersonIcon } from 'icons';
import { ROUTES } from 'routes/routes';

export function ProfileMenuItem({ onClose }) {
    return (
        <MenuItem onClick={onClose}>
            <Link
                to={ROUTES.PROFILE}
                component={RouterLink}
                style={{ display: 'flex', alignItems: 'center' }}
                color="inherit"
                underline="none"
            >
                <ListItemIcon>
                    <PersonIcon />
                </ListItemIcon>
                <ListItemText>Profile</ListItemText>
            </Link>
        </MenuItem>
    );
}
