import _ from 'lodash';

import { useUser } from 'api/authorization';
import { ROLE_DEFINITIONS } from 'roles/definitions';

/**
 * @param {any} user
 * @param {import('roles/definitions').Role} role
 * @returns {boolean}
 */
export function userHasRole(user, role) {
    return (
        user &&
        (user.is_superuser ||
            _.some(user.groups, group => _.get(ROLE_DEFINITIONS, group, ROLE_DEFINITIONS.None) >= role))
    );
}

/** @returns {(role: import('roles/definitions').Role) => boolean} */
export function useCurrentUserHasRole() {
    const { data: user } = useUser();
    return role => userHasRole(user, role);
}

export function useDefaultScreen() {
    const { data: user } = useUser();
    if (
        user &&
        (userHasRole(user, ROLE_DEFINITIONS.LicenseManager) || userHasRole(user, ROLE_DEFINITIONS.Administrator))
    )
        return user.default_screen;
    return 'L';
}
