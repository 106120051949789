import { ConfigurationDeleteButton } from './ConfigurationDeleteButton';
import { ConfigurationInvalidateButton } from './ConfigurationInvalidateButton';

export function DeleteOrInvalidateConfiguration({ configuration }) {
    if (configuration.inUse) {
        return <ConfigurationInvalidateButton configuration={configuration} />;
    }

    return <ConfigurationDeleteButton configuration={configuration} />;
}
