import { Card, CardContent, Typography } from '@mui/material';

export function RequestNewPasswordSuccess() {
    return (
        <Card>
            <CardContent>
                <Typography>
                    We have emailed you instructions for setting your password. If they have not arrived in a few
                    minutes, check your spam folder.
                </Typography>
            </CardContent>
        </Card>
    );
}
