import { useEffect, useMemo } from 'react';
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';

import { useQuery } from '@tanstack/react-query';

import { usePersistentState } from 'common/hooks';

import { ObjectTable } from 'commonComponents/ObjectTable/ObjectTable';
import { mapBoolean } from 'commonComponents/ObjectTable/ObjectTableContext';
import { MIN_WIDTH } from 'commonComponents/ObjectTable/styles';
import { PageContainer } from 'commonComponents/PageContainer';

import { loadUserList } from 'api/users';

import { UserListButtons } from './UserListButtons';

const columns = [
    { Header: 'User Name', accessor: 'username' },
    { Header: 'Full Name', accessor: 'full_name' },
    { Header: 'Email Address', accessor: 'email' },
    { Header: 'Role', accessor: 'userType' },
    { Header: 'Internal', accessor: user => mapBoolean(user.is_internal) },
    { Header: 'Active', accessor: user => mapBoolean(user.is_active) },
].map(c => ({ ...c, id: c.Header }));

const emptyList = [];

export function UserList() {
    const { data: users, isLoading } = useQuery({
        queryKey: ['User', 'list'],
        queryFn: loadUserList,
        select: users => users.map(u => ({ ...u, strikethrough: u.invalidated })),
    });

    const defaultFilterSortSettings = useMemo(
        () => ({
            filter: '',
            sortBy: [{ id: 'User Name', desc: false }],
        }),
        []
    );
    const [initialFilterSortSettings, persistFilterSortSettings] = usePersistentState(
        'userFilterSort',
        defaultFilterSortSettings
    );

    const table = useTable(
        {
            columns,
            data: users || emptyList,
            initialState: {
                pageSize: 15,
                sortBy: initialFilterSortSettings.sortBy,
                globalFilter: initialFilterSortSettings.filter,
            },
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );
    const {
        state: { globalFilter, sortBy },
    } = table;

    useEffect(() => {
        persistFilterSortSettings({
            filter: globalFilter || '',
            sortBy,
        });
    }, [globalFilter, persistFilterSortSettings, sortBy]);

    return (
        <PageContainer dataTestId="UserList_Container" minWidth={MIN_WIDTH.LIST} maxWidth={false} maxHeight="100%">
            <ObjectTable historyPath={'/user/'} Buttons={UserListButtons} loading={isLoading} table={table} />
        </PageContainer>
    );
}
