import { useHistory } from 'react-router';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ActionButton } from 'commonComponents/ActionButton';

import { deleteLicense } from 'api/licenses';
import { DeleteIcon, UndoIcon } from 'icons';
import { ROUTES } from 'routes/routes';

export function LicenseDeleteDialog({ licenseId, open, onClose }) {
    const queryClient = useQueryClient();
    const { mutate: trigger, isLoading: isDeleting } = useMutation({
        mutationFn: () => deleteLicense(licenseId),
        onSuccess: () => {
            queryClient.invalidateQueries(['License', 'list']);
            queryClient.removeQueries(['License', 'detail', licenseId]);
            onClose();
            history.push(ROUTES.LICENSE_LIST);
        },
        meta: { errorMessage: 'Deleting license failed' },
    });
    const history = useHistory();

    return (
        <Dialog {...{ open, onClose }}>
            <DialogTitle>Delete License</DialogTitle>
            <DialogContent> Are you sure you want to delete this license?</DialogContent>
            <DialogActions>
                <ActionButton
                    color="secondary"
                    iconClass={DeleteIcon}
                    disabled={isDeleting}
                    inProgress={isDeleting}
                    title="Delete"
                    variant="contained"
                    type="submit"
                    onClick={() => trigger()}
                />
                <ActionButton
                    color="primary"
                    iconClass={UndoIcon}
                    inProgress={false}
                    onClick={onClose}
                    title="Cancel"
                    variant="contained"
                />
            </DialogActions>
        </Dialog>
    );
}
