import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';

import { QueryClient } from '@tanstack/react-query';

import { clearCache } from 'api/cache';
import { useCurrentProduct } from 'api/products';
import { ROUTES } from 'routes/routes';

import { isSessionExpired } from './isSessionExpired';

export function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export function useHandleExpiredSession(queryClient?: QueryClient) {
    const history = useHistory();
    return useCallback(
        error => {
            if (isSessionExpired(error)) {
                clearCache(queryClient);
                history.push({ pathname: ROUTES.LOGIN, search: '?session_expired' });
            }
        },
        [queryClient, history]
    );
}

export function usePersistentState<T = any>(
    key: string,
    initialValue: T,
    isSession = true
): [T, React.Dispatch<React.SetStateAction<T>>] {
    const storage = isSession ? sessionStorage : localStorage;
    const [state, setState] = useState<T>(() => {
        try {
            const storageValue = storage.getItem(key);
            if (typeof storageValue !== 'string') {
                storage.setItem(key, JSON.stringify(initialValue));
                return initialValue;
            }
            return JSON.parse(storageValue || 'null');
        } catch {
            return initialValue;
        }
    });

    useEffect(() => {
        try {
            const serializedState = JSON.stringify(state);
            storage.setItem(key, serializedState);
        } catch {}
    });

    return [state, setState];
}

export function useTableWithPersistedFilterSort(tableOptions, defaultSortBy, persistKey) {
    const { data: currentProduct } = useCurrentProduct();

    const defaultFilterSortSettings = useMemo(
        () => ({
            filter: '',
            sortBy: defaultSortBy,
            product: currentProduct?.id,
        }),
        [currentProduct, defaultSortBy]
    );
    const [initialFilterSortSettings, persistFilterSortSettings] = usePersistentState(
        persistKey,
        defaultFilterSortSettings
    );

    tableOptions.initialState.sortBy = initialFilterSortSettings.sortBy;
    tableOptions.initialState.globalFilter = initialFilterSortSettings.filter;
    const table = useTable(tableOptions, useGlobalFilter, useSortBy, usePagination);
    const {
        state: { globalFilter, sortBy },
    } = table;

    useEffect(() => {
        if (currentProduct) {
            persistFilterSortSettings({
                filter: globalFilter || '',
                sortBy,
                product: currentProduct?.id,
            });
        }
    }, [currentProduct, globalFilter, persistFilterSortSettings, sortBy]);

    useEffect(() => {
        if (currentProduct && initialFilterSortSettings.product !== currentProduct.id) {
            persistFilterSortSettings(defaultFilterSortSettings);
            table.setGlobalFilter(defaultFilterSortSettings.filter);
            table.initialState.globalFilter = defaultFilterSortSettings.filter;
            table.state.sortBy = defaultFilterSortSettings.sortBy;
        }
    }, [initialFilterSortSettings, currentProduct, persistFilterSortSettings, defaultFilterSortSettings, table]);

    return table;
}
