import { useRef, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Box, Grid, TextField, Typography, useTheme } from '@mui/material';
import parse from 'autosuggest-highlight/parse';
import { find } from 'lodash';
import { makeStyles } from 'makeStyles';
import useDebouncedEffect from 'use-debounced-effect-hook';
import { v4 as uuidv4 } from 'uuid';

import { AutocompleteElement } from 'commonComponents/AutocompleteElement';

import { loadPlaceDetails, loadSuggestions } from 'api/places';
import AttributionImage from 'assets/powered_by_google_on_white.png';

const useStyles = makeStyles()(theme => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
}));

// Adapted from https://v4.mui.com/components/autocomplete/#google-maps-place
export function GooglePlaceAutocomplete({ form }: { form: UseFormReturn<any> }) {
    const { watch, setValue } = form as UseFormReturn<{ address: string; country: string; city: string }>;
    const value = watch('address');
    const theme = useTheme();
    const { classes } = useStyles();
    const [inputValue, setInputValue] = useState(value);
    const [suggestions, setSuggestions] = useState([]);
    const [changeReason, setChangeReason] = useState('');

    // Use useRef so that effects aren't triggered when the sessiontoken changes
    const sessionToken = useRef(uuidv4());

    useDebouncedEffect(
        () => {
            async function fetchSuggestions() {
                if (inputValue === '') {
                    setSuggestions([]);
                    setValue('country', '');
                    setValue('city', '');
                    return;
                }
                // Only fetch suggestions after typing
                if (changeReason !== 'input') {
                    return;
                }
                const response = await loadSuggestions(inputValue, sessionToken.current);
                setSuggestions(response.predictions);
            }
            fetchSuggestions();
        },
        [changeReason, inputValue, setValue],
        200
    );

    async function handleChange(event, newValue, reason) {
        setSuggestions(newValue ? [newValue, ...suggestions] : suggestions);
        if (reason === 'selectOption') {
            const response = await loadPlaceDetails(newValue.place_id, sessionToken.current);
            sessionToken.current = uuidv4();
            const addressComponents = response.result.address_components;
            const country = find(addressComponents, ['types', ['country']]);
            const city = find(addressComponents, ['types', ['locality']]);
            setValue('country', country ? country.long_name : '');
            setValue('city', city ? city.long_name : '');
        }
    }

    function handleInputChange(event, newInputValue, reason) {
        setChangeReason(reason);
        setInputValue(newInputValue);
        setValue('address', newInputValue, { shouldValidate: true, shouldTouch: true, shouldDirty: true });
    }

    return (
        <Box display="flex" flexDirection="column" style={{ gridGap: theme.spacing(1) }}>
            <AutocompleteElement
                name="address"
                label="Address"
                getOptionLabel={(option: string | Record<string, any>) =>
                    typeof option === 'string' ? option : option.description
                }
                isOptionEqualToValue={(option: string | Record<string, any>, value: Record<string, any>) => {
                    if (value === option) {
                        return true;
                    }
                    if (typeof option === 'string' && typeof value === 'object') {
                        return option === value.description;
                    }
                    if (typeof option === 'object' && typeof value === 'string') {
                        return option.description === value;
                    }
                    return typeof option === 'object' && option.place_id === value.place_id;
                }}
                autoComplete
                filterOptions={x => x}
                options={[value, ...suggestions]}
                includeInputInList
                filterSelectedOptions
                onChange={handleChange}
                onInputChange={handleInputChange}
                renderInput={params => (
                    <TextField {...params} name="address" label="Address" variant="outlined" fullWidth />
                )}
                renderOption={(props, option?: Record<string, any>) => {
                    if (typeof option !== 'object') {
                        return null;
                    }
                    const matches = option.structured_formatting.main_text_matched_substrings || [];
                    const parts = parse(
                        option.structured_formatting.main_text,
                        matches.map(match => [match.offset, match.offset + match.length])
                    );

                    return (
                        <Grid container alignItems="center" {...(props as React.HTMLAttributes<any>)}>
                            <Grid item>
                                <LocationOnIcon className={classes.icon} />
                            </Grid>
                            <Grid item xs>
                                {parts.map((part, index) => (
                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                        {part.text}
                                    </span>
                                ))}

                                <Typography variant="body2" color="textSecondary">
                                    {option.structured_formatting.secondary_text}
                                </Typography>
                            </Grid>
                        </Grid>
                    );
                }}
            />
            <img src={AttributionImage} alt="Powered by Google" width={144} height={18} />
        </Box>
    );
}
