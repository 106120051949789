import { useForm } from 'react-hook-form';
import { FormContainer } from 'react-hook-form-mui';

import { Box, Card, CardActions, CardContent, CardHeader, useTheme } from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import { DATE_FORMAT } from 'common/constants';
import { triggerDownload } from 'common/fileUtils';

import { ActionButton } from 'commonComponents/ActionButton';
import { DisableProductMenu, NonFieldErrors } from 'commonComponents/CommonFormComponents';
import { KeyboardDatePickerElement } from 'commonComponents/KeyboardDatePickerElement';
import { LinkButton } from 'commonComponents/LinkButton';
import { PageContainer } from 'commonComponents/PageContainer';

import { useHandleFormError } from 'api/hooks/errorHandling';
import { downloadRenewedLicenses, downloadSoldLicenses } from 'api/licenses';
import { useCurrentProduct } from 'api/products';
import { SaveIcon, UndoIcon } from 'icons';
import { ROUTES } from 'routes/routes';

function generateInitialValues() {
    let end = new Date();
    let start = new Date(new Date(end).setMonth(end.getMonth() - 1));
    return { start, end };
}

export function LicenseBilling() {
    const { data: currentProduct } = useCurrentProduct();
    const formContext = useForm({ defaultValues: generateInitialValues() });
    const { watch } = formContext;

    const handleFormError = useHandleFormError(formContext);

    const { mutate: triggerSoldLicensesDownload, isLoading: soldLicensesInProgress } = useMutation({
        mutationFn: (values: { start: Date; end: Date }) =>
            downloadSoldLicenses({ product: currentProduct.id, ...values }),
        onSuccess: triggerDownload,
        onError: handleFormError,
        meta: { errorMessage: 'Downloading sold licenses failed.' },
    });
    const { mutate: triggerRenewedLicensesDownload, isLoading: renewedLicensesInProgress } = useMutation({
        mutationFn: (values: { start: Date; end: Date }) =>
            downloadRenewedLicenses({ product: currentProduct.id, ...values }),
        onSuccess: triggerDownload,
        onError: handleFormError,
        meta: { errorMessage: 'Downloading renewed licenses failed.' },
    });
    const [start, end] = watch(['start', 'end']);
    const theme = useTheme();
    return (
        <PageContainer maxWidth="sm">
            <FormContainer
                formContext={formContext}
                onSuccess={data => {
                    triggerSoldLicensesDownload(data);
                    triggerRenewedLicensesDownload(data);
                }}
            >
                <DisableProductMenu />
                <Card>
                    <CardHeader title="Download sold licenses" />
                    <CardContent>
                        <Box display="flex" gap={theme.spacing(1)}>
                            <KeyboardDatePickerElement
                                label={'From (' + DATE_FORMAT + ')'}
                                maxDate={end}
                                maxDateMessage="'To' has to be before 'From'"
                                name="start"
                            />
                            <KeyboardDatePickerElement
                                disableFuture
                                label={'To (' + DATE_FORMAT + ')'}
                                minDate={start}
                                minDateMessage="'From' has to be after 'To'"
                                name="end"
                            />
                        </Box>
                        <NonFieldErrors />
                    </CardContent>
                    <CardActions>
                        <ActionButton
                            color="primary"
                            iconClass={SaveIcon}
                            inProgress={soldLicensesInProgress || renewedLicensesInProgress}
                            title="Download"
                            type="submit"
                            variant="contained"
                        />
                        <LinkButton iconClass={UndoIcon} route={ROUTES.LICENSE_LIST} title="Back" />
                    </CardActions>
                </Card>
            </FormContainer>
        </PageContainer>
    );
}
