import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { triggerDownload } from 'common/fileUtils';
import { useHandleExpiredSession, useTableWithPersistedFilterSort } from 'common/hooks';

import { ActionButton } from 'commonComponents/ActionButton';
import { ObjectTable } from 'commonComponents/ObjectTable/ObjectTable';
import { mapBoolean } from 'commonComponents/ObjectTable/ObjectTableContext';
import { MIN_WIDTH, useTableInteractionStyles } from 'commonComponents/ObjectTable/styles';
import { PageContainer } from 'commonComponents/PageContainer';
import { SelectProduct } from 'commonComponents/SelectProduct';

import { useUser } from 'api/authorization';
import { downloadCustomerOrigins } from 'api/customers';
import { useCurrentProduct } from 'api/products';
import { ExcelIcon } from 'icons/ExcelIcon';

import { loadCustomerList } from '../api/customers';
import { ClaimLicense } from '../commonComponents/ClaimLicense';
import { LinkButton } from '../commonComponents/LinkButton';
import { CreateIcon } from '../icons/CreateIcon';
import { ROLE_DEFINITIONS } from '../roles/definitions';
import { RoleDependentComponent } from '../roles/RoleDependentComponent';
import { ROUTES } from '../routes/routes';

const columns = [
    { Header: 'Id', accessor: 'id' },
    { Header: 'Name', accessor: 'full_name' },
    { Header: 'Email', accessor: 'email' },
    { Header: 'Company', accessor: 'company' },
    { Header: 'Default Owner ID', accessor: 'owner_id' },
    { Header: 'Active', accessor: customer => mapBoolean(customer.is_active) },
    { Header: 'Comment', accessor: 'comment' },
].map(c => ({ ...c, id: c.Header }));
const emptyList = [];

export function CustomerList() {
    const { data: currentProduct } = useCurrentProduct();
    const { data: user } = useUser();
    const userHasProduct = Boolean(user?.products?.length);
    const productIsSelected = Boolean(currentProduct);

    const { data, isLoading } = useQuery({
        enabled: Boolean(currentProduct),
        queryKey: ['Customer', 'list', { product: currentProduct?.id }],
        queryFn: () => loadCustomerList({ product: currentProduct?.id }),
        meta: { errorMessage: 'Loading customers failed' },
    });

    const table = useTableWithPersistedFilterSort(
        {
            columns,
            data: data || emptyList,
            initialState: {
                pageSize: 15,
                hiddenColumns: ['Id'],
            },
        },
        [{ id: 'id', desc: true }],
        'customerFilterSort'
    );

    return (
        <PageContainer dataTestId="CustomerList_Container" minWidth={MIN_WIDTH.LIST} maxWidth={false} maxHeight="100%">
            {!userHasProduct && <ClaimLicense />}
            {userHasProduct && !productIsSelected && <SelectProduct />}
            {userHasProduct && productIsSelected && (
                <ObjectTable
                    historyPath={'/customer/'}
                    Buttons={CustomerListButtons}
                    loading={isLoading}
                    table={table}
                />
            )}
        </PageContainer>
    );
}

function CustomerListButtons() {
    const { classes } = useTableInteractionStyles();
    const handleExpiredSession = useHandleExpiredSession();
    const { enqueueSnackbar } = useSnackbar();
    const triggerDownloadCustomerOrigins = () => {
        downloadCustomerOrigins()
            .then(triggerDownload)
            .catch(error => {
                enqueueSnackbar('Downloading customer origin report failed', { variant: 'error' });
                handleExpiredSession(error);
            });
    };

    return (
        <>
            <RoleDependentComponent
                userRole={ROLE_DEFINITIONS.PrivilegedCustomer}
                component={ActionButton}
                color="primary"
                className={classes.button}
                iconClass={ExcelIcon}
                title="Customer Origins"
                onClick={triggerDownloadCustomerOrigins}
            />
            <RoleDependentComponent
                userRole={ROLE_DEFINITIONS.PrivilegedCustomer}
                component={LinkButton}
                className={classes.button}
                iconClass={CreateIcon}
                route={ROUTES.CUSTOMER_CREATE}
                title="Create"
                data-testid="FilterCustomers_CreateCustomer"
            />
        </>
    );
}
