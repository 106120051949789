import { forwardRef } from 'react';
import { IMaskInput } from 'react-imask';

export function buildSmartDrLegacySiteIdMaskInput(mask, lazy = false) {
    return forwardRef((props, ref) => {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask={mask}
                definitions={{ 0: /[0123456789ABCDEFGHKMNPQRSTUVWXYZ]/ }}
                placeholderChar={'\u2000'}
                lazy={lazy}
                inputRef={ref}
                prepareChar={str => str.toUpperCase()}
                onAccept={value => onChange({ target: { name: props.name, value } })}
                overwrite
            />
        );
    });
}
