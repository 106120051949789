import { Card, CardActions, CardContent, Typography } from '@mui/material';

import { LinkButton } from 'commonComponents/LinkButton';
import { PageContainer } from 'commonComponents/PageContainer';

import { UndoIcon } from 'icons';
import { ROUTES } from 'routes/routes';

export function UserRegistrationSuccess() {
    return (
        <PageContainer maxWidth="md">
            <Card>
                <CardContent>
                    <Typography variant="h6">Thank you for registering!</Typography>
                    <Typography>
                        We have sent an email to you containing a link to activate your account.
                        <br />
                        Please click on this link to fully access the system.
                    </Typography>
                </CardContent>
                <CardActions>
                    <LinkButton
                        iconClass={UndoIcon}
                        route={ROUTES.LOGIN}
                        title="Login"
                        data-testid="UserRegistration_Login"
                    />
                </CardActions>
            </Card>
        </PageContainer>
    );
}
