import { useState } from 'react';

import { Card, CardHeader, Collapse, Divider, IconButton, useTheme } from '@mui/material';

import { LicenseDetails } from 'api/licenses';
import { ExpandLessIcon, ExpandMoreIcon } from 'icons';
import { LicenseCardContent } from 'licenses/LicenseDetails/LicenseDetails';

export function LicenseCollapse({ license }: { license: LicenseDetails }) {
    const [open, setOpen] = useState(false);
    const theme = useTheme();

    return (
        <Card style={{ marginTop: theme.spacing(1) }}>
            <CardHeader
                onClick={() => setOpen(open => !open)}
                subheader="License"
                action={<IconButton size="large">{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>}
                style={{ cursor: 'pointer' }}
                data-testid="LicenseFileLicense_CardHeader"
            ></CardHeader>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Divider />
                <Card>
                    <LicenseCardContent details={license} />
                </Card>
            </Collapse>
        </Card>
    );
}
