import { useQuery } from '@tanstack/react-query';

import { get } from './axiosWrapper';
import { API_ENDPOINTS } from './endpoints';

export function useSettings() {
    return useQuery({
        queryKey: ['Settings'],
        queryFn: requestSettings,
        meta: { errorMessage: 'Loading settings failed' },
    });
}

export function requestSettings() {
    return get<{
        isTestSystem: boolean;
        isDevSystem: boolean;
        isDemoSystem: boolean;
        isSelfRegistrationEnabled: boolean;
        environmentName: string;
        softwareVersion: string;
        copyRightYear: string;
    }>(API_ENDPOINTS.SETTINGS_REQUEST).then(res => res.data);
}
