import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormContainer } from 'react-hook-form-mui';
import { generatePath, Redirect } from 'react-router-dom';

import {
    Box,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    IconButton,
    InputAdornment,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { GooglePlaceAutocomplete } from 'customers/GooglePlaceAutocomplete';
import { useSnackbar } from 'notistack';

import { ActionButton } from 'commonComponents/ActionButton';
import { NonFieldErrors } from 'commonComponents/CommonFormComponents';
import { LinkButton } from 'commonComponents/LinkButton';
import { PageContainer } from 'commonComponents/PageContainer';

import { registerUser } from 'api/users';
import { PersonAddIcon, UndoIcon, VisibilityIcon, VisibilityOffIcon } from 'icons';
import { ROUTES } from 'routes/routes';

import { UserRegistrationSuccess } from './UserRegistrationSuccess';

const INITIAL_VALUES = {
    address: '',
    city: '',
    company: '',
    country: '',
    email: '',
    full_name: '',
    password: '',
};

export function UserRegistration() {
    const formContext = useForm({
        defaultValues: { ...INITIAL_VALUES },
    });
    const theme = useTheme();
    const [fullName, setFullName] = useState('');
    const [email, setEMail] = useState('');
    const [company, setCompany] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [passwordError, setPasswordError] = useState('');
    const { watch, getValues, setError } = formContext;
    const [city, country] = watch(['city', 'country']);
    const { enqueueSnackbar } = useSnackbar();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showRegistrationIncomplete, setShowRegistrationIncomplete] = useState(false);

    const { mutate: trigger, isLoading: requestInProgress } = useMutation({
        mutationFn: registerUser,
        onSuccess: () => setShowConfirmation(true),
        onError: errorProcessing,
        meta: { errorMessage: 'User registration failed.' },
    });

    function errorProcessing(error) {
        var msg = 'User registration failed.';
        var formMsg = error.response.statusText;

        if (error.response.data.validationErrors?.length > 0) {
            setPasswordError(error.response.data['validationErrors'][0]);
            return;
        }

        switch (error.response.status) {
            case 400:
                switch (error.response.data) {
                    case 'blacklisted':
                        formMsg = 'Provide a valid corporate email address.';
                        break;
                    case 'need_confirmation':
                        setShowRegistrationIncomplete(true);
                        break;
                    default:
                        break;
                }
                break;
            case 403:
                formMsg = 'A user with that email address address already exists.';
                break;
            default:
                break;
        }

        setError('root.serverError', {
            type: 'server',
            message: formMsg,
        });
        enqueueSnackbar(msg, { variant: 'error' });
    }

    function requestRegistration() {
        setPasswordError('');
        trigger({
            email: email,
            full_name: fullName,
            company: company,
            password: password,
            address: getValues('address'),
            city: city,
            country: country,
        });
    }

    const defaultProps = {
        autoComplete: 'off',
        variant: 'outlined',
        margin: 'none',
    };

    if (showConfirmation) {
        return <UserRegistrationSuccess />;
    }

    if (showRegistrationIncomplete) {
        var path = generatePath(ROUTES.USER_REGISTER_INCOMPLETE, {
            email,
        });
        return <Redirect to={path} />;
    }

    return (
        <PageContainer maxWidth="md">
            <FormContainer
                formContext={formContext}
                onSuccess={requestRegistration}
                data-testid="UserRegistration_Form"
            >
                <Card>
                    <CardHeader title="New User Registration" />
                    <CardContent>
                        <Box display="flex" flexDirection="column" style={{ gridGap: theme.spacing(2) }}>
                            <TextField
                                variant="standard"
                                label="Email Address"
                                name="email"
                                type="email"
                                value={email}
                                required
                                {...defaultProps}
                                onChange={event => {
                                    setEMail(event.target.value);
                                }}
                            />
                            <TextField
                                variant="standard"
                                label="Full Name"
                                name="full_name"
                                value={fullName}
                                required
                                {...defaultProps}
                                onChange={event => {
                                    setFullName(event.target.value);
                                }}
                            />
                            <TextField
                                variant="standard"
                                label="Company"
                                name="company"
                                value={company}
                                required
                                {...defaultProps}
                                onChange={event => {
                                    setCompany(event.target.value);
                                }}
                            />
                            <GooglePlaceAutocomplete form={formContext} />
                            <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={theme.spacing(1)}>
                                {/* Regular TextFields with watch to ensure updates */}
                                <TextField label="Country" name="country" disabled variant="outlined" value={country} />
                                <TextField label="City" name="city" disabled variant="outlined" value={city} />
                            </Box>
                            <TextField
                                variant="outlined"
                                label="Password"
                                name="password"
                                autoComplete="current-password"
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                data-testid="password"
                                required
                                error={Boolean(passwordError)}
                                helperText={passwordError}
                                onChange={event => {
                                    setPassword(event.target.value);
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title={showPassword ? 'Hide password' : 'Show password'}>
                                                <IconButton
                                                    edge="end"
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowPassword(!showPassword)}
                                                    data-testid="togglePasswordVisibility"
                                                    size="large"
                                                >
                                                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Typography>
                                Please find details about the storage of your personal data in our{' '}
                                <a href="https://www.mevis.de/en/data-privacy" target="_blank" rel="noreferrer">
                                    data privacy statement
                                </a>
                                .
                            </Typography>
                            <NonFieldErrors />
                        </Box>
                    </CardContent>
                    <CardActions>
                        <ActionButton
                            color="secondary"
                            iconClass={PersonAddIcon}
                            inProgress={requestInProgress}
                            disabled={email === '' || password === '' || company === '' || fullName === ''}
                            title="Create"
                            type="submit"
                            variant="contained"
                            data-testid="UserRegistration_Submit"
                        />
                        <LinkButton
                            iconClass={UndoIcon}
                            route={ROUTES.LOGIN}
                            title="Cancel"
                            data-testid="UserRegistration_Cancel"
                        />
                    </CardActions>
                </Card>
            </FormContainer>
        </PageContainer>
    );
}
