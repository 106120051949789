import { Box, Container } from '@mui/material';
import { makeStyles } from 'makeStyles';

export const useStyles = makeStyles()(theme => ({
    container: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
    },
}));

/**
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @param {string} [props.dataTestId]
 * @param {React.ComponentProps<Container>['maxWidth']} [props.maxWidth]
 * @param {number} [props.minWidth]
 * @param {string} [props.maxHeight='none']
 */
export function PageContainer({ children, dataTestId, maxWidth, minWidth, maxHeight = 'none', ...otherProps }) {
    const { classes } = useStyles();
    return (
        <Box
            data-testid={dataTestId}
            {...otherProps}
            flexGrow={1}
            display="flex"
            flexDirection="column"
            overflow="auto"
        >
            <Container className={classes.container} maxWidth={maxWidth} style={{ minWidth, maxHeight }}>
                {children}
            </Container>
        </Box>
    );
}
