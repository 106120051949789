import { useQuery } from '@tanstack/react-query';

import { HARDWARE_BINDING_NAME } from 'common/constants';

import { useUser } from './authorization';
import { get } from './axiosWrapper';
import { API_ENDPOINTS } from './endpoints';

export type HardwareBinding = {
    id: number;
    type: HARDWARE_BINDING_NAME;
    orderNumber: number;
    licenseGenerator: {
        id: number;
        name: string;
        allowFileDownload: boolean;
    };
};

export type Feature = {
    id: number;
    name: string;
    description: string;
    featureType: 'Feature' | 'ADK Feature' | 'Runtime';
    isApplicationFeature: boolean;
};

export type Product = {
    id: number;
    adk_expiration_date: string;
    adk_owner_id: string;
    allow_move_license: boolean;
    allow_additional_license_data: boolean;
    applicationFeatures: Feature[];
    use_no_of_concurrent_users: boolean;
    hardwareBindings: HardwareBinding[];
    has_max_version: boolean;
    features: Feature[];
    is_application: boolean;
    name: string;
    numberOfMacAddresses: number;
    use_owner_id: boolean;
    file_name_template: string;
    email_template: number;
};

export function loadProductList() {
    return get<Product[]>(`${API_ENDPOINTS.PRODUCT_LIST}`).then(res => res.data);
}

export function useProducts() {
    return useQuery({
        queryKey: ['Product', 'list'],
        queryFn: loadProductList,
        meta: { errorMessage: 'Loading products failed' },
    });
}

export function useCurrentProduct() {
    const { data: user } = useUser();
    // Store the selected product id in local storage as we want to keep it across tabs
    return useQuery({
        enabled: Boolean(user),
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: ['currentProduct'],
        select: data => {
            if (!data) {
                return null;
            }
            return {
                ...data,
                applicationFeatures: data?.is_application ? data.features.filter(f => f.isApplicationFeature) : [],
            };
        },
        queryFn: () =>
            user.products.find(p => p.id === Number(window.localStorage.getItem('currentProductId'))) ||
            user.products.find(p => p.id === user.default_product?.id) ||
            (user.products.length === 1 ? user.products[0] : null),
    });
}

export function setCurrentProductById(productId: number | string) {
    window.localStorage.setItem('currentProductId', productId?.toString());
}
