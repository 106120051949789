import React, { useEffect, useMemo } from 'react';

import { Box, Checkbox, FormControlLabel } from '@mui/material';

import { usePersistentState, useTableWithPersistedFilterSort } from 'common/hooks';

import { ClaimLicense } from 'commonComponents/ClaimLicense';
import { LinkButton } from 'commonComponents/LinkButton';
import { ObjectTable } from 'commonComponents/ObjectTable/ObjectTable';
import { MIN_WIDTH, useTableInteractionStyles } from 'commonComponents/ObjectTable/styles';
import { PageContainer } from 'commonComponents/PageContainer';
import { SelectProduct } from 'commonComponents/SelectProduct';

import { useUser } from 'api/authorization';
import { useProductConfigurations } from 'api/productConfigurations';
import { useCurrentProduct } from 'api/products';
import { CreateIcon } from 'icons/CreateIcon';
import { ROLE_DEFINITIONS } from 'roles/definitions';
import { RoleDependentComponent } from 'roles/RoleDependentComponent';
import { ROUTES } from 'routes/routes';

import { approxDaysForDuration, durationOrUnlimited } from './durationUnits';

function compareValidities(rowA, rowB, _id, _desc) {
    return (
        approxDaysForDuration(rowB.original.validity, rowB.original.validityUnit) -
        approxDaysForDuration(rowA.original.validity, rowA.original.validityUnit)
    );
}

function compareSupportValidities(rowA, rowB, _id, _desc) {
    return (
        approxDaysForDuration(rowB.original.supportValidity, rowB.original.supportValidityUnit) -
        approxDaysForDuration(rowA.original.supportValidity, rowA.original.supportValidityUnit)
    );
}

const columns = [
    { Header: 'Product Name', accessor: details => (details.product ? details.product.name : 'N/A') },
    { Header: 'Configuration Name', accessor: 'name' },
    {
        Header: 'Application Names',
        accessor: details => details.runtimeFeatures?.map(f => f.name).join(', ') || '',
    },
    {
        Header: 'Validity',
        accessor: details => durationOrUnlimited(details.validity, details.validityUnit),
        sortType: compareValidities,
    },
    {
        Header: 'Support Period',
        accessor: details => durationOrUnlimited(details.supportValidity, details.supportValidityUnit),
        sortType: compareSupportValidities,
    },
    { Header: 'Part No.', accessor: 'partNo' },
];

export function ConfigurationList() {
    const { data: currentProduct } = useCurrentProduct();
    const { data: user } = useUser();
    const userHasProduct = Boolean(user?.products?.length);
    const productIsSelected = Boolean(currentProduct);
    const hiddenColumns = currentProduct?.is_application ? [] : ['Application Names'];

    const defaultAdditionalFilters = useMemo(
        () => ({
            showInvalidatedConfigurations: false,
            product: currentProduct?.id,
        }),
        [currentProduct]
    );
    const [additionalFilters, persistAdditionalFilters] = usePersistentState(
        'productConfigurationAdditionalFilters',
        defaultAdditionalFilters
    );
    useEffect(() => {
        if (currentProduct && additionalFilters.product !== currentProduct.id) {
            persistAdditionalFilters(defaultAdditionalFilters);
        }
    }, [additionalFilters, currentProduct, defaultAdditionalFilters, persistAdditionalFilters]);

    const { data, isLoading } = useProductConfigurations(
        {
            product: currentProduct?.id,
            invalidated: additionalFilters.showInvalidatedConfigurations ? undefined : false,
        },
        Boolean(currentProduct)
    );
    const configurations = useMemo(() => data || [], [data]);
    const table = useTableWithPersistedFilterSort(
        {
            columns,
            data: configurations,
            initialState: {
                pageSize: 15,
                hiddenColumns,
            },
        },
        [{ id: 'Product Name', desc: false }],
        'configurationFilterSort'
    );

    function Buttons() {
        return (
            <Box display="flex" flexDirection="row" paddingLeft={5} justifyContent="stretch">
                <ConfigurationListFilters
                    showInvalidatedConfigurations={additionalFilters.showInvalidatedConfigurations}
                    setShowInvalidatedConfigurations={flag =>
                        persistAdditionalFilters({ ...additionalFilters, showInvalidatedConfigurations: flag })
                    }
                />
                <ConfigurationListActions />
            </Box>
        );
    }

    return (
        <PageContainer
            dataTestId="ConfigurationList_Container"
            minWidth={MIN_WIDTH.LIST}
            maxWidth={false}
            maxHeight="100%"
        >
            {!userHasProduct && <ClaimLicense />}
            {userHasProduct && !productIsSelected && <SelectProduct />}
            {userHasProduct && productIsSelected && (
                <ObjectTable historyPath={'/configuration/'} Buttons={Buttons} loading={isLoading} table={table} />
            )}
        </PageContainer>
    );
}

function ConfigurationListFilters({ showInvalidatedConfigurations, setShowInvalidatedConfigurations }) {
    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={showInvalidatedConfigurations}
                    onChange={event => setShowInvalidatedConfigurations(event.target.checked)}
                    name="showInvalidatedConfigurations"
                />
            }
            label="Show Invalid Product Configurations"
        />
    );
}

function ConfigurationListActions() {
    const { classes } = useTableInteractionStyles();
    return (
        <RoleDependentComponent
            userRole={ROLE_DEFINITIONS.LicenseManager}
            component={LinkButton}
            className={classes.button}
            iconClass={CreateIcon}
            route={ROUTES.CONFIGURATION_CREATE}
            title="Create"
        />
    );
}
