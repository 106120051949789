import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useLicenseGeneratorsVersion } from 'api/licenses';
import { useSettings } from 'api/settings';

export function AboutDialog({ open, onClose }) {
    const theme = useTheme();
    const { data: appSettings } = useSettings();
    const { data: licenseGeneratorsVersion } = useLicenseGeneratorsVersion();

    if (!appSettings || !licenseGeneratorsVersion) {
        return null;
    }
    const { softwareVersion, copyRightYear } = appSettings;

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md">
            <DialogTitle component="div">
                <Typography variant="h6">MeVis Varex Licensing</Typography>
                <Typography component="div" color="textSecondary" variant="caption">
                    Version: {softwareVersion}
                </Typography>

                {licenseGeneratorsVersion.map(generator => (
                    <Typography component="div" key={generator.name} color="textSecondary" variant="caption">
                        {generator.name}: {generator.version}
                    </Typography>
                ))}
            </DialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column', gridGap: theme.spacing(2) }}>
                <Box>
                    <Typography variant="subtitle1" style={{ fontWeight: '500' }}>
                        Copyright
                    </Typography>
                    <Typography display="inline">{copyRightYear} by </Typography>
                    <Typography display="inline">
                        <Link color="primary" target="_blank" href="https://www.mevis.de/" underline="hover">
                            MeVis Medical Solutions AG
                        </Link>
                    </Typography>
                    <Typography display="inline">, Bremen. All Rights Reserved.</Typography>
                </Box>
                <Box>
                    <Typography variant="subtitle1" style={{ fontWeight: '500' }}>
                        Contact
                    </Typography>
                    <Box>
                        <Typography display="inline">E-Mail: </Typography>
                        <Typography display="inline">
                            <Link color="primary" target="_blank" href="mailto:lms@mevis.de" underline="hover">
                                lms@mevis.de
                            </Link>
                        </Typography>
                    </Box>
                    <Box>
                        <Typography display="inline">Website: </Typography>
                        <Typography display="inline">
                            <Link
                                color="primary"
                                target="_blank"
                                href="https://www.mevis.de/en/imprint/"
                                underline="hover"
                            >
                                https://www.mevis.de/impressum
                            </Link>
                        </Typography>
                    </Box>
                </Box>

                <Box>
                    <Typography>
                        <Link
                            color="primary"
                            target="_blank"
                            href="https://www.mevis.de/en/data-privacy/"
                            underline="hover"
                        >
                            Data Privacy Statement
                        </Link>
                    </Typography>
                    <Typography>
                        <Link color="primary" target="_blank" href="https://www.mevis.de/en/imprint/" underline="hover">
                            Imprint / Impressum
                        </Link>
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}
