import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { makeStyles } from 'makeStyles';

import { ActionButton } from 'commonComponents/ActionButton';
import { AutocompleteElement } from 'commonComponents/AutocompleteElement';

import { loadCustomerList } from 'api/customers';
import { useCurrentProduct } from 'api/products';
import { CreateIcon } from 'icons/CreateIcon';
import { LicenseCreateCustomerDialog } from 'licenses/LicenseDetails/LicenseCardActions/LicenseCreateCustomerDialog';
import { getCustomerById, getCustomerLabel, getCustomerOwnerId, isApplication } from 'licenses/utils';
import { ROLE_DEFINITIONS } from 'roles/definitions';
import { RoleDependentComponent } from 'roles/RoleDependentComponent';

const useStyles = makeStyles()(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
    createButton: {
        marginRight: theme.spacing(-2),
        padding: `${theme.spacing(2)} 0`,
    },
}));

export function Customer({ onCustomerSelected, disabled = false }) {
    const { classes } = useStyles();
    const { data: currentProduct } = useCurrentProduct();
    const [createCustomerDialogOpen, setCreateCustomerDialogOpen] = useState(false);

    const { setValue, watch } = useFormContext();
    const [customer, product] = watch(['user', 'configuration.product']);

    const { data: customers, isLoading } = useQuery({
        enabled: Boolean(currentProduct),
        queryKey: ['Customer', 'list', { product: currentProduct?.id, invalidated: false }],
        queryFn: () => {
            return loadCustomerList({ product: currentProduct?.id, invalidated: false });
        },
        meta: { errorMessage: 'Loading customers failed.' },
    });

    const handleChange = (_, newValue) => {
        if (isApplication({ product }, currentProduct)) {
            setValue('ownerId', currentProduct.adk_owner_id, {
                shouldValidate: true,
                shouldDirty: true,
                shouldTouch: true,
            });
        } else {
            setValue('ownerId', getCustomerOwnerId(customers, newValue), {
                shouldValidate: true,
                shouldDirty: true,
                shouldTouch: true,
            });
        }

        onCustomerSelected?.(getCustomerById(customers, newValue));
    };

    return (
        <Box className={classes.container}>
            <AutocompleteElement
                name="user"
                label="Customer"
                testId="LicenseForm_CustomerSelect"
                required
                value={getCustomerById(customers, customer) === undefined ? null : customer}
                placeholder="Type or select a customer"
                options={customers?.map(c => c.id) || []}
                getOptionLabel={customerId => {
                    return getCustomerLabel(customers, customerId);
                }}
                onChangeCallback={handleChange}
                loading={isLoading}
                disabled={disabled}
            />
            {!disabled && (
                <>
                    <RoleDependentComponent
                        className={classes.createButton}
                        userRole={ROLE_DEFINITIONS.PrivilegedCustomer}
                        component={ActionButton}
                        onClick={() => setCreateCustomerDialogOpen(true)}
                        color="secondary"
                        iconClass={CreateIcon}
                        variant="text"
                        data-testid="LicenseForm_CustomerCreate"
                    />
                    <LicenseCreateCustomerDialog
                        open={createCustomerDialogOpen}
                        onClose={() => {
                            setCreateCustomerDialogOpen(false);
                        }}
                    />
                </>
            )}
        </Box>
    );
}
