import { useForm } from 'react-hook-form';
import { FormContainer } from 'react-hook-form-mui';
import { generatePath } from 'react-router';

import { CardActions } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import _ from 'lodash';
import { makeStyles } from 'makeStyles';

import { ActionButton } from 'commonComponents/ActionButton';
import { CommonFormComponents, NonFieldErrors } from 'commonComponents/CommonFormComponents';
import { LinkButton } from 'commonComponents/LinkButton';
import { LoadingIndicator } from 'commonComponents/LoadingIndicator';

import { useHandleFormError } from 'api/hooks/errorHandling';
import { ProductConfiguration } from 'api/productConfigurations';
import { useCurrentProduct } from 'api/products';
import { UndoIcon } from 'icons';
import { ROUTES } from 'routes/routes';

import { ConfigurationFormContents } from './ConfigurationFormContents';

const useStyles = makeStyles()(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        columnGap: theme.spacing(1),
        rowGap: theme.spacing(2),
        '& > *': {
            flex: '400px',
        },
    },
    formHelper: {
        flexGrow: 1,
        alignSelf: 'flex-end',
        textAlign: 'end',
    },
}));

export function ConfigurationForm({
    cancelButtonRoute,
    history,
    initialValues,
    submitApiCall,
    submitButtonIconClass,
    submitButtonText,
    submitErrorMessage,
    predefinedFeatures = null,
}) {
    const { classes } = useStyles();
    const { data: currentProduct } = useCurrentProduct();
    // If externalFormContext is set, we create this, but ignore it
    const formContext = useForm({ defaultValues: initialValues });
    const handleFormError = useHandleFormError(formContext);

    const productFeatures =
        currentProduct?.features?.filter(f => f.featureType === 'Feature' || f.featureType === 'ADK Feature') || [];
    const configurationFeatures =
        initialValues?.features?.filter(f => f.featureType === 'Feature' || f.featureType === 'ADK Feature') || [];
    const availableFeatures = _.unionBy(productFeatures, configurationFeatures, 'id');

    const { mutate: trigger, isLoading: requestInProgress } = useMutation({
        mutationFn: (values: any) =>
            submitApiCall({
                ...values,
                features: values.features.map(f => f.id),
                product: values.product.id,
                validity: values.validity || null,
                supportValidity: values.supportValidity || null,
                maximumActivations: values.maximumActivations || null,
                maxNumberOfConcurrentUsers:
                    values.maxNumberOfConcurrentUsers != null && values.maxNumberOfConcurrentUsers !== ''
                        ? values.maxNumberOfConcurrentUsers
                        : null,
            }),
        onSuccess: (response: ProductConfiguration, values) => {
            formContext.reset(values);
            history.push(generatePath(ROUTES.CONFIGURATION_DETAIL, { id: response.id }));
        },
        onError: handleFormError,
        meta: { errorMessage: submitErrorMessage },
    });

    if (!currentProduct) {
        return <LoadingIndicator />;
    }

    return (
        <FormContainer formContext={formContext} onSuccess={values => trigger(values)}>
            <CommonFormComponents />
            <ConfigurationFormContents
                currentProduct={currentProduct}
                availableFeatures={predefinedFeatures || availableFeatures}
                showInternal
                showMaxConcurrent={currentProduct.use_no_of_concurrent_users}
            />
            {!predefinedFeatures && !initialValues.isAdhocConfiguration && (
                <CardActions>
                    <ActionButton
                        color="secondary"
                        iconClass={submitButtonIconClass}
                        inProgress={requestInProgress}
                        title={submitButtonText}
                        type="submit"
                        variant="contained"
                    />
                    <LinkButton iconClass={UndoIcon} route={cancelButtonRoute} title="Cancel" />
                    <NonFieldErrors className={classes.formHelper} />
                </CardActions>
            )}
        </FormContainer>
    );
}
