import _ from 'lodash';

import { AutocompleteElement } from 'commonComponents/AutocompleteElement';

export function Product({ allProducts }) {
    function getProductLabel(product) {
        return `${product.name}`;
    }

    return (
        <AutocompleteElement
            label="Products"
            name="products"
            testId="UserForm_ProductsSelect"
            options={allProducts}
            isOptionEqualToValue={(option, value) => {
                return _.isEqual(option, value);
            }}
            autoComplete={false}
            multiple
            disableCloseOnSelect
            placeholder="Type or select a product"
            getOptionLabel={product => {
                return getProductLabel(product);
            }}
        />
    );
}
