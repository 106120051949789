import { QueryClient } from '@tanstack/react-query';

import { destroy, get, post, put } from './axiosWrapper';
import { replaceObjectInReactQueryCache } from './cache';
import { API_ENDPOINTS } from './endpoints';
import { Product } from './products';

export type UserDetails = {
    address: string;
    city: string;
    comment: string;
    company: string;
    country: string;
    date_joined: string;
    default_product: Product | null;
    default_screen: 'D' | 'L';
    email: string;
    full_name: string;
    groups: string[];
    hasLicenses: boolean;
    id: number;
    invalidated: boolean;
    is_active: boolean;
    is_internal: boolean;
    owner_id: string;
    phone_number: string;
    products: Product[];
    userType: 'Administrator' | 'License Manager' | 'Privileged Customer' | 'Customer' | 'N/A';
    username: string;
};

type PostUserDetails = Pick<
    UserDetails,
    | 'address'
    | 'city'
    | 'comment'
    | 'company'
    | 'default_screen'
    | 'email'
    | 'full_name'
    | 'groups'
    | 'invalidated'
    | 'is_internal'
    | 'is_active'
    | 'owner_id'
    | 'phone_number'
    | 'username'
> & {
    default_product: number;
    products: number[];
};

export function createUser(payload: Partial<PostUserDetails>) {
    return post<UserDetails>(API_ENDPOINTS.USER_CREATE, payload);
}

export function registerUser(payload) {
    return post(API_ENDPOINTS.USER_REGISTER, payload).then(res => res.data);
}

export function deleteUser(id: number) {
    return destroy(API_ENDPOINTS.USER_DETAIL, id).then(res => res.data);
}

export function confirmUser(token) {
    return post(`${API_ENDPOINTS.USER_CONFIRM}?token=${token}`).then(res => res.data);
}

export function resendValidationEmail(username) {
    return post(`${API_ENDPOINTS.USER_RESEND}${username}`);
}

export function welcomeUser(id, payload) {
    return post(`${API_ENDPOINTS.USER_WELCOME}${id}/welcome/`, payload);
}

export function updateUser(id: number, payload: Partial<PostUserDetails>) {
    return put<UserDetails>(API_ENDPOINTS.USER_DETAIL, id, payload).then(res => res.data);
}

export function loadUserList() {
    return get<UserDetails[]>(`${API_ENDPOINTS.USER_LIST}`).then(res => res.data);
}

export function loadUserDetails(userId: number) {
    return get<UserDetails>(`${API_ENDPOINTS.USER_DETAIL}${userId}`).then(res => res.data);
}

export function mutateUser(queryClient: QueryClient, user: UserDetails) {
    replaceObjectInReactQueryCache(queryClient, user, 'User');
}
