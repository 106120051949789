import { useState } from 'react';

import { Card, CardContent, CardHeader, Collapse, Divider, IconButton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { makeStyles } from 'makeStyles';

import { HistoryList } from 'commonComponents/HistoryList';
import { LoadingIndicator } from 'commonComponents/LoadingIndicator';

import { loadHistory } from 'api/licenses';
import { ExpandLessIcon, ExpandMoreIcon } from 'icons';
import { CircularProgressIcon } from 'icons/CircularProgressIcon';

const useStyles = makeStyles()(theme => ({
    card: {
        marginTop: theme.spacing(1),
    },
    cardContent: {
        paddingTop: 0,
        '&:last-child': {
            paddingBottom: 0,
        },
    },
    cardHeader: {
        cursor: 'pointer',
    },
}));

function HistoryCard({ open, entries }) {
    const { classes } = useStyles();
    return (
        <Collapse in={open} timeout="auto" unmountOnExit>
            <Divider />
            <CardContent className={classes.cardContent}>
                <HistoryList entries={entries} modelName="license" />
            </CardContent>
        </Collapse>
    );
}
export function LicenseHistory({ licenseId }: { licenseId: number }) {
    const { classes } = useStyles();
    const [open, setOpen] = useState(false);

    const { data: entries, isLoading } = useQuery({
        queryKey: ['LicenseHistory', 'list', licenseId],
        queryFn: () => loadHistory(licenseId),
        meta: { errorMessage: 'Loading license history failed' },
    });

    return (
        <Card className={classes.card}>
            <CardHeader
                onClick={() => setOpen(!isLoading && !open)}
                subheader="History"
                action={
                    <IconButton size="large">
                        {isLoading ? <CircularProgressIcon /> : open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                }
                className={classes.cardHeader}
                data-testid="LicenseHistory_CardHeader"
            >
                <LoadingIndicator />
            </CardHeader>
            {!isLoading && <HistoryCard open={open} entries={entries} />}
        </Card>
    );
}
