import { LinkButton } from 'commonComponents/LinkButton';

import { UndoIcon } from 'icons';
import { ROUTES } from 'routes/routes';

export function LicenseDetailsBackButton() {
    return (
        <LinkButton iconClass={UndoIcon} route={ROUTES.LICENSE_LIST} title="Back" data-testid="LicenseDetails_Back" />
    );
}
