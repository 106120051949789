import { type LinkProps, Link } from 'react-router-dom';

import { Button } from '@mui/material';
import { makeStyles } from 'makeStyles';

const useStyles = makeStyles()(theme => ({
    icon: {
        marginRight: theme.spacing(1),
    },
}));

type LinkButtonProps = {
    iconClass?: (...props: any) => JSX.Element;
    route: string;
    title: string;
};

export function LinkButton({
    iconClass,
    size,
    title,
    route,
    variant = 'contained',
    ...other
}: React.ComponentProps<typeof Button> & Partial<LinkProps> & LinkButtonProps) {
    const { classes } = useStyles();
    const Icon = iconClass;
    return (
        <Button color="primary" component={Link} size={size || 'large'} to={route} variant={variant} {...other}>
            {iconClass && <Icon className={classes.icon} />}
            {title}
        </Button>
    );
}
