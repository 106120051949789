import { TextFieldElement } from 'react-hook-form-mui';

import { buildHexMaskInput } from 'commonComponents/HexMaskInput';

const MAC_ADDRESS_REGEX = /^(([A-Fa-f0-9]{2}[:]){5}[A-Fa-f0-9]{2}[,]?)+$/i;
const InputMask = buildHexMaskInput('00:00:00:00:00:00');

export function MacAddress({ index, disabled, required, defaultProps }) {
    const fieldName = `mac_address_${index}`;

    return (
        <TextFieldElement
            data-testid="LicenseForm_MacAddress"
            InputProps={{ inputComponent: InputMask }}
            InputLabelProps={{ shrink: true }}
            label={`MAC address ${index + 1}`}
            name={fieldName}
            {...{ required, disabled }}
            validation={{ pattern: MAC_ADDRESS_REGEX }}
            parseError={error => {
                if (error.message) {
                    return error.message;
                }
                if (error.type === 'pattern') {
                    return 'Invalid MAC Address';
                }
                return 'Unknown error';
            }}
            {...defaultProps}
        />
    );
}
