import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';

const directionLookup = {
    true: 'desc',
    false: 'asc',
};

/**
 * @param {Object} props
 * @param {import('react-table').TableInstance} props.table
 */
export function ObjectTableHeader({ table }) {
    return (
        <TableHead>
            <TableRow>
                {table.visibleColumns.map(column => {
                    return (
                        <TableCell
                            {...column.getHeaderProps(column.getSortByToggleProps?.())}
                            style={{ whiteSpace: 'nowrap' }}
                        >
                            <TableSortLabel active={column.isSorted} direction={directionLookup[column.isSortedDesc]}>
                                {column.render('Header')}
                            </TableSortLabel>
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
}
