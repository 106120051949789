import { useEffect, useMemo } from 'react';
import { generatePath } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';

import { Card, CardActions, CardContent, Table, TableBody } from '@mui/material';
import _ from 'lodash';
import { makeStyles } from 'makeStyles';
import ShortId from 'shortid';

import { usePrevious } from 'common/hooks';

import { DetailsCardTitle } from 'commonComponents/DetailsCardTitle';
import { LinkButton } from 'commonComponents/LinkButton';
import { LoadingIndicator } from 'commonComponents/LoadingIndicator';
import { cellValue } from 'commonComponents/ObjectTable/ObjectTableContext';
import { PageContainer } from 'commonComponents/PageContainer';
import { TableRow } from 'commonComponents/TableRow';

import { useProductConfiguration } from 'api/productConfigurations';
import { useCurrentProduct } from 'api/products';
import { EditIcon, UndoIcon } from 'icons';
import { getNumberOfMacAddresses } from 'licenses/utils';
import { ROLE_DEFINITIONS } from 'roles/definitions';
import { RoleDependentComponent } from 'roles/RoleDependentComponent';
import { ROUTES } from 'routes/routes';

import { DeleteOrInvalidateConfiguration } from './DeleteOrInvalidateConfiguration';
import { durationOrUnlimited } from './durationUnits';

const useStyles = makeStyles()(() => ({
    featuresCell: {
        maxHeight: '20vh',
        overflow: 'auto',
    },
}));

export function ConfigurationDetails() {
    const { classes } = useStyles();
    const { id: definitionId } = useParams<{ id: string }>();
    const { data: currentProduct } = useCurrentProduct();
    const previousProduct = usePrevious(currentProduct);
    const history = useHistory();

    const ROW_DATA_MAPPER = useMemo(
        () => [
            {
                title: 'Name',
                accessor: 'name',
            },
            {
                title: 'Part No.',
                accessor: 'partNo',
            },
            {
                title: 'Validity',
                accessor: details => durationOrUnlimited(details.validity, details.validityUnit),
            },
            {
                title: 'Support Period',
                accessor: details => durationOrUnlimited(details.supportValidity, details.supportValidityUnit),
            },
            {
                title: 'Maximum Number of Activations',
                accessor: details => (details.maximumActivations ? details.maximumActivations : 'unlimited'),
            },
            {
                title: 'Hardware Binding',
                accessor: details =>
                    previousProduct?.hardwareBindings?.find(({ id }) => id === details?.hardwareBinding)?.type,
            },
            {
                title: 'Maximum Number of Mac Addresses',
                accessor: details => getNumberOfMacAddresses(details),
            },
            {
                title: 'Maximum Number of Concurrent Users',
                accessor: details =>
                    details.maxNumberOfConcurrentUsers ? details.maxNumberOfConcurrentUsers : 'unlimited',
                hide: details => !details.product.use_no_of_concurrent_users,
            },
            {
                title: 'Serial Number',
                accessor: 'hasSerialNumber',
            },
            {
                title: 'Features',
                accessor: details => details.features?.map(f => f.name).join(', ') || '',
                pre: true,
                classes: 'featuresCell',
            },
            {
                title: 'Application Names',
                accessor: details => details.runtimeFeatures?.map(f => f.name).join(', ') || '',
                pre: true,
                classes: 'featuresCell',
                hide: details => !details.product.is_application,
            },
            {
                title: 'Max Version',
                accessor: 'max_version',
                hide: details => !details.product.has_max_version,
            },
            { title: 'Comment', accessor: 'comment' },
            {
                title: 'Additional License Data',
                accessor: 'additionalLicenseData',
                hide: details => !details.product.allow_additional_license_data,
            },
            {
                title: 'Invalidated',
                accessor: 'invalidated',
                hide: license => !license.invalidated,
                testId: 'invalidated',
            },
        ],
        [previousProduct?.hardwareBindings]
    );

    const { data: details, isLoading: isFetching } = useProductConfiguration(Number(definitionId));

    useEffect(() => {
        if (previousProduct && !_.isEqual(previousProduct, currentProduct)) {
            history.push(ROUTES.CONFIGURATION_LIST);
        }
    }, [currentProduct, previousProduct, history]);

    if (isFetching || !details) {
        return <LoadingIndicator />;
    }

    const rowData = ROW_DATA_MAPPER.filter(rowData => rowData.hide == null || !rowData.hide(details)).map(rowData => {
        let value = cellValue(details, rowData);
        value = details.pre ? <pre className={classes[details.classes]}>{value}</pre> : value;
        return <TableRow key={ShortId.generate()} title={rowData.title} value={value} />;
    });

    return (
        <PageContainer maxWidth="md">
            <Card>
                <CardContent data-testid="ConfigurationDetails_CardContent">
                    <DetailsCardTitle
                        subtitle={`Configuration${details.internal ? ' [Only for internal use]' : ''}`}
                        title={details.name}
                        showError={details.internal}
                    />
                    <Table size="small">
                        <TableBody>{rowData}</TableBody>
                    </Table>
                </CardContent>
                <CardActions>
                    {!details.invalidated && (
                        <>
                            <RoleDependentComponent
                                userRole={ROLE_DEFINITIONS.LicenseManager}
                                component={LinkButton}
                                iconClass={EditIcon}
                                route={generatePath(ROUTES.CONFIGURATION_EDIT, { id: definitionId })}
                                title="Edit"
                                data-testid="EditConfig_Button"
                            />
                            <DeleteOrInvalidateConfiguration configuration={details} />
                        </>
                    )}
                    <LinkButton iconClass={UndoIcon} route={ROUTES.CONFIGURATION_LIST} title="Back" />
                </CardActions>
            </Card>
        </PageContainer>
    );
}
