import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';

import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { makeStyles } from 'makeStyles';

import { usePrevious } from 'common/hooks';

import { ActionButton } from 'commonComponents/ActionButton';
import { NonFieldErrors } from 'commonComponents/CommonFormComponents';

import { useHandleFormError } from 'api/hooks/errorHandling';
import { invalidateLicense } from 'api/licenses';
import { DeleteIcon, UndoIcon } from 'icons';

const initialValues = { reason: '' };

const useStyles = makeStyles()(theme => ({
    inputField: {
        marginTop: theme.spacing(1),
        width: '100%',
    },
}));

export function LicenseInvalidateDialog({ licenseId, open, onClose }) {
    const { classes } = useStyles();
    const formContext = useForm({ defaultValues: initialValues });
    const {
        formState: { errors },
    } = formContext;

    const handleFormError = useHandleFormError(formContext);
    const queryClient = useQueryClient();
    const {
        mutate: trigger,
        isLoading: isRequesting,
        reset,
    } = useMutation({
        mutationFn: (values: { reason: string }) => invalidateLicense({ licenseId, reason: values.reason }),
        onSuccess: () => {
            queryClient.invalidateQueries(['License', 'list']);
            queryClient.invalidateQueries(['License', 'detail', licenseId]);
            queryClient.invalidateQueries(['LicenseHistory', 'list', licenseId]);
        },
        onError: handleFormError,
        meta: { errorMessage: 'Invalidating license failed' },
    });

    const wasRequesting = usePrevious(isRequesting);
    useEffect(() => {
        if (!isEmpty(errors)) return;
        if (!isRequesting && wasRequesting) {
            onClose();
        }
    }, [errors, isRequesting, onClose, wasRequesting]);

    // Reset error when opening or closing the dialog
    useEffect(reset, [open, reset]);

    const text = 'Are you sure you want to invalidate this license?';

    return (
        <Dialog {...{ open, onClose }} data-testid="LicenseInvalidateDialog">
            <DialogTitle>Invalidate License</DialogTitle>
            <FormContainer formContext={formContext} onSuccess={values => trigger(values)}>
                <DialogContent>
                    <Typography>{text}</Typography>
                    <TextFieldElement
                        label="Reason"
                        name="reason"
                        required
                        autoComplete="off"
                        variant="outlined"
                        className={classes.inputField}
                        data-testid="InvalidateLicense_ReasonInput"
                        inputProps={{ role: 'textbox' }}
                    />
                    <NonFieldErrors />
                </DialogContent>
                <DialogActions>
                    <ActionButton
                        color="secondary"
                        iconClass={DeleteIcon}
                        inProgress={isRequesting}
                        title=" Invalidate"
                        variant="contained"
                        type="submit"
                        data-testid="InvalidateLicense_InvalidateButton"
                    />
                    <ActionButton
                        color="primary"
                        iconClass={UndoIcon}
                        inProgress={false}
                        onClick={onClose}
                        title="Cancel"
                        variant="contained"
                        data-testid="InvalidateLicense_CancelButton"
                    />
                </DialogActions>
            </FormContainer>
        </Dialog>
    );
}
