import { useForm } from 'react-hook-form';
import { FormContainer } from 'react-hook-form-mui';
import { generatePath } from 'react-router';
import { useHistory } from 'react-router-dom';

import { Card, CardActions, CardHeader } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ActionButton } from 'commonComponents/ActionButton';
import { CommonFormComponents } from 'commonComponents/CommonFormComponents';
import { LinkButton } from 'commonComponents/LinkButton';
import { PageContainer } from 'commonComponents/PageContainer';

import { useUser } from 'api/authorization';
import { createCustomer, CustomerDetails } from 'api/customers';
import { useHandleFormError } from 'api/hooks/errorHandling';
import { useCurrentProduct } from 'api/products';
import { UndoIcon } from 'icons';
import { ROLE_DEFINITIONS } from 'roles/definitions';
import { useCurrentUserHasRole } from 'roles/helpers';
import { ROUTES } from 'routes/routes';

import { CreateIcon } from '../icons/CreateIcon';
import { CustomerFormContent, CustomerFormContentProps, CustomerFormFields } from './CustomerFormContent';

const INITIAL_VALUES: CustomerFormFields = {
    full_name: '',
    address: '',
    city: '',
    comment: '',
    company: '',
    country: '',
    email: '',
    is_active: false,
    phone_number: '',
    owner_id: '',
    products: [],
};

export function CreateCustomer() {
    const history = useHistory();
    const { data: currentProduct } = useCurrentProduct();
    const { data: currentUser } = useUser();
    const currentUserHasRole = useCurrentUserHasRole();
    const userIsLicenseManager = currentUserHasRole(ROLE_DEFINITIONS.LicenseManager);
    const formContext = useForm({
        defaultValues: {
            ...INITIAL_VALUES,
            products: currentProduct ? [currentProduct] : [],
            owner_id: userIsLicenseManager ? '' : currentUser.owner_id || '',
        },
    });
    const handleFormError = useHandleFormError(formContext);

    const queryClient = useQueryClient();
    const { mutate: trigger, isLoading: requestInProgress } = useMutation({
        mutationFn: (values: CustomerFormFields) =>
            createCustomer({
                ...values,
                username: values.email,
                products: values.products?.map(p => p.id),
                groups: ['Customer'],
            }),
        onSuccess: (response, values) => {
            queryClient.setQueriesData<CustomerDetails[]>(['Customer', 'list'], oldList => [response, ...oldList]);
            formContext.reset(values);
            const customerDetailRoute = generatePath(ROUTES.CUSTOMER_DETAIL, { id: response.id });
            if (response.is_active) {
                history.push(
                    `${generatePath(ROUTES.USER_EMAIL, { id: response.id })}?${new URLSearchParams({
                        returnPath: customerDetailRoute,
                    })}`
                );
            } else {
                history.push(customerDetailRoute);
            }
            return response;
        },
        onError: handleFormError,
        meta: { errorMessage: 'Creating customer failed' },
    });

    const defaultProps: CustomerFormContentProps['defaultProps'] = {
        autoComplete: 'off',
        variant: 'outlined',
        margin: 'none',
    };

    return (
        <PageContainer maxWidth="md">
            <FormContainer formContext={formContext} onSuccess={data => trigger(data)}>
                <CommonFormComponents />
                <Card>
                    <CardHeader title="Create new customer" />
                    <CustomerFormContent
                        defaultProps={defaultProps}
                        form={formContext}
                        showIsActive={userIsLicenseManager}
                    />
                    <CardActions>
                        <ActionButton
                            color="secondary"
                            iconClass={CreateIcon}
                            inProgress={requestInProgress}
                            title="Create"
                            type="submit"
                            variant="contained"
                        />
                        <LinkButton
                            iconClass={UndoIcon}
                            route={ROUTES.CUSTOMER_LIST}
                            title="Cancel"
                            data-testid="CreateCustomer_Cancel"
                        />
                    </CardActions>
                </Card>
            </FormContainer>
        </PageContainer>
    );
}
