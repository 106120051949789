import { useState } from 'react';

import { Card, CardContent, CardHeader, Collapse, Divider, IconButton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { makeStyles } from 'makeStyles';

import { HistoryList } from 'commonComponents/HistoryList';
import { LoadingIndicator } from 'commonComponents/LoadingIndicator';

import { loadCustomerHistory } from 'api/customers';
import { ExpandLessIcon, ExpandMoreIcon } from 'icons';
import { CircularProgressIcon } from 'icons/CircularProgressIcon';
import { ROUTES } from 'routes/routes';

const useStyles = makeStyles()(() => ({
    cardContent: {
        paddingTop: 0,
        '&:last-child': {
            paddingBottom: 0,
        },
    },
    cardHeader: {
        cursor: 'pointer',
    },
}));

function HistoryCard({ open, entries }) {
    const { classes } = useStyles();

    return (
        <Collapse in={open} timeout="auto" unmountOnExit>
            <Divider />
            <CardContent className={classes.cardContent}>
                <HistoryList entries={entries} modelName="customer" revertUrl={ROUTES.CUSTOMER_REVERT} />
            </CardContent>
        </Collapse>
    );
}
export function CustomerHistory({ customerId }: { customerId: number }) {
    const { classes } = useStyles();
    const [open, setOpen] = useState(false);

    const { data: entries, error } = useQuery({
        queryKey: ['CustomerHistory', 'list', customerId],
        queryFn: () => loadCustomerHistory(customerId),
        meta: { errorMessage: 'Loading customer history failed' },
    });

    if (error) {
        return null;
    }

    const fetching = !entries && !error;

    return (
        <Card>
            <CardHeader
                onClick={() => setOpen(!fetching && !open)}
                subheader="History"
                action={
                    <IconButton size="large">
                        {fetching ? <CircularProgressIcon /> : open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                }
                className={classes.cardHeader}
                data-testid="CustomerHistory_CardHeader"
            >
                <LoadingIndicator />
            </CardHeader>
            {!fetching && <HistoryCard open={open} entries={entries} />}
        </Card>
    );
}
