import { useForm } from 'react-hook-form';
import { FormContainer } from 'react-hook-form-mui';
import { generatePath } from 'react-router';
import { useHistory } from 'react-router-dom';

import { Card, CardActions, CardHeader } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';

import { ActionButton } from 'commonComponents/ActionButton';
import { CommonFormComponents } from 'commonComponents/CommonFormComponents';
import { LinkButton } from 'commonComponents/LinkButton';
import { PageContainer } from 'commonComponents/PageContainer';

import { useHandleFormError } from 'api/hooks/errorHandling';
import { EditIcon, UndoIcon } from 'icons';
import { ROLE_DEFINITIONS } from 'roles/definitions';
import { useCurrentUserHasRole } from 'roles/helpers';
import { ROUTES } from 'routes/routes';

import { mutateCustomer, updateCustomer } from '../api/customers';
import { CustomerFormContent, CustomerFormContentProps, CustomerFormFields } from './CustomerFormContent';

export function EditCustomer({ customer }: { customer: import('../api/customers').CustomerDetails }) {
    const history = useHistory();
    const currentUserHasRole = useCurrentUserHasRole();
    const formContext = useForm<CustomerFormFields>({
        defaultValues: _.pick(customer, [
            'username',
            'groups',
            'address',
            'city',
            'comment',
            'company',
            'country',
            'email',
            'full_name',
            'is_active',
            'owner_id',
            'phone_number',
            'products',
        ]),
    });
    const handleFormError = useHandleFormError(formContext);

    const queryClient = useQueryClient();
    const { mutate: trigger, isLoading: requestInProgress } = useMutation({
        mutationFn: (values: CustomerFormFields) =>
            updateCustomer(customer.id, {
                ...customer,
                ...values,
                default_product: customer.default_product?.id,
                products: values.products?.map(p => p.id),
            }),
        onSuccess: (response, values) => {
            mutateCustomer(queryClient, response);
            formContext.reset(values);
            const customerDetailRoute = generatePath(ROUTES.CUSTOMER_DETAIL, {
                id: response.id,
            });
            if (!customer.is_active && values.is_active) {
                history.push(
                    `${generatePath(ROUTES.USER_EMAIL, {
                        id: response.id,
                    })}?${new URLSearchParams({
                        returnPath: customerDetailRoute,
                    })}`
                );
            } else {
                history.push(customerDetailRoute);
            }
        },
        onError: handleFormError,
        meta: { errorMessage: 'Customer update failed' },
    });

    const defaultProps: CustomerFormContentProps['defaultProps'] = {
        autoComplete: 'off',
        variant: 'outlined',
        margin: 'none',
    };

    return (
        <PageContainer maxWidth="md">
            <FormContainer formContext={formContext} onSuccess={data => trigger(data)} data-testid="EditCustomer_form">
                <CommonFormComponents />
                <Card>
                    <CardHeader title="Edit Customer" />
                    <CustomerFormContent
                        defaultProps={defaultProps}
                        form={formContext}
                        showIsActive={currentUserHasRole(ROLE_DEFINITIONS.LicenseManager)}
                    />
                    <CardActions>
                        <ActionButton
                            color="secondary"
                            iconClass={EditIcon}
                            inProgress={requestInProgress}
                            title="Submit"
                            type="submit"
                            variant="contained"
                        />
                        <LinkButton
                            iconClass={UndoIcon}
                            route={generatePath(ROUTES.CUSTOMER_DETAIL, {
                                id: customer.id,
                            })}
                            title="Cancel"
                        />
                    </CardActions>
                </Card>
            </FormContainer>
        </PageContainer>
    );
}
