import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';

import { Card, CardActions, CardContent, CardHeader } from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import { ActionButton } from 'commonComponents/ActionButton';
import { LinkButton } from 'commonComponents/LinkButton';

import { useHandleFormError } from 'api/hooks/errorHandling';
import { requestPasswordReset } from 'api/passwordReset';
import { EmailIcon, UndoIcon } from 'icons';
import { ROUTES } from 'routes/routes';

import { RequestNewPasswordSuccess } from './RequestNewPasswordSuccess';

export function ResetPasswordForm({ isRequesting }) {
    const formContext = useForm({ defaultValues: { email: '' } });
    const [requestNewPasswordSuccess, setRequestNewPasswordSuccess] = useState(false);
    const handleFormError = useHandleFormError(formContext);
    const { reset } = formContext;
    const { mutate } = useMutation({
        mutationFn: requestPasswordReset,
        onSuccess: () => {
            setRequestNewPasswordSuccess(true);
            return reset;
        },
        onError: handleFormError,
        meta: { errorMessage: 'Resetting password failed' },
    });

    if (requestNewPasswordSuccess) {
        return <RequestNewPasswordSuccess />;
    }
    return (
        <FormContainer formContext={formContext} onSuccess={values => mutate(values)}>
            <Card>
                <CardHeader title="Reset Password" />
                <CardContent>
                    <TextFieldElement fullWidth variant="outlined" name="email" label="Email" type="email" required />
                </CardContent>
                <CardActions>
                    <ActionButton
                        color="secondary"
                        iconClass={EmailIcon}
                        inProgress={isRequesting}
                        title="Reset Password"
                        type="submit"
                        variant="contained"
                    />
                    <LinkButton iconClass={UndoIcon} route={ROUTES.LOGIN} title="Cancel" />
                </CardActions>
            </Card>
        </FormContainer>
    );
}
