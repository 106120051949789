import { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { Card, CardContent, Table, TableBody } from '@mui/material';
import _ from 'lodash';

import { usePrevious } from 'common/hooks';

import { DetailsCardTitle } from 'commonComponents/DetailsCardTitle';
import { LoadingIndicator } from 'commonComponents/LoadingIndicator';
import { PageContainer } from 'commonComponents/PageContainer';

import { useLicense } from 'api/licenses';
import { useCurrentProduct } from 'api/products';
import { useBasicRowDataMap } from 'licenses/licenseDetails';
import { LicenseFiles } from 'licenses/LicenseFiles/LicenseFiles';
import { ROLE_DEFINITIONS } from 'roles/definitions';
import { RoleDependentComponent } from 'roles/RoleDependentComponent';
import { ROUTES } from 'routes/routes';

import { LicenseCardActions } from './LicenseCardActions/LicenseCardActions';
import { LicenseDetailsRow } from './LicenseDetailsRow';
import { LicenseHistory } from './LicenseHistory';

export function LicenseDetails() {
    const { id: licenseId } = useParams<{ id: string }>();
    const { data: currentProduct } = useCurrentProduct();
    const previousProduct = usePrevious(currentProduct);
    const { data: details, isLoading: isFetchingLicenseDetails } = useLicense(Number(licenseId));
    const history = useHistory();
    const location = useLocation<{ showLicenseFiles: boolean }>();

    useEffect(() => {
        if (previousProduct && !_.isEqual(previousProduct, currentProduct)) {
            history.push(ROUTES.LICENSE_LIST);
        }
    }, [currentProduct, previousProduct, history]);

    if (isFetchingLicenseDetails || !details) {
        return <LoadingIndicator />;
    }

    return (
        <PageContainer maxWidth="lg" data-testid="LicenseDetails">
            <Card>
                <LicenseCardContent details={details} />
                <LicenseCardActions licenseDetails={details} />
            </Card>
            <LicenseFiles license={details} initiallyOpen={location.state?.showLicenseFiles ?? false} />
            <RoleDependentComponent
                userRole={ROLE_DEFINITIONS.LicenseManager}
                component={LicenseHistory}
                licenseId={Number(licenseId)}
            />
        </PageContainer>
    );
}

export function LicenseCardContent({ details }) {
    const showError = details.isExpired || details.internal;
    const rowDataMap = useBasicRowDataMap(details?.configuration || null);

    return (
        <CardContent>
            <DetailsCardTitle
                subtitle={`License${details.isExpired ? ' [EXPIRED]' : ''} ${
                    details.internal ? '[Only for internal use]' : ''
                }`}
                title={details.configuration?.name || ''}
                showError={showError}
                dataTestId="LicenseDetails"
            />
            <Table size="small">
                <TableBody>
                    {rowDataMap.map(rowConfig => (
                        <LicenseDetailsRow key={rowConfig.title} rowConfig={rowConfig} details={details} />
                    ))}
                </TableBody>
            </Table>
        </CardContent>
    );
}
