import { DATE_FORMAT } from 'common/constants';

import { KeyboardDatePickerElement } from 'commonComponents/KeyboardDatePickerElement';

export function SupportExpirationDate({ disabled = false }) {
    return (
        <KeyboardDatePickerElement
            label={'Support Expiration Date (' + DATE_FORMAT + ')'}
            name="support_expiration_date"
            margin="none"
            slotProps={{ textField: { InputLabelProps: { shrink: true }, placeholder: 'unlimited' } }}
            disabled={disabled}
        />
    );
}
