import { generatePath } from 'react-router/cjs/react-router.min';

import { Undo as UndoIcon } from '@mui/icons-material';
import { CardActions } from '@mui/material';
import { object } from 'prop-types';

import { LinkButton } from 'commonComponents/LinkButton';

import { ROUTES } from 'routes/routes';

import { LicenseFileDeactivateButton } from './LicenseFileDeactivateButton';
import { LicenseFileDownloadButton } from './LicenseFileDownloadButton';
import { LicenseFileEditButton } from './LicenseFileEditButton';
import { LicenseFileEmailButton } from './LicenseFileEmailButton';
import { LicenseFileMoveButton } from './LicenseFileMoveButton';

export function LicenseFileActions({ licenseFile, license }) {
    return (
        <CardActions>
            <LicenseFileDownloadButton {...{ licenseFile, license }} />
            <LicenseFileEmailButton {...{ licenseFile, license }} />
            <LicenseFileEditButton {...{ licenseFile, license }} />
            {!licenseFile.deactivated && (
                <LicenseFileMoveButton
                    license={license}
                    licenseFileIds={[licenseFile.id]}
                    tooltip="Move license file to another license"
                />
            )}
            <LicenseFileDeactivateButton {...{ licenseFile, license }} />
            <LinkButton
                iconClass={UndoIcon}
                route={generatePath(ROUTES.LICENSE_DETAIL, { id: licenseFile.license })}
                title="Back"
            />
        </CardActions>
    );
}

LicenseFileActions.propTypes = {
    licenseFile: object.isRequired,
    license: object.isRequired,
};
