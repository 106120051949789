import { useState } from 'react';

import { ActionButton } from 'commonComponents/ActionButton';

import { type UserDetails } from 'api/users';
import { DeleteIcon } from 'icons';
import { ROLE_DEFINITIONS } from 'roles/definitions';
import { RoleDependentComponent } from 'roles/RoleDependentComponent';

import { UserInvalidateDialog } from './UserInvalidateDialog';

export function UserInvalidateButton({ user }: { user: UserDetails }) {
    const [open, setOpen] = useState(false);

    if (user.userType === 'Administrator' || user.invalidated) {
        return null;
    }

    return (
        <>
            <RoleDependentComponent
                userRole={ROLE_DEFINITIONS.Administrator}
                component={ActionButton}
                onClick={() => setOpen(true)}
                color="secondary"
                iconClass={DeleteIcon}
                title="Invalidate"
                variant="contained"
            />
            <UserInvalidateDialog
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                user={user}
            />
        </>
    );
}
