import { useCallback, useRef, useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

export function useMacAddressesWarningDialog() {
    const [isOpen, setIsOpen] = useState(false);
    const [licenses, setLicenses] = useState(null);
    const closeDialogRef = useRef(null);
    const openDialog = useCallback(licenses => {
        return new Promise(resolve => {
            closeDialogRef.current = function (reason) {
                resolve(reason);
                setIsOpen(false);
            };
            setLicenses(licenses);
            setIsOpen(true);
        });
    }, []);

    const closeDialog = closeDialogRef.current;
    return {
        openDialog,
        WarningDialog: function () {
            if (!licenses) {
                return null;
            }
            return (
                <Dialog open={isOpen} onClose={() => closeDialog('cancelled')}>
                    <DialogTitle>Warning</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Some Mac addresses are already used by License(s):</DialogContentText>
                        {licenses.map(license => (
                            <DialogContentText
                                key={license.id}
                            >{`Product: ${license.productName}, Customer: ${license.customerEmail}, Mac Addresses: ${license.latestLicenseFileHardwareIdentifiers}`}</DialogContentText>
                        ))}
                        <DialogContentText>Use the same MAC address again?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={() => closeDialog('continue')} color="secondary" autoFocus>
                            Use Again
                        </Button>
                        <Button variant="outlined" onClick={() => closeDialog('cancelled')} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            );
        },
    };
}
