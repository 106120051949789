import { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { AppBar as MaterialUiAppBar, Box, Tab, Tabs, Toolbar } from '@mui/material';
import { alpha } from '@mui/material/styles';
import _ from 'lodash';
import { makeStyles } from 'makeStyles';

import { useUser } from 'api/authorization';
import MeVisVarexLogo from 'assets/mevis_varex_logo.png';
import { useGetTabbedRoutes } from 'routes/routes';

import { AppBarMenu } from './AppBarMenu';
import { AppBarMenuButton } from './AppBarMenuButton';
import { CompanyLogo } from './CompanyLogo';
import { ProductMenu } from './ProductMenu';
import { SystemLogo } from './SystemLogo';

const useStyles = makeStyles()(theme => ({
    root: {
        position: 'relative',
        zIndex: theme.zIndex.drawer + 1,
    },
    toolbar: {
        alignItems: 'stretch',
        marginLeft: theme.spacing(0.5),
    },
    tabs: {
        position: 'inherit',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        '& .MuiTab-root': {
            borderRadius: theme.spacing(0.5),
            marginBlock: theme.spacing(1),
        },
        '& .MuiTab-root.Mui-selected': {
            backgroundColor: alpha(theme.palette.secondary.main, 0.6),
        },
    },
    indicator: {
        display: 'none',
    },
    mevisLogo: {
        height: 60,
        alignSelf: 'center',
    },
}));

export function AppBar() {
    const history = useHistory();
    const { classes } = useStyles();
    const { pathname } = useLocation();
    const { data: user } = useUser();
    const [menuAnchor, setMenuAnchor] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const [selectedRouteTab, setSelectedRouteTab] = useState(false);
    const tabbedRoutes = useGetTabbedRoutes();

    useEffect(() => {
        const tabbedRoute = _.find(tabbedRoutes, tabbedRoute => pathname.includes(tabbedRoute.mapping));
        if (tabbedRoute) {
            setSelectedRouteTab(tabbedRoute.route);
        }
    }, [pathname, tabbedRoutes]);

    return (
        <MaterialUiAppBar position="fixed" className={classes.root}>
            <Toolbar className={classes.toolbar} disableGutters>
                <img className={classes.mevisLogo} alt="MeVis Logo" src={MeVisVarexLogo} />
                <Box flexGrow={1} paddingRight={1}>
                    {user && (
                        <Tabs
                            classes={{ indicator: classes.indicator, root: classes.tabs, flexContainer: classes.tabs }}
                            indicatorColor="secondary"
                            textColor="inherit"
                            value={selectedRouteTab}
                        >
                            {tabbedRoutes.map(tab => (
                                <Tab
                                    key={tab.route}
                                    value={tab.route}
                                    label={tab.label}
                                    component={Link}
                                    to={tab.route}
                                    data-testid={`AppBar_${tab.label}`}
                                />
                            ))}
                        </Tabs>
                    )}
                </Box>
                {user && <ProductMenu />}
                <CompanyLogo />
                <Box padding={1}>
                    <AppBarMenuButton ref={ref => setMenuAnchor(ref)} onClick={() => setMenuOpen(true)} user={user} />
                </Box>
            </Toolbar>
            <AppBarMenu anchor={menuAnchor} open={menuOpen} history={history} onClose={() => setMenuOpen(false)} />
            <SystemLogo />
        </MaterialUiAppBar>
    );
}
