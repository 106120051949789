import { type QueryClient, useQuery } from '@tanstack/react-query';

import { destroy, get, post, put } from './axiosWrapper';
import { removeObjectFromReactQueryCache, replaceObjectInReactQueryCache } from './cache';
import { API_ENDPOINTS } from './endpoints';
import type { Feature, Product } from './products';

export type ProductConfiguration = {
    additionalLicenseData: string;
    comment: string;
    features: Feature[];
    hardwareBinding: number;
    hasSerialNumber: boolean;
    id: number;
    internal: boolean;
    inUse: boolean;
    invalidated: boolean;
    isAdhocConfiguration: boolean;
    maximumActivations: number | null;
    maxNumberOfConcurrentUsers: number | null;
    name: string;
    partNo: string;
    product: Product;
    runtimeFeatures: Feature[];
    supportValidity: number;
    supportValidityUnit: 'd' | 'm' | 'y';
    validity: number;
    validityUnit: 'd' | 'm' | 'y';
    max_version: string;
};

export function loadProductConfigurationDetails(id: number) {
    return get<ProductConfiguration>(`${API_ENDPOINTS.PRODUCT_CONFIGURATION_DETAIL}${id}`).then(res => res.data);
}

export function useProductConfiguration(id: number) {
    return useQuery({
        queryKey: ['ProductConfiguration', 'detail', id],
        queryFn: () => loadProductConfigurationDetails(id),
        meta: { errorMessage: 'Loading product configuration failed' },
    });
}

export function loadProductConfigurationList(params: { invalidated?: boolean; product?: number }) {
    return get<ProductConfiguration[]>(API_ENDPOINTS.PRODUCT_CONFIGURATION_LIST, { params }).then(res => res.data);
}

export function useProductConfigurations(params: { invalidated?: boolean; product?: number }, enabled = true) {
    return useQuery({
        enabled,
        queryKey: ['ProductConfiguration', 'list', params],
        queryFn: () => loadProductConfigurationList(params),
        meta: { errorMessage: 'Loading product configurations failed' },
    });
}

export function updateProductConfiguration(id, payload) {
    return put<ProductConfiguration>(API_ENDPOINTS.PRODUCT_CONFIGURATION_DETAIL, id, payload).then(res => res.data);
}

export function createProductConfiguration(payload) {
    return post<ProductConfiguration>(API_ENDPOINTS.PRODUCT_CONFIGURATION_CREATE, payload).then(res => res.data);
}

export function deleteProductConfiguration(id) {
    return destroy(API_ENDPOINTS.PRODUCT_CONFIGURATION_DETAIL, id);
}

export function mutateProductConfiguration(queryClient: QueryClient, productConfiguration: ProductConfiguration) {
    replaceObjectInReactQueryCache(queryClient, productConfiguration, 'ProductConfiguration');
}

export function forgetProductConfiguration(queryClient: QueryClient, configurationId: number) {
    removeObjectFromReactQueryCache(queryClient, configurationId, 'ProductConfiguration');
}
