import { CheckboxElement, TextFieldElement } from 'react-hook-form-mui';

import { Box, CardContent, TextField, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';

import { AutocompleteElement } from 'commonComponents/AutocompleteElement';
import { NonFieldErrors } from 'commonComponents/CommonFormComponents';
import { LoadingIndicator } from 'commonComponents/LoadingIndicator';

import { loadCompanyList, loadOwnerIdList } from 'api/customers';
import { useProducts } from 'api/products';
import { ROLE_DEFINITIONS } from 'roles/definitions';
import { useCurrentUserHasRole } from 'roles/helpers';

import { GooglePlaceAutocomplete } from './GooglePlaceAutocomplete';

const parsePhoneNumberError = error => {
    if (error.type === 'maxLength') {
        return 'Max 20 characters allowed';
    }
    return error.message;
};

const isOptionEqualToValue = (option, value) => _.isEqual(option, value);

function getProductLabel(product) {
    return `${product.name}`;
}

export type CustomerFormFields = Pick<
    import('api/customers').CustomerDetails,
    | 'full_name'
    | 'address'
    | 'city'
    | 'comment'
    | 'company'
    | 'country'
    | 'email'
    | 'is_active'
    | 'owner_id'
    | 'phone_number'
    | 'products'
> &
    Partial<Pick<import('api/customers').CustomerDetails, 'groups' | 'full_name' | 'username'>>;

export type CustomerFormContentProps = {
    defaultProps: Partial<
        import('react-hook-form-mui').TextFieldElementProps & import('react-hook-form-mui').CheckboxElementProps
    >;
    form: import('react-hook-form').UseFormReturn<CustomerFormFields>;
    showIsActive?: boolean;
    disableProducts?: boolean;
};

export function CustomerFormContent({
    defaultProps,
    form,
    showIsActive = false,
    disableProducts = false,
}: CustomerFormContentProps) {
    const theme = useTheme();
    const { data: allProducts } = useProducts();
    const currentUserHasRole = useCurrentUserHasRole();
    const userIsLicenseManager = currentUserHasRole(ROLE_DEFINITIONS.LicenseManager);

    const { data: allCompanies } = useQuery({
        queryKey: ['Company', 'list'],
        queryFn: () => loadCompanyList({ onlyUserProducts: true }),
        meta: { errorMessage: 'Loading companies failed' },
    });
    const { data: allOwnerIds } = useQuery({
        queryKey: ['OwnerId', 'list'],
        queryFn: () => loadOwnerIdList({ onlyUserProducts: true }),
        meta: { errorMessage: 'Loading owner-ids failed.' },
    });
    const { register, watch } = form;
    const [city, country] = watch(['city', 'country']);

    if (!allProducts || !allCompanies || !allOwnerIds) {
        return <LoadingIndicator />;
    }

    return (
        <CardContent>
            <Box display="flex" flexDirection="column" style={{ gridGap: theme.spacing(2) }}>
                <TextFieldElement label="Full Name" name="full_name" required {...defaultProps} autoFocus />
                <TextFieldElement label="Email" name="email" type="email" required {...defaultProps} />
                <input type="hidden" {...register('username')}></input>
                <AutocompleteElement
                    label="Company"
                    name="company"
                    options={allCompanies}
                    freeSolo
                    autoSelect
                    required
                    testId="Customer_CompanyInput"
                />
                <GooglePlaceAutocomplete form={form} />
                <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={theme.spacing(1)}>
                    {/* Regular TextFields with watch to ensure updates */}
                    <TextField label="Country" name="country" disabled variant="outlined" value={country} />
                    <TextField label="City" name="city" disabled variant="outlined" value={city} />
                </Box>
                <TextFieldElement
                    label="Phone Number"
                    name="phone_number"
                    validation={{ maxLength: 20 }}
                    parseError={parsePhoneNumberError}
                    {...defaultProps}
                />
                <AutocompleteElement
                    label="Products"
                    name="products"
                    testId="UserForm_ProductsSelect"
                    options={allProducts}
                    isOptionEqualToValue={isOptionEqualToValue}
                    multiple
                    disableCloseOnSelect
                    getOptionLabel={getProductLabel}
                    required
                    disabled={disableProducts}
                />
                <AutocompleteElement
                    testId="Customer_Default_Owner_Id_Input"
                    label="Default Owner ID"
                    name="owner_id"
                    options={allOwnerIds}
                    freeSolo
                    autoSelect
                    disabled={!userIsLicenseManager}
                />
                <TextFieldElement label="Comment" name="comment" {...defaultProps} />
                {showIsActive ? (
                    <CheckboxElement name="is_active" label="Active" {...defaultProps} />
                ) : (
                    <input type="hidden" {...register('is_active')} />
                )}
                <NonFieldErrors />
            </Box>
        </CardContent>
    );
}
