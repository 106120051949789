import { type Role } from './definitions';
import { useCurrentUserHasRole } from './helpers';

export function RoleDependentComponent<T extends React.ComponentType<any>>({
    component: ChildComponent,
    userRole,
    ...props
}: { component: T; userRole: Role } & React.ComponentProps<T>) {
    const currentUserHasRole = useCurrentUserHasRole();
    return currentUserHasRole(userRole) && <ChildComponent {...props} />;
}
