import { useHistory } from 'react-router-dom';

import { TableBody, TableCell, TableRow, Tooltip } from '@mui/material';
import clsx from 'clsx';

import { useHistoryPath } from './ObjectTableContext';
import { useTableBodyStyles } from './styles';

/**
 *
 * @param {Object} props
 * @param {import('react-table').TableInstance} props.table
 * @returns
 */
export function ObjectTableBody({ table }) {
    const history = useHistory();
    const { classes } = useTableBodyStyles();

    const historyPath = useHistoryPath();

    return (
        <TableBody data-testid="ObjectTable_Body" {...table.getTableBodyProps()}>
            {table.page.map(row => {
                table.prepareRow(row);
                return (
                    <TableRow
                        hover
                        className={clsx(classes.row, row.original.invalidated && classes.rowInvalidated)}
                        onClick={() => history.push(historyPath + row.original.id)}
                        {...row.getRowProps()}
                    >
                        {row.cells.map(cell => {
                            return (
                                <Tooltip {...cell.getCellProps()} title={cell.column.tooltip?.(cell) || ''}>
                                    <TableCell
                                        className={row.original.error ? classes.rowError : null}
                                        style={cell.column.style}
                                    >
                                        {cell.render('Cell')}
                                    </TableCell>
                                </Tooltip>
                            );
                        })}
                    </TableRow>
                );
            })}
        </TableBody>
    );
}
