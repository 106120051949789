import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Card, CardActions, CardContent, Typography } from '@mui/material';

import { LinkButton } from 'commonComponents/LinkButton';
import { PageContainer } from 'commonComponents/PageContainer';

import { confirmUser } from 'api/users';
import { UndoIcon } from 'icons';
import { ROUTES } from 'routes/routes';

export function UserConfirmation() {
    const location = useLocation();
    const queryParameters = new URLSearchParams(location.search);
    var token = queryParameters.get('token');

    const [confirmed, setConfirmed] = useState(false);
    const [invalidToken, setInvalidToken] = useState(false);

    if (confirmed) {
        return (
            <PageContainer maxWidth="md">
                <Card>
                    <CardContent>
                        <Typography variant="h6">Thank you for registering!</Typography>
                        <Typography>
                            You can now log in using your email address and the password you selected during
                            registration.
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <LinkButton
                            iconClass={UndoIcon}
                            route={ROUTES.LOGIN}
                            title="Login"
                            data-testid="UserConfirmation_Login"
                        />
                    </CardActions>
                </Card>
            </PageContainer>
        );
    } else if (invalidToken) {
        return (
            <PageContainer maxWidth="md">
                <Card>
                    <CardContent>
                        <Typography variant="h6">Confirmation Link invalid.</Typography>
                        <Typography>
                            Please make sure to use the full link contained in your confirmation email.
                            <br />
                            If you already confirmed your account, you can log in using your email address and the
                            password you selected during registration.
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <LinkButton
                            iconClass={UndoIcon}
                            route={ROUTES.LOGIN}
                            title="Login"
                            data-testid="UserConfirmation_Login"
                        />
                    </CardActions>
                </Card>
            </PageContainer>
        );
    }
    confirmUser(token)
        .then(() => setConfirmed(true))
        .catch(() => setInvalidToken(true));

    return (
        <Card>
            <CardContent>
                <Typography>Verifying, please wait...</Typography>
            </CardContent>
        </Card>
    );
}
