import { generatePath } from 'react-router';

import { format, parseJSON } from 'date-fns';
import { durationOrUnlimited } from 'productConfigurations/durationUnits';

import { DATE_FORMAT } from 'common/constants';

import { LicenseDetails } from 'api/licenses';
import { ProductConfiguration } from 'api/productConfigurations';
import { propertyNames } from 'licenses/propertyNames';
import { expirationDate, getValueOrFallback } from 'licenses/utils';
import { ROLE_DEFINITIONS } from 'roles/definitions';
import { useCurrentUserHasRole } from 'roles/helpers';
import { ROUTES } from 'routes/routes';

export function useBasicRowDataMap(productConfiguration: ProductConfiguration): Array<{
    title: string;
    accessor: string | ((license: LicenseDetails) => string);
    clipboard?: (license: LicenseDetails) => string;
    hide?: (license: LicenseDetails) => boolean;
    route?: (license: LicenseDetails) => string;
}> {
    const currentUserHasRole = useCurrentUserHasRole();
    const showInternalState = currentUserHasRole(ROLE_DEFINITIONS.PrivilegedCustomer);
    const showSupportPeriod = currentUserHasRole(ROLE_DEFINITIONS.LicenseManager);

    if (productConfiguration == null) return [];
    return [
        {
            title: 'ID',
            accessor: 'id',
            clipboard: _ => window.location.href,
        },
        {
            title: 'Product',
            accessor: license => license.configuration.product?.name || 'N/A',
        },
        {
            title: 'Configuration',
            accessor: license => license.configuration.name,
        },
        {
            title: 'Creation Date',
            accessor: license => license.creation_date && format(parseJSON(license.creation_date), DATE_FORMAT),
        },
        {
            title: 'Validity',
            accessor: license =>
                durationOrUnlimited(license.configuration.validity, license.configuration.validityUnit),
        },
        {
            title: 'Expiration Date',
            accessor: license => expirationDate(license, license.expiration_date),
        },
        {
            title: 'Support Period',
            accessor: license =>
                durationOrUnlimited(license.configuration.supportValidity, license.configuration.supportValidityUnit),
            hide: _ => !showSupportPeriod,
        },
        {
            title: 'Support Expiration Date',
            accessor: license => expirationDate(license, license.support_expiration_date),
        },
        {
            title: 'Activations',
            accessor: license =>
                `${license.activeLicenseFilesCount} / ${
                    license.configuration.maximumActivations ? license.configuration.maximumActivations : 'unlimited'
                }`,
        },
        {
            title: 'Renewed on',
            accessor: license => license.renewal_date && format(parseJSON(license.renewal_date), DATE_FORMAT),
        },
        {
            title: 'Creator',
            accessor: license => getValueOrFallback(license.creator_fullname, license.creator),
        },
        {
            title: 'Serial Number',
            accessor: license => {
                const serialNumberProp = license.properties.find(
                    ({ name }) => name === propertyNames.PROPERTY_SERIAL_NUMBER
                );
                return serialNumberProp != null ? serialNumberProp.value : '';
            },
            hide: license => !license.configuration.hasSerialNumber,
        },
        {
            title: 'Owner ID',
            accessor: license => license.ownerId,
            hide: license => !license.configuration?.product.use_owner_id,
        },
        {
            title: 'Company',
            accessor: 'customerCompany',
        },
        {
            title: 'Customer Name',
            accessor: 'customerFullName',
            route: license => license.user && generatePath(ROUTES.CUSTOMER_DETAIL, { id: license.user }),
        },
        {
            title: 'Customer Email',
            accessor: 'customerEmail',
        },
        {
            title: 'Features',
            accessor: license =>
                license.features
                    ?.sort((a, b) => a.featureType.localeCompare(b.featureType) || a.name.localeCompare(b.name))
                    .map(f => (f.featureType === 'ADK Feature' ? `ADK: ${f.name}` : f.name))
                    .join(', ') || '',
        },
        {
            title: 'Application Names',
            accessor: license => license.configuration?.runtimeFeatures?.map(f => f.name).join(', ') || '',
            hide: license => !license.configuration?.product.is_application,
        },
        {
            title: 'Sales Order Number',
            accessor: license => license.sales_order_number,
        },
        {
            title: 'Purchase Order Number',
            accessor: license => license.purchase_order_number,
        },
        {
            title: 'Maximum Number of Concurrent Users',
            accessor: 'maxNumberOfConcurrentUsers',
            hide: license => !license.configuration?.product.use_no_of_concurrent_users,
        },
        {
            title: 'Max Version',
            accessor: 'max_version',
            hide: license => !license.configuration?.product.has_max_version,
        },
        {
            title: 'Comment',
            accessor: license => license.comment,
        },
        {
            title: 'Internal',
            accessor: 'internal',
            hide: _ => !showInternalState,
        },
        {
            title: 'Invalidated',
            accessor: license => (license.invalidated ? `Yes (${license.reason_for_invalidity})` : 'No'),
            hide: license => !license.invalidated,
        },
    ];
}
