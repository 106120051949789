import { SelectElement, TextFieldElement } from 'react-hook-form-mui';

import { Box, useTheme } from '@mui/material';
import { units } from 'productConfigurations/durationUnits';

export function DurationWithUnits({ name, durationLabel, unitLabel, disabled = false, ...defaultProps }) {
    const theme = useTheme();

    return (
        <Box display="flex" flexDirection="row" gap={theme.spacing(1)}>
            <TextFieldElement
                sx={{ flexGrow: 1 }}
                label={durationLabel}
                name={name}
                placeholder="unlimited"
                type="number"
                inputProps={{ min: '0', step: '1' }}
                InputLabelProps={{ shrink: true }}
                disabled={disabled}
                {...defaultProps}
            />
            <SelectElement
                sx={{ flexGrow: 0, minWidth: 150 }}
                label={unitLabel}
                name={`${name}Unit`}
                valueKey="value"
                labelKey="label"
                options={units}
                disabled={disabled}
                {...defaultProps}
            />
        </Box>
    );
}
