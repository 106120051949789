import { add, addDays, getDate, isDate, min, parseISO, set } from 'date-fns';

export const pluralize = (count, noun) => `${count} ${noun}${count !== 1 ? 's' : ''}`;

/**
 * Adds a given duration to a reference date.
 *
 * @param {Date} refDate
 * @param {int|null} duration
 * @param {('d'|'m'|'y')} unit
 * @return {Date|null}
 */
export const addDuration = (refDate, duration, unit) => {
    if (!refDate || !duration) {
        return null;
    }

    if (!isDate(refDate)) {
        refDate = parseISO(refDate);
    }

    refDate = set(refDate, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });

    switch (unit) {
        case 'd':
            return addDays(refDate, duration);
        case 'm':
            // adding a month at the end of a month should "round down",
            // e.g. 2023-01-31 + 1 month = 2023-02-28, but
            // 2023-02-28 + 1 month = 2023-03-28
            // first add the month to the first of month of the reference date,
            // then add the months and finally figure out the correct day
            return addDurationRoundDownToEndOfMonth(refDate, { months: duration });
        case 'y': {
            // the same mechanism as for months applies also for years,
            // e.g. 2024-02-29 + 1 year = 2025-02-28
            return addDurationRoundDownToEndOfMonth(refDate, { years: duration });
        }
        default:
            return null;
    }
};

function addDurationRoundDownToEndOfMonth(refDate, durationObj) {
    const firstOfMonth = set(refDate, { date: 1 });
    const firstOfTargetMonth = add(firstOfMonth, durationObj);
    const refDateInTargetMonth = set(firstOfTargetMonth, { date: getDate(refDate) });
    const refDatePlusDuration = add(refDate, durationObj);
    return min([refDateInTargetMonth, refDatePlusDuration]);
}
