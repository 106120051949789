import { useState } from 'react';

import { ActionButton } from 'commonComponents/ActionButton';

import { DeleteIcon } from 'icons';
import { ROLE_DEFINITIONS } from 'roles/definitions';
import { RoleDependentComponent } from 'roles/RoleDependentComponent';

import { CustomerDeleteDialog } from './CustomerDeleteDialog';

export function CustomerDeleteButton({ customer }) {
    const [open, setOpen] = useState(false);

    if (customer.hasLicenses) {
        return null;
    }

    return (
        <>
            <RoleDependentComponent
                userRole={ROLE_DEFINITIONS.LicenseManager}
                component={ActionButton}
                onClick={() => setOpen(true)}
                color="secondary"
                iconClass={DeleteIcon}
                title="Delete"
            />
            <CustomerDeleteDialog
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                customerId={customer.id}
            />
        </>
    );
}
