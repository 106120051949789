import { TextFieldElement, TextFieldElementProps } from 'react-hook-form-mui';

import { isEmpty } from 'lodash';

import { useCurrentProduct } from 'api/products';

type MaxVersionProps = {
    disabled?: boolean;
    name?: string;
};

export function MaxVersion({
    disabled,
    name = 'max_version',
    required = false,
    ...defaultProps
}: Omit<TextFieldElementProps, 'name'> & MaxVersionProps) {
    const { data: currentProduct } = useCurrentProduct();

    function validate(value: string) {
        if (required && isEmpty(value)) {
            return 'This field is required';
        }
        const reg = new RegExp(/^(\d+)(\.(\d+))?(\.(\*|\d+))?$/);
        if (!isEmpty(value) && !reg.test(value)) {
            return 'Invalid version';
        }
    }

    if (!currentProduct?.has_max_version) return null;

    return (
        <TextFieldElement
            label={required ? 'Max Version *' : 'Max Version'}
            name={name}
            placeholder="__.__.__"
            type="text"
            InputLabelProps={{ shrink: true }}
            validation={{ validate: validate }}
            disabled={disabled}
            {...defaultProps}
        />
    );
}
