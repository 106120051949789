import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { TextFieldElement, TextFieldElementProps } from 'react-hook-form-mui';

import { useQuery } from '@tanstack/react-query';

import { AutocompleteElement } from 'commonComponents/AutocompleteElement';
import { buildHexMaskInput } from 'commonComponents/HexMaskInput';

import { loadUpgradeSystemIds } from 'api/licenses';
import { hasInficryptSystemId, hasSystemId } from 'licenses/utils';

const SYSTEMID_REGEX = /^(([A-Fa-f0-9]{2}[:]){19}[A-Fa-f0-9]{2}[,]?)+$/i;
const InputMask = buildHexMaskInput('00:00:00:00:00:00:00:00:00:00:00:00:00:00:00:00:00:00:00:00');

const INFICRYPT_SYSTEMID_REGEX = /^[A-Fa-f0-9]{16}$/;
const InficryptInputMask = buildHexMaskInput('0000000000000000');

type DisplayMode = 'freeform' | 'options' | 'fixed';

function validateInficryptSystemId(hexString: string) {
    const firstPart = hexString.slice(0, 14);
    const actualChecksum = parseInt(hexString.slice(14), 16);

    // Convert hex string to byte array
    let byteArray: Array<number> = [];
    for (let i = 0; i < firstPart.length; i += 2) {
        byteArray.push(parseInt(firstPart.slice(i, i + 2), 16));
    }

    // Calculate checksum
    let c = 0;
    for (let b of byteArray) {
        c += b;
        c += b * b * b;
    }
    const expectedChecksum = c & 0xff;
    if (actualChecksum === expectedChecksum) {
        return true;
    }
    return 'Invalid checksum';
}

function InficryptSystemIdField({ defaultProps }: { defaultProps: Partial<TextFieldElementProps> }) {
    return (
        <SystemIdField
            defaultProps={defaultProps}
            pattern={INFICRYPT_SYSTEMID_REGEX}
            inputComponent={InficryptInputMask}
            validate={validateInficryptSystemId}
        />
    );
}

function SystemIdField({
    disabled = false,
    defaultProps,
    validate,
    pattern = SYSTEMID_REGEX,
    inputComponent = InputMask,
}: {
    disabled?: boolean;
    defaultProps: Partial<TextFieldElementProps>;
    pattern?: RegExp;
    validate?: TextFieldElementProps['validation']['validate'];
    inputComponent?: typeof InputMask;
}) {
    return (
        <TextFieldElement
            label="System ID"
            name="system_id"
            required
            disabled={disabled}
            {...defaultProps}
            data-testid="LicenseFileForm_SystemId"
            InputProps={{ inputComponent }}
            InputLabelProps={{ shrink: true }}
            validation={{ pattern, validate }}
            parseError={error => {
                if (error.message) {
                    return error.message;
                }
                if (error.type === 'pattern') {
                    return 'Invalid System ID';
                }
                return 'Unknown error';
            }}
        />
    );
}

function SystemIdSelect({ systemIds }) {
    return <AutocompleteElement label="System ID" name="system_id" required options={systemIds} disablePortal />;
}

function SystemId({ license, defaultProps }) {
    const { setValue } = useFormContext();

    const { data: validSystemIds } = useQuery({
        queryKey: ['SystemId', 'list', { licenseId: license.id, licenseConfiguration: license.configuration }],
        queryFn: () => {
            if (hasSystemId(license.configuration)) {
                return loadUpgradeSystemIds(license.id);
            }
            return [];
        },
        meta: { errorMessage: 'Loading valid system IDs failed.' },
    });

    const [displayMode, setDisplayMode] = useState<DisplayMode>();

    useEffect(() => {
        if (validSystemIds == null) {
            setDisplayMode(undefined);
            return;
        }

        if (validSystemIds.length === 1) {
            // only one option, force this option
            setValue('system_id', validSystemIds[0]);
            setDisplayMode('fixed');
            return;
        }

        if (validSystemIds.length > 1) {
            // multiple options, allow the user to select one
            setDisplayMode('options');
            return;
        }

        // no restrictions, user can enter any system id
        setDisplayMode('freeform');
    }, [setDisplayMode, setValue, validSystemIds]);

    switch (displayMode) {
        case 'freeform':
            return <SystemIdField defaultProps={defaultProps} />;
        case 'options':
            return <SystemIdSelect systemIds={validSystemIds} />;
        case 'fixed':
            return <SystemIdField defaultProps={defaultProps} disabled />;
        default:
            return null;
    }
}

export function SystemIdOrInficryptSystemId({ license, defaultProps }) {
    const { register } = useFormContext();
    if (hasInficryptSystemId(license.configuration)) {
        return <InficryptSystemIdField defaultProps={defaultProps} />;
    }
    if (hasSystemId(license.configuration)) {
        return <SystemId license={license} defaultProps={defaultProps} />;
    }

    return <input type="hidden" {...register('system_id')}></input>;
}
