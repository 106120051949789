import { generatePath } from 'react-router';

import { LinkButton } from 'commonComponents/LinkButton';

import { EditIcon } from 'icons';
import { ROLE_DEFINITIONS } from 'roles/definitions';
import { RoleDependentComponent } from 'roles/RoleDependentComponent';
import { ROUTES } from 'routes/routes';

export function LicenseFileEditButton({ licenseFile, license }) {
    if (license.invalidated || licenseFile.deactivated) return null;
    return (
        <RoleDependentComponent
            userRole={ROLE_DEFINITIONS.LicenseManager}
            component={LinkButton}
            iconClass={EditIcon}
            route={generatePath(ROUTES.LICENSEFILE_EDIT, { id: licenseFile.id })}
            title="Edit"
            data-testid="LicenseFileDetails_EditButton"
        />
    );
}
