import { Box } from '@mui/material';
import { uniqueId } from 'lodash';
import { getMRTTheme } from 'material-react-table';

function mapPositionToFullText(text, position) {
    let count = position;
    let fullPosition = 0;
    while (count > 0) {
        if (text[fullPosition] !== ':' && text[fullPosition] !== '-') {
            count--;
        }
        fullPosition++;
    }

    if (fullPosition < text.length && (text[fullPosition] === ':' || text[fullPosition] === '-')) {
        fullPosition++;
    }

    return fullPosition;
}

function makeChunk(table, content, isMatch) {
    return (
        <Box
            aria-hidden="true"
            component="span"
            key={uniqueId('chunk-')}
            sx={
                isMatch
                    ? {
                          backgroundColor: theme => getMRTTheme(table, theme).matchHighlightColor,
                          borderRadius: '2px',
                          color: theme =>
                              theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
                          padding: '2px 1px',
                      }
                    : undefined
            }
        >
            {content}
        </Box>
    );
}

function highlightOne(table, text, search) {
    search = search.replace(/[:-]/g, '').toLowerCase();
    const textWithoutDelims = text.replace(/[:-]/g, '').toLowerCase();

    const startIndex = textWithoutDelims.indexOf(search);
    if (startIndex < 0) {
        return <span key={uniqueId('span-')}>{text}</span>;
    }
    const endIndex = startIndex + search.length - 1;

    const fullStartIndex = mapPositionToFullText(text, startIndex);
    const fullEndIndex = mapPositionToFullText(text, endIndex);

    return (
        <span key={uniqueId('span-')}>
            {fullStartIndex > 0 && makeChunk(table, text.substring(0, fullStartIndex), false)}
            {makeChunk(table, text.substring(fullStartIndex, fullEndIndex + 1), true)}
            {fullEndIndex < text.length && makeChunk(table, text.substring(fullEndIndex + 1), false)}
        </span>
    );
}

export function highlight(table, text, search) {
    const items = text.split(/,\s*/);
    const highlightedItems = items.map(i => highlightOne(table, i, search));
    const result = highlightedItems.map((item, index) =>
        index === 0 ? item : <span key={uniqueId('osp-')}>, {item}</span>
    );
    return result;
}
