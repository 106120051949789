import { LinkButton } from 'commonComponents/LinkButton';
import { useTableInteractionStyles } from 'commonComponents/ObjectTable/styles';

import { PersonAddIcon } from 'icons';
import { ROLE_DEFINITIONS } from 'roles/definitions';
import { RoleDependentComponent } from 'roles/RoleDependentComponent';
import { ROUTES } from 'routes/routes';

export function UserListButtons() {
    const { classes } = useTableInteractionStyles();

    return (
        <RoleDependentComponent
            userRole={ROLE_DEFINITIONS.Administrator}
            component={LinkButton}
            className={classes.button}
            iconClass={PersonAddIcon}
            route={ROUTES.USER_CREATE}
            title="Create"
            data-testid="FilterUsers_CreateUser"
        />
    );
}
