import { Controller, useFormContext } from 'react-hook-form';

import { FormControl } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import { DATE_FORMAT } from 'common/constants';

import { getDateTimeObjectFromDate } from '../licenses/utils';

/**
 * @param {import('@material-ui/pickers').KeyboardDatePickerProps } props
 */
export function KeyboardDatePickerElement({ name, required, ...other }) {
    const { control } = useFormContext();
    return (
        <Controller
            control={control}
            name={name}
            rules={required ? 'This field is required' : undefined}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormControl variant="standard" error={Boolean(error)} fullWidth>
                    <DatePicker
                        value={getDateTimeObjectFromDate(value)}
                        onChange={onChange}
                        format={DATE_FORMAT}
                        name={name}
                        inputVariant="outlined"
                        {...other}
                    />
                </FormControl>
            )}
        />
    );
}
