import { useForm } from 'react-hook-form';
import { FormContainer } from 'react-hook-form-mui';

import { Box, CardActions, CardContent, Dialog, DialogTitle, FormHelperText, useTheme } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { arrayOf, func, number, object } from 'prop-types';

import { ActionButton } from 'commonComponents/ActionButton';
import { AutocompleteElement } from 'commonComponents/AutocompleteElement';

import { loadCustomerList } from 'api/customers';
import { moveLicenseFile, mutateLicenseFile, useCustomerLicenses } from 'api/licenses';
import { useCurrentProduct } from 'api/products';
import { MoveIcon, UndoIcon } from 'icons';
import { getCustomerLabel, getLicenseLabel } from 'licenses/utils';

export function MoveLicenseFileDialog({ license, licenseFileIds, onSubmitCallback, setOpen }) {
    const initialValues = {
        customer: license.user,
        license: null,
    };
    const formContext = useForm({ defaultValues: initialValues });
    const { watch, setValue } = formContext;
    const customerId = watch('customer');
    const theme = useTheme();

    const { data: currentProduct } = useCurrentProduct();

    const { data: customers, isLoading } = useQuery({
        enabled: Boolean(currentProduct),
        queryKey: ['Customer', 'list', { product: currentProduct?.id }],
        queryFn: () => loadCustomerList({ product: currentProduct?.id }),
        meta: { errorMessage: 'Loading customers failed' },
    });

    const queryClient = useQueryClient();
    const {
        error,
        isLoading: isRequesting,
        mutate: trigger,
    } = useMutation({
        mutationFn: moveLicenseFile,
        onSuccess: data => mutateLicenseFile(queryClient, data),
        meta: { errorMessage: 'Moving license file failed' },
    });

    const { data } = useCustomerLicenses({ customerId, invalidated: false });
    const validCustomerLicenses = data?.results;

    const handleChangeCustomer = (_, _newValue) => {
        setValue('license', null);
    };

    const onSubmit = async values => {
        const promises = licenseFileIds.map(async licenseFileId => {
            await trigger({ id: licenseFileId, licenseId: values.license });
        });
        await Promise.all(promises);

        if (onSubmitCallback) {
            onSubmitCallback();
        }
        setOpen(false);
    };

    return (
        <Dialog open onClose={() => setOpen(false)} maxWidth="md" fullWidth>
            <DialogTitle>
                {licenseFileIds.length === 1
                    ? 'Move license file to another license'
                    : `Move ${licenseFileIds.length} license files to another license`}
            </DialogTitle>
            <FormContainer formContext={formContext} onSuccess={onSubmit}>
                <CardContent data-testid="LicenseForm_CardContent">
                    <Box display="flex" flexDirection="column" style={{ gridGap: theme.spacing(1) }}>
                        <AutocompleteElement
                            label="Customer"
                            loading={isLoading}
                            name="customer"
                            data-testid="MoveLicenseFile_CustomerSelect"
                            options={customers?.map(c => c.id) || []}
                            getOptionLabel={customerId => {
                                return getCustomerLabel(customers, customerId);
                            }}
                            onChangeCallback={handleChangeCustomer}
                            required
                        />
                        <AutocompleteElement
                            label="Move to license"
                            name="license"
                            data-testid="MoveLicenseFile_LicenseSelect"
                            options={
                                validCustomerLicenses
                                    ? validCustomerLicenses.filter(l => l.id !== license.id).map(l => l.id)
                                    : []
                            }
                            getOptionLabel={licenseId => {
                                return getLicenseLabel(validCustomerLicenses, licenseId);
                            }}
                            required
                        />
                    </Box>
                </CardContent>
                <CardActions>
                    <ActionButton
                        color="secondary"
                        data-testid="SubmitButton"
                        disabled={isRequesting}
                        iconClass={MoveIcon}
                        inProgress={isRequesting}
                        title="Move license file"
                        type="submit"
                        variant="contained"
                    />
                    <ActionButton
                        data-testid="LicenseForm_CancelButton"
                        iconClass={UndoIcon}
                        onClick={() => setOpen(false)}
                        title="Cancel"
                        variant="contained"
                        color="primary"
                    />
                    <FormHelperText error>{error?.message}</FormHelperText>
                </CardActions>
            </FormContainer>
        </Dialog>
    );
}

MoveLicenseFileDialog.propTypes = {
    license: object.isRequired,
    licenseFileIds: arrayOf(number.isRequired).isRequired,
    onSubmitCallback: func,
    setOpen: func.isRequired,
};
