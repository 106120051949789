import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

import { useUser } from 'api/authorization';

export function ConfirmLicenseFileSentViaEmailDialog({ open, setOpen }) {
    const { data: user } = useUser();

    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>We have e-mailed the license to {user.email}.</DialogTitle>
            <DialogContent>
                <DialogContentText>If it has not arrived in a few minutes, check your spam folder.</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => setOpen(false)} color="primary" autoFocus>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}
