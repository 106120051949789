import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { LoadingIndicator } from 'commonComponents/LoadingIndicator';

import { useLicense } from 'api/licenses';

import { CreateLicense } from './CreateLicense';
import { propertyNames } from './propertyNames';

export function UpgradeLicense() {
    const { id: licenseId } = useParams<{ id: string }>();

    const { data: license, error } = useLicense(Number(licenseId));

    const upgradeFrom = useMemo(() => {
        if (license) {
            return {
                id: license.id,
                serial_number:
                    license.properties.find(({ name }) => name === propertyNames.PROPERTY_SERIAL_NUMBER) != null
                        ? license.properties.find(({ name }) => name === propertyNames.PROPERTY_SERIAL_NUMBER).value
                        : '',
                ownerId: license.ownerId,
                user: license.user,
            };
        }
    }, [license]);

    if (error) {
        return null;
    }

    if (!license) {
        return <LoadingIndicator />;
    }

    return <CreateLicense upgradeFrom={upgradeFrom} />;
}
