import { makeStyles } from 'makeStyles';

import { useSettings } from 'api/settings';
import Logo3DBolus from 'assets/3DBolus_logo.png';
import LogoKuLeuven from 'assets/kuleuven_logo.png';
import LogoSound from 'assets/sound_logo.png';
import LogoVarex from 'assets/varex_logo.png';

const useStyles = makeStyles()(theme => ({
    companyLogo: {
        height: 'fit-content',
        alignSelf: 'center',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
}));

const logos = { adaptiiv: Logo3DBolus, 'varex-demo': LogoVarex, 'ku-leuven': LogoKuLeuven, sound: LogoSound };

export function CompanyLogo() {
    const { classes } = useStyles();
    const { data: appSettings } = useSettings();

    if (!appSettings) {
        return null;
    }

    return (
        <>
            {logos[appSettings.environmentName] && (
                <img
                    className={classes.companyLogo}
                    alt={appSettings.environmentName + ' Logo'}
                    src={logos[appSettings.environmentName]}
                />
            )}
        </>
    );
}
