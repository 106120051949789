// Note: The numbers do NOT represent the database role object IDs, but a role hierarchy.
// A role includes all roles with a lower number.

export const ROLE_DEFINITIONS = {
    Administrator: 4,
    LicenseManager: 3,
    PrivilegedCustomer: 2,
    Customer: 1,
    None: 0,
} as const;

export type Role = (typeof ROLE_DEFINITIONS)[keyof typeof ROLE_DEFINITIONS];
