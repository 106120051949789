import { makeStyles } from 'makeStyles';

export const MIN_WIDTH = {
    LIST: 800,
};

export const useTableBodyStyles = makeStyles()(theme => ({
    row: {
        cursor: 'pointer',
        whiteSpace: 'nowrap',
    },
    rowError: {
        color: theme.palette.error.main,
    },
    rowInvalidated: { textDecoration: 'line-through' },
}));

export const useTableInteractionStyles = makeStyles()(theme => ({
    card: {
        marginBottom: theme.spacing(1),
        padding: theme.spacing(0.5),
        flexShrink: 0,
    },
    searchField: {
        margin: theme.spacing(0.5),
        flexGrow: 1,
    },
    button: {
        margin: theme.spacing(0.5),
    },
}));
