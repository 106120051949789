import React, { useRef } from 'react';
import { BrowserRouter } from 'react-router-dom';

import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { CssBaseline, IconButton } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { SnackbarProvider } from 'notistack';
import { ReactQueryWrapper } from 'ReactQueryWrapper';

import { BASE_URL } from 'common/baseUrl';

import { CloseIcon } from 'icons';

import { configureTheme } from './configureTheme';

export function getNonce() {
    const element = document.querySelector<HTMLElement>('[data-nonce]');
    return element.dataset.nonce;
}

const cache = createCache({
    key: 'css',
    nonce: getNonce(),
});

export function AppEnvironment({ children }) {
    // add action to all snackbars
    const notistackRef = useRef<SnackbarProvider>();
    const onClickDismiss = key => () => {
        notistackRef.current.closeSnackbar(key);
    };
    return (
        <StyledEngineProvider injectFirst>
            <CacheProvider value={cache}>
                <ThemeProvider theme={configureTheme()}>
                    <SnackbarProvider
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        ref={notistackRef}
                        action={key => (
                            <IconButton color="inherit" onClick={onClickDismiss(key)} size="large">
                                <CloseIcon />
                            </IconButton>
                        )}
                    >
                        <CssBaseline />
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <BrowserRouter basename={`/${BASE_URL}`}>
                                <ReactQueryWrapper>{children}</ReactQueryWrapper>
                            </BrowserRouter>
                        </LocalizationProvider>
                    </SnackbarProvider>
                </ThemeProvider>
            </CacheProvider>
        </StyledEngineProvider>
    );
}
