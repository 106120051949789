import { useEffect } from 'react';
import { useHistory } from 'react-router';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { usePrevious } from 'common/hooks';

import { ActionButton } from 'commonComponents/ActionButton';

import { type UserDetails, mutateUser, updateUser } from 'api/users';
import { DeleteIcon, UndoIcon } from 'icons';
import { ROUTES } from 'routes/routes';

export function UserInvalidateDialog({
    user,
    open,
    onClose,
}: {
    user: UserDetails;
    open: boolean;
    onClose: () => void;
}) {
    const queryClient = useQueryClient();
    const {
        mutate: trigger,
        error,
        isLoading: isInvalidating,
    } = useMutation({
        mutationFn: () =>
            updateUser(user.id, {
                ...user,
                default_product: user.default_product?.id || null,
                products: user.products.map(p => p.id),
                invalidated: true,
            }),
        onSuccess: response => mutateUser(queryClient, response),
        meta: { errorMessage: 'Invalidating user failed' },
    });
    const history = useHistory();

    const wasInvalidating = usePrevious(isInvalidating);
    useEffect(() => {
        if (error) return;
        if (!isInvalidating && wasInvalidating) {
            onClose();
        }
    }, [error, isInvalidating, onClose, wasInvalidating]);

    async function triggerInvalidation() {
        // @ts-ignore
        await trigger();
        history.push(ROUTES.USER_LIST);
    }

    return (
        <Dialog {...{ open, onClose }}>
            <DialogTitle>Invalidate User</DialogTitle>
            <DialogContent>Are you sure you want to invalidate this user?</DialogContent>
            <DialogActions>
                <ActionButton
                    color="secondary"
                    iconClass={DeleteIcon}
                    disabled={isInvalidating}
                    inProgress={isInvalidating}
                    title="Invalidate"
                    variant="contained"
                    type="submit"
                    onClick={triggerInvalidation}
                />
                <ActionButton
                    color="primary"
                    iconClass={UndoIcon}
                    inProgress={false}
                    onClick={onClose}
                    title="Cancel"
                    variant="contained"
                />
            </DialogActions>
        </Dialog>
    );
}
