import { ROLE_DEFINITIONS } from 'roles/definitions';
import { userHasRole } from 'roles/helpers';

export const USERNAME_REGEX = /^[A-Za-z0-9@.+-_]*$/i;

export function otherAdminUserIsLoggedInUser(user, loggedInUser) {
    return (
        !userHasRole(user, ROLE_DEFINITIONS.Administrator) || loggedInUser.is_superuser || loggedInUser.id === user.id
    );
}
