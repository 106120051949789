import { TextFieldElement } from 'react-hook-form-mui';

export function MaximumNumberOfConcurrentUsersField({ name, disabled = false, required }) {
    return (
        <TextFieldElement
            label="Maximum Number of Concurrent Users"
            name={name}
            type="number"
            inputProps={{ min: '1', step: '1' }}
            disabled={disabled}
            validation={{
                validate: value => {
                    if (value != null && value !== '' && value < 1) {
                        return 'Please enter a positive number';
                    }
                },
            }}
            required={required}
            autoComplete="off"
            variant="outlined"
        />
    );
}
