import type { QueryClient } from '@tanstack/query-core';

export function replaceObjectInReactQueryCache<T extends { id: number }>(
    queryClient: QueryClient,
    obj: T,
    name: string
) {
    queryClient.setQueriesData<T>([name, 'detail', obj.id], obj);
    queryClient.setQueriesData<T[]>([name, 'list'], list => {
        if (Array.isArray(list)) {
            return list.map(old => (old.id === obj.id ? obj : old));
        }
        return list;
    });
}

export function removeObjectFromReactQueryCache<T extends { id: number }>(
    queryClient: QueryClient,
    id: number,
    name: string
) {
    queryClient.removeQueries([name, 'detail', id]);
    queryClient.setQueriesData<T[]>([name, 'list'], list => {
        if (Array.isArray(list)) {
            return list.filter(e => e.id !== id);
        }
        return list;
    });
}
export function clearCache(queryClient?: QueryClient) {
    window.localStorage.removeItem('currentProductId');
    queryClient?.clear();
}
