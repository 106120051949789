export const API_ENDPOINTS = {
    SETTINGS_REQUEST: '/api/request-settings/',
    LICENSE_CREATE: '/api/license/create/',
    LICENSE_ASSIGN: '/api/license/assign/',
    LICENSE_LIST: '/api/licenses/',
    LICENSE_SERIALNUMBERS: '/api/licenses/by-sn/',
    REPORT_CLAIMED_LICENSES: '/api/licenses/reports/claimed-licenses/',
    REPORT_SOLD_LICENSES: '/api/licenses/reports/sold-licenses/',
    REPORT_RENEWED_LICENSES: '/api/licenses/reports/renewed-licenses/',
    REPORT_LICENSE_STATISTICS: '/api/licenses/reports/statistics/',
    REPORT_SYSTEM_INFO_FILES: '/api/licenses/reports/system-info-files/',
    LICENSE_DETAIL: '/api/license/',
    PRODUCT_CONFIGURATION_DETAIL: '/api/product-configuration/',
    PRODUCT_CONFIGURATION_LIST: '/api/product-configurations/',
    PRODUCT_CONFIGURATION_CREATE: '/api/product-configuration/create/',
    LICENSE_HISTORY: '/api/license-history/',
    LICENSEFILE_LIST: '/api/licensefiles/',
    LICENSEFILE_DETAIL: '/api/licensefile/',
    LICENSEFILE_HISTORY: '/api/licensefile-history/',
    PASSWORD_RESET: '/api/password_reset/',
    PASSWORD_RESET_CONFIRM: '/api/password_reset/confirm/',
    USER_CREATE: '/api/user/create/',
    USER_REGISTER: '/api/user/register/',
    USER_CONFIRM: '/api/user/register/confirm/',
    USER_RESEND: '/api/user/register/resend/',
    USER_WELCOME: '/api/user/',
    USER_DETAIL: '/api/user/',
    USER_LIST: '/api/users/',
    AUTHORIZATION_LOAD_USER: '/api/authorization/user/',
    AUTHORIZATION_LOGIN: '/api/authorization/login/',
    AUTHORIZATION_LOGOUT: '/api/authorization/logout/',
    EMAIL_TEMPLATE_DETAIL: '/api/email_templates/',
    CUSTOMER_LIST: '/api/customers/',
    CUSTOMER_CREATE: '/api/customer/create/',
    CUSTOMER_DETAIL: '/api/customer/',
    CUSTOMER_HISTORY_DETAIL: '/api/customers/history/',
    REPORT_CUSTOMER_ORIGINS: '/api/customers/reports/customer-origins/',
    PLACES: '/api/places/',
    PLACE_DETAIL: '/api/place/',
    FEATURE_LIST: '/api/features/',
    PRODUCT_LIST: '/api/products/',
    COMPANY_LIST: '/api/companies/',
    OWNER_ID_LIST: '/api/owner-ids/',
    LICENSE_GENERATORS_VERSION: '/api/license-generators-version/',
} as const;
