import { useParams } from 'react-router-dom';

import { Card, CardActions, CardContent, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import { ActionButton } from 'commonComponents/ActionButton';
import { LinkButton } from 'commonComponents/LinkButton';
import { PageContainer } from 'commonComponents/PageContainer';

import { resendValidationEmail } from 'api/users';
import { EmailIcon, UndoIcon } from 'icons';
import { ROUTES } from 'routes/routes';

import { UserRegistrationSuccess } from './UserRegistrationSuccess';

export function UserRegistrationIncomplete() {
    const { email } = useParams<{ email: string }>();

    const {
        isSuccess,
        mutate: trigger,
        isLoading: isResending,
    } = useMutation({
        mutationFn: () => resendValidationEmail(email),
        meta: { errorMessage: 'Resending validation email failed' },
    });

    if (isSuccess) {
        return <UserRegistrationSuccess />;
    }

    return (
        <PageContainer maxWidth="md">
            <Card>
                <CardContent>
                    <Typography variant="h6">This account is not activated yet.</Typography>
                    <Typography>
                        Please use the confirmation link from the email you received when registering.
                    </Typography>
                    <Typography>
                        If you did not receive a confirmation email, please also check your Spam folder.
                        <br />
                        You can use the button below to resend the confirmation email.
                    </Typography>
                </CardContent>
                <CardActions>
                    <LinkButton
                        iconClass={UndoIcon}
                        route={ROUTES.LOGIN}
                        title="Login"
                        data-testid="UserRegistration_Login"
                    />
                    <ActionButton
                        iconClass={EmailIcon}
                        title="Resend confirmation email"
                        variant="contained"
                        onClick={() => trigger()}
                        inProgress={isResending}
                    />
                </CardActions>
            </Card>
        </PageContainer>
    );
}
