import _ from 'lodash';

import { ROLE_DEFINITIONS } from 'roles/definitions';
import { useCurrentUserHasRole, useDefaultScreen } from 'roles/helpers';

export const ROUTES = {
    LOGIN: '/login',
    DASHBOARD: '/dashboard',
    LICENSE_LIST: '/licenses',
    LICENSE_BILLING: '/licenses/billing',
    LICENSE_CREATE: '/license/create',
    LICENSE_CLAIM: '/license/claim',
    LICENSE_ASSIGN: '/license/assign',
    LICENSE_DETAIL: '/license/:id',
    LICENSE_EDIT: '/license/:id/edit',
    LICENSE_UPGRADE: '/license/:id/upgrade',
    LICENSEFILE_DETAIL: '/licensefile/:id',
    LICENSEFILE_CREATE: '/licensefile/:licenseId/create',
    LICENSEFILE_EDIT: '/licensefile/:id/edit',
    CONFIGURATION_LIST: '/configurations',
    CONFIGURATION_CREATE: '/configuration/create',
    CONFIGURATION_DETAIL: '/configuration/:id',
    CONFIGURATION_EDIT: '/configuration/:id/edit',
    CUSTOMER_LIST: '/customers',
    CUSTOMER_CREATE: '/customer/create',
    CUSTOMER_DETAIL: '/customer/:id',
    CUSTOMER_EDIT: '/customer/:id/edit',
    CUSTOMER_REVERT: '/customer/revert/:historyId',
    USER_LIST: '/users',
    USER_CREATE: '/user/create',
    USER_REGISTER: '/user/register',
    USER_REGISTER_INCOMPLETE: '/user/register/incomplete/:email',
    USER_CONFIRM: '/user/confirm',
    USER_EMAIL: '/user/:id/email',
    USER_EDIT: '/user/:id/edit',
    USER_DETAIL: '/user/:id',
    PROFILE: '/profile/',
    RESET_PASSWORD_REQUEST: '/reset-password',
    RESET_PASSWORD_CONFIRM: '/reset-password/confirm',
    NOT_ALLOWED: '/not-allowed',
} as const;

export const TABBED_ROUTES = [
    { route: ROUTES.DASHBOARD, mapping: '/dashboard', label: 'Dashboard', role: ROLE_DEFINITIONS.LicenseManager },
    { route: ROUTES.LICENSE_LIST, mapping: '/license', label: 'Licenses' },
    { route: ROUTES.USER_LIST, mapping: '/user', label: 'Users', role: ROLE_DEFINITIONS.Administrator },
    {
        route: ROUTES.CONFIGURATION_LIST,
        mapping: '/configuration',
        label: 'Product Configurations',
        role: ROLE_DEFINITIONS.PrivilegedCustomer,
    },
    {
        route: ROUTES.CUSTOMER_LIST,
        mapping: '/customer',
        label: 'Customers',
        role: ROLE_DEFINITIONS.Customer,
    },
] as const;

export function useGetTabbedRoutes() {
    const userHasRole = useCurrentUserHasRole();
    return _.filter(TABBED_ROUTES, route => !('role' in route) || userHasRole(route.role));
}

export function useDefaultRoute() {
    return useDefaultScreen() === 'D' ? ROUTES.DASHBOARD : ROUTES.LICENSE_LIST;
}
