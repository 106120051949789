import React from 'react';

import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { useHandleError } from 'api/hooks/errorHandling';

// The error handling hook needs access to the notistack context and the react-router context,
// i.e.it needs to be rendered inside the Router and SnackbarProvider components
export function ReactQueryWrapper({ children, retry }: { children: React.ReactNode; retry?: boolean }) {
    // Ensure queryClient is only created once
    const [queryClient] = React.useState(
        () =>
            new QueryClient({
                defaultOptions: {
                    mutations: {
                        retry,
                    },
                    queries: {
                        retry,
                    },
                },
                mutationCache: new MutationCache({
                    onError: (error, variables, context, mutation) => {
                        handleError(error, mutation.meta?.errorMessage || 'Error saving data');
                    },
                }),
                queryCache: new QueryCache({
                    onError: (error, query) => {
                        handleError(error, query.meta?.errorMessage || 'Error loading data');
                    },
                }),
            })
    );

    const handleError = useHandleError(queryClient);
    return (
        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false}></ReactQueryDevtools>
            {children}
        </QueryClientProvider>
    );
}
