import { Box, Paper } from '@mui/material';
import { makeStyles } from 'makeStyles';

const useStyles = makeStyles()({
    paper: {
        position: 'relative',
        flexGrow: 1,
        flexShrink: 1,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '550px',
    },
});

export function ChartContainer({ children }) {
    const { classes } = useStyles();

    return (
        <Paper className={classes.paper}>
            <Box display="relative" height="100%" padding="10px">
                {children}
            </Box>
        </Paper>
    );
}
