import { post } from './axiosWrapper';
import { API_ENDPOINTS } from './endpoints';

export function requestPasswordReset(payload) {
    return post(API_ENDPOINTS.PASSWORD_RESET, payload).then(res => res.data);
}

export function confirmPasswordReset(payload) {
    return post(API_ENDPOINTS.PASSWORD_RESET_CONFIRM, payload);
}
