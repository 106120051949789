import { useCallback, useEffect, useMemo, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

import {
    Box,
    Divider,
    FormHelperText,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Paper,
} from '@mui/material';
import { difference, differenceBy, find, get, groupBy, unionBy } from 'lodash';
import { makeStyles } from 'makeStyles';

import { type Feature, useCurrentProduct } from 'api/products';
import { CheckBoxCheckedIcon, CheckBoxUncheckedIcon } from 'icons';

type FeatureType = 'Feature' | 'ADK Feature' | 'Runtime';

const useStyles = makeStyles()(theme => ({
    childList: {
        paddingLeft: theme.spacing(4),
    },
    iconItem: {
        minWidth: 'unset',
    },
}));

function FeatureItemCheckbox({
    feature,
    selectedFeatures,
    setSelectedFeatures,
    disabled,
}: {
    feature: Feature;
    selectedFeatures: Array<Feature>;
    setSelectedFeatures: (features: Array<Feature>) => void;
    disabled: boolean;
}) {
    const { classes } = useStyles();

    const handleFeatureChange = useCallback(() => {
        if (find(selectedFeatures, { id: feature.id })) {
            setSelectedFeatures(selectedFeatures.filter(f => f.id !== feature.id));
        } else {
            setSelectedFeatures([...selectedFeatures, feature]);
        }
    }, [selectedFeatures, feature, setSelectedFeatures]);

    return (
        <ListItemButton key={feature.name} onClick={handleFeatureChange} disabled={disabled} role="checkbox">
            <ListItemIcon className={classes.iconItem} sx={{ marginRight: 1 }}>
                {find(selectedFeatures, { id: feature.id }) ? <CheckBoxCheckedIcon /> : <CheckBoxUncheckedIcon />}
            </ListItemIcon>
            <ListItemText primary={feature.name} />
        </ListItemButton>
    );
}

function LicenseFeatureTreeViewItem({
    featureType,
    features,
    selectedFeatures,
    setSelectedFeatures,
    disabled = false,
}: {
    featureType: FeatureType;
    features: Array<Feature>;
    selectedFeatures: Array<Feature>;
    setSelectedFeatures: (features: Array<Feature>) => void;
    disabled?: boolean;
}) {
    const { classes } = useStyles();
    const { data: currentProduct } = useCurrentProduct();
    const applicationFeatures = useMemo(() => {
        return currentProduct ? currentProduct.applicationFeatures : [];
    }, [currentProduct]);

    function isSubset(subset: Array<Feature>, superset: Array<Feature>) {
        return difference(subset, superset).length === 0;
    }

    function isApplicationFeature(feature: Feature) {
        return applicationFeatures.some(f => f.id === feature.id);
    }

    const typeHasFeatureSelected = useCallback(() => {
        if (isSubset(features, applicationFeatures)) {
            return true;
        }
        const nonApplicationFeatures = differenceBy(selectedFeatures, applicationFeatures, 'id');
        return features.some(f => find(nonApplicationFeatures, { id: f.id }));
    }, [applicationFeatures, features, selectedFeatures]);

    const handleFeatureTypeChange = useCallback(() => {
        if (typeHasFeatureSelected()) {
            const nonApplicationFeatures = differenceBy(features, applicationFeatures, 'id');
            setSelectedFeatures(differenceBy(selectedFeatures, nonApplicationFeatures, 'id'));
        } else {
            setSelectedFeatures(unionBy(selectedFeatures, features, 'id'));
        }
    }, [applicationFeatures, features, selectedFeatures, setSelectedFeatures, typeHasFeatureSelected]);

    return (
        <>
            <Divider />
            <ListItemButton
                onClick={handleFeatureTypeChange}
                disabled={disabled || isSubset(features, applicationFeatures)}
                role="checkbox"
            >
                <ListItemIcon className={classes.iconItem} sx={{ marginRight: 1 }}>
                    {typeHasFeatureSelected() ? <CheckBoxCheckedIcon /> : <CheckBoxUncheckedIcon />}
                </ListItemIcon>
                <ListItemText primary={featureType} />
            </ListItemButton>

            <List className={classes.childList} dense disablePadding>
                {features.map(feature => (
                    <FeatureItemCheckbox
                        key={feature.id}
                        feature={feature}
                        selectedFeatures={selectedFeatures}
                        setSelectedFeatures={setSelectedFeatures}
                        disabled={disabled || isApplicationFeature(feature)}
                    />
                ))}
            </List>
        </>
    );
}

export function LicenseFeatureTreeView({
    availableFeatures,
    form,
    name = 'features',
    disabled = false,
    required = false,
}: {
    availableFeatures: Array<Feature>;
    form: UseFormReturn;
    name?: string;
    disabled?: boolean;
    required?: boolean;
}) {
    const {
        watch,
        setValue,
        register,
        formState: { errors },
    } = form;
    const selectedFeatures: Array<Feature> = watch(name);
    const setSelectedFeatures = useCallback(
        (features: Array<Feature>) => {
            setValue(name, features);
        },
        [setValue, name]
    );
    const [featuresByType, setFeaturesByType] = useState<Record<FeatureType, Feature[]>>(null);

    // Explicitly register features since we don't use actual inputs here.
    function validate(features: Array<Feature>) {
        return !required || features.length > 0 || 'Please select at least one feature';
    }
    register(name, { validate: validate });

    useEffect(() => {
        setFeaturesByType(groupBy(availableFeatures, 'featureType') as Record<FeatureType, Feature[]>);
    }, [availableFeatures, name]);

    if (!featuresByType || !availableFeatures) {
        return null;
    }

    return (
        <Paper>
            <Box
                sx={{
                    margin: 1,
                    padding: 1,
                    ...(get(errors, name) && { border: 1, borderColor: 'error.main', borderRadius: 1 }),
                }}
            >
                <List dense subheader={<ListSubheader>Select features</ListSubheader>}>
                    {Object.keys(featuresByType).map((key: FeatureType) => (
                        <LicenseFeatureTreeViewItem
                            featureType={key}
                            features={featuresByType[key]}
                            key={key}
                            setSelectedFeatures={setSelectedFeatures}
                            selectedFeatures={selectedFeatures}
                            disabled={disabled}
                            aria-disabled={disabled}
                        />
                    ))}
                </List>
            </Box>
            {get(errors, name) && (
                <FormHelperText error sx={{ marginLeft: 2 }}>
                    {get(errors, name).message}
                </FormHelperText>
            )}
        </Paper>
    );
}
