import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormContainer } from 'react-hook-form-mui';
import { generatePath } from 'react-router';
import { useHistory } from 'react-router-dom';

import { Card, CardActions, CardHeader } from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import { ActionButton } from 'commonComponents/ActionButton';
import { CommonFormComponents } from 'commonComponents/CommonFormComponents';
import { LinkButton } from 'commonComponents/LinkButton';
import { PageContainer } from 'commonComponents/PageContainer';

import { useHandleFormError } from 'api/hooks/errorHandling';
import { useCurrentProduct } from 'api/products';
import { createUser } from 'api/users';
import { PersonAddIcon, UndoIcon } from 'icons';
import { ROUTES } from 'routes/routes';

import { UserFormContent, UserFormContentProps, UserFormFields } from './UserFormContent';

const INITIAL_VALUES: UserFormFields = {
    address: '',
    city: '',
    comment: '',
    company: '',
    country: '',
    email: '',
    full_name: '',
    is_internal: false,
    is_active: true,
    owner_id: '',
    phone_number: '',
    products: [],
    groups: ['LicenseManager'],
    username: '',
};

export function CreateUser() {
    const history = useHistory();
    const { data: currentProduct } = useCurrentProduct();
    const formContext = useForm({
        defaultValues: { ...INITIAL_VALUES, products: currentProduct ? [currentProduct] : [] },
    });
    const handleFormError = useHandleFormError(formContext);
    const { watch, setValue } = formContext;
    const email = watch('email');

    useEffect(() => {
        const isInternal = email && email.endsWith('@mevis.de');
        setValue('is_internal', isInternal);
    }, [email, setValue]);

    const { mutate: trigger, isLoading: requestInProgress } = useMutation({
        mutationFn: (values: UserFormFields) =>
            createUser({
                ...values,
                products: values.products.map(p => p.id),
                default_product: values.products.length === 1 ? values.products[0].id : undefined,
            }),
        onSuccess: (response, values) => {
            const userDetailRoute = generatePath(ROUTES.USER_DETAIL, { id: response.data.id });
            formContext.reset(values);
            if (response.data.is_active) {
                history.push(
                    `${generatePath(ROUTES.USER_EMAIL, { id: response.data.id })}?${new URLSearchParams({
                        returnPath: userDetailRoute,
                    })}`
                );
            } else {
                history.push(userDetailRoute);
            }
        },
        onError: handleFormError,
        meta: { errorMessage: 'User creation failed' },
    });

    const defaultProps: UserFormContentProps['defaultProps'] = {
        autoComplete: 'off',
        variant: 'outlined',
        margin: 'none',
    };

    return (
        <PageContainer maxWidth="md">
            <FormContainer formContext={formContext} onSuccess={data => trigger(data)} data-testid="CreateUser_Form">
                <CommonFormComponents />
                <Card>
                    <CardHeader title="Create new user" />
                    <UserFormContent defaultProps={defaultProps} form={formContext} />
                    <CardActions>
                        <ActionButton
                            color="secondary"
                            iconClass={PersonAddIcon}
                            inProgress={requestInProgress}
                            title="Create"
                            type="submit"
                            variant="contained"
                        />
                        <LinkButton
                            iconClass={UndoIcon}
                            route={ROUTES.USER_LIST}
                            title="Cancel"
                            data-testid="CreateUser_Cancel"
                        />
                    </CardActions>
                </Card>
            </FormContainer>
        </PageContainer>
    );
}
