import { generatePath } from 'react-router';
import { useParams } from 'react-router-dom';

import { Box, Card, CardActions, CardContent, Table, TableBody, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { DetailsCardTitle } from 'commonComponents/DetailsCardTitle';
import { LinkButton } from 'commonComponents/LinkButton';
import { LoadingIndicator } from 'commonComponents/LoadingIndicator';
import { mapBoolean } from 'commonComponents/ObjectTable/ObjectTableContext';
import { PageContainer } from 'commonComponents/PageContainer';
import { TableRow } from 'commonComponents/TableRow';

import { loadCustomerDetails } from 'api/customers';

import { EditIcon, UndoIcon } from '../icons';
import { ROLE_DEFINITIONS } from '../roles/definitions';
import { RoleDependentComponent } from '../roles/RoleDependentComponent';
import { ROUTES } from '../routes/routes';
import { CustomerDeleteButton } from './CustomerDeleteButton';
import { CustomerHistory } from './CustomerHistory';
import { CustomerLicenses } from './CustomerLicenses';

export function CustomerDetails() {
    const { id: customerId } = useParams<{ id: string }>();
    const theme = useTheme();
    /** @type {import('swr').SWRResponse<import('api/customers').CustomerDetails>} */
    const { data: customer, error } = useQuery({
        queryKey: ['Customer', 'detail', Number(customerId)],
        queryFn: () => loadCustomerDetails(Number(customerId)),
        meta: { errorMessage: 'Loading customer failed' },
    });

    if (error) {
        return null;
    }

    if (!customer) {
        return <LoadingIndicator />;
    }

    return (
        <PageContainer maxWidth="md">
            <Box display="flex" flexDirection="column" gap={theme.spacing(1)}>
                <Card>
                    <CardContent data-testid="CustomerDetails_CardContent">
                        <DetailsCardTitle subtitle="Customer" title={customer.full_name} />
                        <Table size="small">
                            <TableBody>
                                <TableRow title="Full Name" value={customer.full_name} />
                                <TableRow title="Company" value={customer.company} />
                                <TableRow title="Email Address" value={customer.email} />
                                <TableRow title="Address" value={customer.address} />
                                <TableRow title="Country" value={customer.country} />
                                <TableRow title="City" value={customer.city} />
                                <TableRow title="Phone Number" value={customer.phone_number} />
                                <TableRow title="Active" value={mapBoolean(customer.is_active)} />
                                <TableRow title="Default Owner ID" value={customer.owner_id} />
                                <TableRow title="Comment" value={customer.comment} />
                            </TableBody>
                        </Table>
                    </CardContent>
                    <CardActions>
                        <RoleDependentComponent
                            userRole={ROLE_DEFINITIONS.PrivilegedCustomer}
                            component={LinkButton}
                            iconClass={EditIcon}
                            route={generatePath(ROUTES.CUSTOMER_EDIT, { id: customerId })}
                            title="Edit"
                            data-testid="CustomerDetails_EditButton"
                        />
                        <CustomerDeleteButton customer={customer} />
                        <LinkButton iconClass={UndoIcon} route={ROUTES.CUSTOMER_LIST} title="Back" />
                    </CardActions>
                </Card>
                <CustomerLicenses customerId={customerId} />
                <RoleDependentComponent
                    userRole={ROLE_DEFINITIONS.LicenseManager}
                    component={CustomerHistory}
                    customerId={Number(customerId)}
                />
            </Box>
        </PageContainer>
    );
}
