export function triggerDownload(response) {
    function destroyClickedElement(event) {
        document.body.removeChild(event.target);
    }

    const objectURL = URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
    const downloadLink = document.createElement('a');
    downloadLink.download = response.headers['content-disposition'].split('filename=')[1];
    downloadLink.innerHTML = 'Download File';
    downloadLink.href = objectURL;
    downloadLink.onclick = destroyClickedElement;
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);
    downloadLink.click();
}
