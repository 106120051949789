import { useEffect } from 'react';
import { useHistory } from 'react-router';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { usePrevious } from 'common/hooks';

import { ActionButton } from 'commonComponents/ActionButton';

import { deleteCustomer, forgetCustomer } from 'api/customers';
import { DeleteIcon, UndoIcon } from 'icons';
import { ROUTES } from 'routes/routes';

export function CustomerDeleteDialog({ customerId, open, onClose }) {
    const queryClient = useQueryClient();
    const {
        mutate: trigger,
        error,
        isLoading: isDeleting,
    } = useMutation({
        mutationFn: () => deleteCustomer(customerId),
        onSuccess: () => {
            forgetCustomer(queryClient, customerId);
            history.push(ROUTES.CUSTOMER_LIST);
        },
        meta: { errorMessage: 'Deleting customer failed' },
    });
    const history = useHistory();

    const wasDeleting = usePrevious(isDeleting);
    useEffect(() => {
        if (error) return;
        if (!isDeleting && wasDeleting) {
            onClose();
        }
    }, [error, isDeleting, onClose, wasDeleting]);

    return (
        <Dialog {...{ open, onClose }}>
            <DialogTitle>Delete Customer</DialogTitle>
            <DialogContent> Are you sure you want to delete this customer?</DialogContent>
            <DialogActions>
                <ActionButton
                    color="secondary"
                    iconClass={DeleteIcon}
                    disabled={isDeleting}
                    inProgress={isDeleting}
                    title="Delete"
                    variant="contained"
                    type="submit"
                    onClick={() => trigger()}
                />
                <ActionButton
                    color="primary"
                    iconClass={UndoIcon}
                    inProgress={false}
                    onClick={onClose}
                    title="Cancel"
                    variant="contained"
                />
            </DialogActions>
        </Dialog>
    );
}
