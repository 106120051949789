import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { FormContainer } from 'react-hook-form-mui';

import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { DATE_FORMAT } from 'common/constants';
import { addDuration } from 'common/utils';

import { ActionButton } from 'commonComponents/ActionButton';
import { KeyboardDatePickerElement } from 'commonComponents/KeyboardDatePickerElement';

import { renewLicense } from 'api/licenses';
import { RenewIcon, UndoIcon } from 'icons';
import { expirationDate, getDateFromDateTimeObject } from 'licenses/utils';
import { ROLE_DEFINITIONS } from 'roles/definitions';
import { useCurrentUserHasRole } from 'roles/helpers';

export function LicenseRenewDialog({ license, open, onClose }) {
    const queryClient = useQueryClient();
    const { mutate: trigger, isLoading: isRequesting } = useMutation({
        mutationFn: () => renewLicense(license.id, getValues('expiration_date'), getValues('support_expiration_date')),
        onSuccess: () => {
            queryClient.invalidateQueries(['License', 'detail', license.id]);
            queryClient.invalidateQueries(['License', 'list']);
            queryClient.invalidateQueries(['LicenseHistory', 'list', license.id]);
            queryClient.invalidateQueries(['LicenseFile', 'list', { licenseId: license.id }]);
            onClose();
        },
        meta: { errorMessage: 'Renewing license failed' },
    });

    const defaultExpiration = '';
    const defaultSupportExpiration = '';
    const formContext = useForm({
        defaultValues: {
            expiration_date: defaultExpiration,
            support_expiration_date: defaultSupportExpiration,
        },
    });
    const { getValues, setValue } = formContext;
    const currentUserHasRole = useCurrentUserHasRole();
    const userIsAtLeastLicenseManager = currentUserHasRole(ROLE_DEFINITIONS.LicenseManager);

    const renewedExpirationDate = useMemo(
        () => addDuration(license.expiration_date, license.configuration.validity, license.configuration.validityUnit),
        [license]
    );

    const renewedSupportExpirationDate = useMemo(
        () =>
            addDuration(
                license.support_expiration_date,
                license.configuration.supportValidity,
                license.configuration.supportValidityUnit
            ),
        [license]
    );

    useEffect(() => {
        setValue('expiration_date', renewedExpirationDate);
        setValue('support_expiration_date', renewedSupportExpirationDate);
    }, [renewedExpirationDate, renewedSupportExpirationDate, setValue]);

    const shouldAllowSelectingSupportExpirationDate =
        renewedSupportExpirationDate && userIsAtLeastLicenseManager ? true : false;
    const shouldAllowSelectingExpirationDate = renewedExpirationDate && userIsAtLeastLicenseManager ? true : false;

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Renew license</DialogTitle>
            <DialogContent>
                <Typography variant="body1">Are you sure you want to renew this license?</Typography>
                <Typography variant="caption">Please note that license renewals may be subject to a fee.</Typography>
            </DialogContent>
            <FormContainer formContext={formContext} onSuccess={() => trigger()}>
                <Box
                    sx={{
                        paddingLeft: 3,
                        paddingRight: 3,
                        paddingBottom: 2,
                    }}
                >
                    {!shouldAllowSelectingExpirationDate && (
                        <Typography variant="subtitle2">
                            Expiration Date: {expirationDate(license, getDateFromDateTimeObject(renewedExpirationDate))}
                        </Typography>
                    )}
                    {shouldAllowSelectingExpirationDate && (
                        <KeyboardDatePickerElement
                            label={'Renewed Expiration Date (' + DATE_FORMAT + ')'}
                            name="expiration_date"
                            inputVariant="outlined"
                            margin="normal"
                        />
                    )}
                </Box>
                <Box
                    sx={{
                        paddingLeft: 3,
                        paddingRight: 3,
                        paddingBottom: 2,
                    }}
                >
                    {!shouldAllowSelectingSupportExpirationDate && (
                        <Typography variant="subtitle2">
                            Support Expiration Date:{' '}
                            {expirationDate(license, getDateFromDateTimeObject(renewedSupportExpirationDate))}
                        </Typography>
                    )}
                    {shouldAllowSelectingSupportExpirationDate && (
                        <KeyboardDatePickerElement
                            label={'Renewed Support Expiration Date (' + DATE_FORMAT + ')'}
                            name="support_expiration_date"
                            inputVariant="outlined"
                            margin="normal"
                        />
                    )}
                </Box>
            </FormContainer>
            <DialogActions>
                <ActionButton
                    color="secondary"
                    iconClass={RenewIcon}
                    inProgress={isRequesting}
                    title="Renew"
                    variant="contained"
                    type="submit"
                    onClick={() => trigger()}
                />
                <ActionButton
                    color="primary"
                    iconClass={UndoIcon}
                    inProgress={false}
                    onClick={onClose}
                    title="Cancel"
                    variant="contained"
                />
            </DialogActions>
        </Dialog>
    );
}
